import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { URL_AXIOS } from '../../URL/URL';
import axios, { Axios } from 'axios';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { Country, State, City } from 'country-state-city';
import { Toast } from 'primereact/toast';
import { RadioButton } from "primereact/radiobutton";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useContext } from 'react';
import Footer from '../Footer';
import Swal from 'sweetalert2/dist/sweetalert2.js';
function EditionSites() {
    const usernameconnected=sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const navigate = useNavigate();
    const { id } = useParams();
    const [sites, setSites] = useState([]);
    const [country, setCountry] = React.useState('');
    const [region, setRegion] = React.useState([]);
    const [city, setCity] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [countries, setCountries] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredDatatwo, setFilteredDatatwo] = useState([]);
    const [code, setCode] = useState("");
    const [societe, setSociete] = useState("");
    const [type, setType] = useState("");
    const [email, setEmail] = useState("");
    const [nomresponsable, setNomresponsable] = useState("");
    const [fonction, setFonction] = useState("");
    const [adresselocal, setAdresselocal] = useState("");
    const [pays, setPays] = useState('');
    const [departement, setDepartement] = useState('');
    const [ville, setVille] = useState('');
    const [telephone, setTelephone] = useState("");
    const [description, setDescription] = useState("");
    const [logo, setLogo] = useState("");
    const [entete, setEntete] = useState("");
    const [statut, setStatut] = useState(1);
    const toast = useRef(null);
    const [ingredient, setIngredient] = useState('');
    const [phone, setPhone] = useState("242");
    const [message_mail, setMessage_mail] = useState("");
    const [message_societe, setMessage_societe] = useState("");
    const [message_type, setMessage_type] = useState("");
    const [message_telephone, setMessage_telephone] = useState("");
    const [message_adresse, setMessage_adresse] = useState("");
    const [message_responsable, setMessage_responsable] = useState("");
    const [message_fonction, setMessage_fonction] = useState("");
    const [message_pays, setMessage_pays] = useState("");
    const [message_departement, setMessage_departement] = useState("");
    const [message_ville, setMessage_ville] = useState("");
    const [boolean, setBoolean] = useState(true);
    const handleInputSociete = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSociete(value)
            setMessage_societe("");
        } else {
            setSociete("")
        }
    }

    const handleInputType = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setType(value)
            setMessage_type("");
        } else {
            setType("")
        }
    }
    const handleInputEmail = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setEmail(value)
            setMessage_mail("");
        } else {
            setEmail("")
        }
    }
    const handleInputDepartement = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setDepartement(value)
            setMessage_departement("");
        } else {
            setDepartement("")
        }
    }
    const handleInputAdresse = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setAdresselocal(value)
            setMessage_adresse("");
        } else {
            setAdresselocal("")
        }
    }
    const handleInputPays = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setPays(value)
            setMessage_pays("");
        } else {
            setPays("")
        }
    }
    const handleInputVille = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setVille(value)
            setMessage_ville("");
        } else {
            setVille("")
        }
    }
    const handleInputResponsable = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setNomresponsable(value)
            setMessage_responsable("");
        } else {
            setNomresponsable("")
        }
    }
    const handleInputFonction = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setFonction(value)
            setMessage_fonction("");
        } else {
            setFonction("")
        }
    }
    const handleInputTelephone = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setTelephone(value)
            setMessage_telephone("");
        } else {
            setTelephone("")
        }
    }
    const validationFields = () => {
        if (email == "") {
            setTimeout(() => {
                setMessage_mail("Veuillez saisir le mail");
                setBoolean(false)
            }, 1000);


        }
        if (societe == "") {
            setTimeout(() => {
                setMessage_societe("Veuillez saisir la société");
                setBoolean(false);
            }, 1000);


        }
        if (type == "") {
            setTimeout(() => {
                setMessage_type("Veuillez saisir le type de société");
                setBoolean(false);
            }, 1000);


        }
        if (telephone == "") {
            setTimeout(() => {
                setMessage_telephone("Veuillez saisir le N° téléphone");
                setBoolean(false);
            }, 1000);


        }
        if (adresselocal == "") {
            setTimeout(() => {
                setMessage_adresse("Veuillez saisir l'adresse");
                setBoolean(false);
            }, 1000);


        }
        if (nomresponsable == "") {
            setTimeout(() => {
                setMessage_responsable("Veuillez saisir le nom responsable");
                setBoolean(false);
            }, 1000);


        }
        if (fonction == "") {
            setTimeout(() => {
                setMessage_fonction("Veuillez saisir la fonction");
                setBoolean(false);
            }, 1000);


        }
        if (pays == "") {
            setTimeout(() => {
                setMessage_pays("Veuillez sélectionner le pays");
                setBoolean(false);
            }, 1000);


        }
        if (departement == "") {
            setTimeout(() => {
                setMessage_departement("Veuillez saisir le département");
                setBoolean(false);
            }, 1000);


        }
        if (ville == "") {
            setTimeout(() => {
                setMessage_ville("Veuillez saisir la ville");
                setBoolean(false);
            }, 1000);


        }
    }
    const handleNomChange = (e) => {
        setNomresponsable(e.target.value)
    }




    const EmailValidation = (email) => {
        const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
        if (regex.test(email)) {
            //setMessage_mail("Mail is valid");

            return true;
        } else if (!regex.test(email) && email !== "") {
            setMessage_mail("le mail n'est pas valide");
            return false;
        } else {
            setMessage_mail("");
            return false;
        }
    };

    const handleInputSocieteChange = (event) => {
        const value = event.target.value;
        // Use a regular expression to allow only uppercase letters
        const regex = /^[A-Z]*$/;
        if (regex.test(value)) {
            setSociete(value);
        }
    };

    const handleInputChangeNumberTel = (event) => {
        const value = event.target.value;
        // Use a regular expression to allow only numbers (including negative numbers)
        const regex = /^-?\d*$/;

        if (regex.test(value)) {
            // If the input value contains only numbers or negative numbers,
            // update the telephone state with the value.
            setTelephone(value);
            setMessage_telephone(""); // Clear any previous error messages.
        } else {
            // If the input value is invalid (contains non-numeric characters),
            // do not update the telephone state and display an error message.
            setTelephone("");
            setMessage_telephone("Invalid telephone number. Please enter only numbers.");
        }
    };
    const removeIdenticalCharacters = (str) => {
        return str.replace(/(.)\1+/g, '$1');
    }

    const show = () => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: 'message' });
    };
    const handleCityChange = (val) => {
        setCity(val);
    };
    const handleCountryChange = (e) => {
        const name_get = e.target.value;

        if (name_get !== "") {
            setPays(name_get);
            setMessage_pays("");
        } else {
            setPays("")
        }

        const filtered = countries.filter((item) => item.name === name_get);
        setFilteredData(filtered);

        filtered.forEach((filter) => {
            const states = State.getStatesOfCountry(filter.isoCode);
            setRegion(states);

        });
    };


    const handleRegionChange = (e) => {
        const name_get = e.target.value;
        if (name_get !== "") {
            setDepartement(name_get);
            setMessage_departement("");
        } else {
            setDepartement("")
        }

        const filtered = region.filter((item) => item.name === name_get);
        setFilteredDatatwo(filtered);

        filtered.forEach((filter) => {
            const states = City.getCitiesOfState(filter.countryCode, filter.isoCode);
            setCity(states);
            console.log("Region: ", states);
        });
    };
    const handleVilleChange = (e) => {
        const name_get = e.target.value;

        if (name_get !== "") {
            setVille(name_get);
            setMessage_ville("");
        } else {
            setVille("")
        }
    }
    const liste_site_by_id = async () => {
        try {
            await axios.get(URL_AXIOS + `sites_get_by_id/${id}`)
                .then(response => {
                    setSociete(response.data.societe)
                    setType(response.data.type);
                    setAdresselocal(response.data.adresselocal);
                    setEmail(response.data.email);
                    setTelephone(response.data.telephone);
                    setNomresponsable(response.data.nomresponsable);
                    setDescription(response.data.description);
                    setFonction(response.data.fonction);
                    setPays(response.data.pays);
                    setVille(response.data.ville);
                    setDepartement(response.data.ville);
                    setPhone(response.data.code);
                    response.data.statut == 1 ? setIngredient("oui") : setIngredient("non")


                });
            /*if (response.data.status === 200) {
                setSites(response.data);
            }*/
        } catch (Error) {

        }

    }

    const updateConfirm=()=>{
        Swal.fire({
            title: "Question",
            text: "Voulez-vous mettre à jour ce site",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui",
            cancelButtonText:"Non"
          }).then((result) => {
            if (result.isConfirmed) {
           
                update_site_state();
            }
          });
    }

    const update_site_state = async () => {
        try {
            const site_info = {

                societe: societe.replace(' ', '_').toUpperCase(),
                type: type.toUpperCase(),
                email: email,
                nomresponsable: nomresponsable.toUpperCase(),
                fonction: fonction.toUpperCase(),
                adresselocal: adresselocal.toUpperCase(),
                pays: pays.toUpperCase(),
                ville: ville.toUpperCase(),
                telephone: telephone,
                description: description,
                statut: ingredient == "oui" ? 1 : 0,
                code: removeIdenticalCharacters(("+" + phone))

            }
            validationFields();
            if (EmailValidation(email)) {
                if (societe != "" || type != "" || email != "" || nomresponsable != "" || fonction != "" || adresselocal != "" || pays != "" || ville != "" || telephone != "" || phone != "") {
                    const response = await axios.post(URL_AXIOS + `update_site_status/${id}`, site_info);

                    if (response.data.status === 200) {
                        Swal.fire({
                            title: "Réussi!",
                            text: "Les informations du site ont été modifiées avec succès !",
                            icon: "success"
                          });
                        navigate('/sites')

                    } else {
                        show();
                    }
                } else {
                    toast.current.show({ severity: 'warning', summary: 'warning', detail: 'veuillez renseigner les champs obligatoires svp !' });
                }
            }
        } catch (error) {
          alert("Erreur "+error.message)
        }



    }
    useEffect(() => {


        const fetchCountries = async () => {
            try {
                //const response = await axios.get('https://api.countrystatecity.in/v1/countries');
                setCountries(Country.getAllCountries());
            } catch (error) {
                console.log(error);
            }
        };

        fetchCountries();
    }, []);


    useEffect(() => {
        liste_site_by_id(); 
        if(usernameconnected===null || usernameconnected===""){
            navigate('/')
        }
    }, []);


    return (
        <section id="container">
            <Toast ref={toast} />
            <Header />
            <AsideBar  username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="form-panel">


                                <form role="form" class="form-horizontal style-form">
                                    <div class="form-group ">

                                        <div class="col-lg-6">
                                            <input type="text" id='societe' value={societe} onChange={handleInputSociete} placeholder="Centre *" class="form-control"  />
                                            {message_societe!=="" ? <label style={{ color: 'red' }}>{message_societe}</label>:""} 
                                        </div>
                                        <div class="col-lg-6">
                                            <select id='type' className='form-control' placeholder='Type *' value={type} onChange={handleInputType} >
                                                <option>Type *</option>
                                                <option value="Centre imagerie">Centre imagerie</option>
                                                <option value="Centre médical">Centre médical</option>
                                                <option value="Clinique médicale">Clinique médicale</option>
                                                <option value="Hôpital">Hôpital</option>
                                                <option value="Laboratoire d'analyse médicale">Laboratoire d'analyse médicale</option>
                                                <option value="Plateforme d'interpretation">Plateforme d'interpretation</option>
                                            </select>
                                            {message_type!=="" ? <label style={{ color: 'red' }}>{message_type}</label>:""} 
                                        </div>
                                    </div>
                                    <div class="form-group ">

                                        <div class="col-lg-6">
                                            <input type="email" required value={email} id='email' placeholder="Email *" onChange={handleInputEmail} class="form-control" />
                                          
                                            {message_mail!=="" ? <label style={{ color: 'red' }}>{message_mail}</label>:""} 
                                        </div>

                                        <div class="col-lg-6">
                                            <input type="text"  value={adresselocal} id='adresselocal' onChange={handleInputAdresse} placeholder="Adresse *" class="form-control" />
                                            {message_adresse!=="" ? <label style={{ color: 'red' }}>{message_adresse}</label>:""} 
                                        </div>
                                    </div>
                                    <div class="form-group">

                                        <div class="col-lg-4">

                                            <select className='form-control' value={id !== "" ? pays : pays} id='pays' onChange={handleCountryChange} >
                                                <option value=""> {id !== "" ? pays : "Sélectionner un pays"}</option>
                                                {countries.map((country) => (
                                                    <option key={country.isoCode} value={country.name}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {message_pays!=="" ? <label style={{ color: 'red' }}>{message_pays}</label>:""} 

                                        </div>
                                        <div class="col-lg-4">
                                            <select className='form-control' value={departement} id='departement' onChange={handleRegionChange} >
                                                <option value="">{id !== "" ? ville : "Sélectionner un département"}</option>
                                                {region.map((region) => (
                                                    <option key={region.isoCode} value={region.name}>
                                                        {region.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {message_departement!=="" ? <label style={{ color: 'red' }}>{message_departement}</label>:""} 
                                        </div>
                                        <div class="col-lg-4">

                                            <select className='form-control' value={ville} id='ville' onChange={handleVilleChange} >
                                                <option value="">{id !== "" ? ville : "Sélectionner une ville"}</option>

                                                {city.map((city) => (
                                                    <option key={city.isoCode} value={city.name}>
                                                        {city.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {message_ville!=="" ? <label style={{ color: 'red' }}>{message_ville}</label>:""} 
                                        </div>
                                    </div>
                                    <div class="form-group">

                                        <div class="col-lg-3">
                                            <input type="text" value={nomresponsable} id='nomresponsable' onChange={handleInputResponsable} placeholder="Responsable *"  class="form-control"  />
                                           
                                            {message_responsable!=="" ? <label style={{ color: 'red' }}>{message_responsable}</label>:""} 
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" value={fonction} id='fonction' onChange={handleInputFonction} placeholder="Fonction *"  class="form-control"  />
                                            {message_fonction!=="" ? <label style={{ color: 'red' }}>{message_fonction}</label>:""} 
                                        </div>
                                        <div class="col-lg-2">

                                            <PhoneInput
                                                country={'cg'}
                                                inputStyle={{ width: '100%' }}
                                                enableSearch={true}
                                                value={phone}
                                                onChange={(phone) => setPhone(phone)}
                                                placeholder="Code * "
                                                countryCodeEditable={false}
                                                disabled={true}
                                                disableCountryCode={true}
                                                inputProps={{
                                                    required: true,

                                                }}
                                                id='phone'
                                            />

                                        </div>
                                        <div class="col-lg-4">
                                            <input type="text" value={telephone}  id='telephone' onChange={handleInputChangeNumberTel} placeholder="Téléphone *"  class="form-control" />
 
                                            {message_telephone!=="" ? <label style={{ color: 'red' }}>{message_telephone}</label>:""} 
                                        </div>
                                    </div>
                                    <div className="form-group ">

                                        <div className="flex align-items-center" style={{ marginLeft: '40%' }}>
                                            <label className="ml-2" style={{ marginLeft: 5 }}> Status de validation :</label>
                                            <RadioButton inputId="ingredient1" name="pizza" style={{ marginLeft: 10 }} value="oui" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'oui'} />
                                            <label htmlFor="ingredient1" className="ml-2" style={{ marginLeft: 10 }}>Oui</label>
                                            <RadioButton inputId="ingredient2" name="pizza" value="non"
                                                onChange={(e) => setIngredient(e.value)} checked={ingredient === 'non'}
                                                style={{ marginLeft: 10 }} />
                                            <label htmlFor="ingredient2" style={{ marginLeft: 10 }} className="ml-2">Non</label>
                                        </div>



                                    </div>
                                    <div class="form-group">
                                        <div class="col-lg-10">
                                            <button class="btn btn-primary" type="button" onClick={() => updateConfirm()}>Validation site</button>
                                            <button class="btn btn-warning" type="button" style={{ marginLeft: 10 }}
                                                onClick={() => navigate('/sites')}>Retour</button>
                                        </div>
                                    </div>
                                </form>



                            </div>
                        </div>
                    </div>


                </section>
            </section>
            <Footer/>
        </section>
    );
}

export default EditionSites;