import React, { useEffect, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';
import { Paginator } from 'primereact/paginator';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Footer from '../Footer';
import { useContext } from 'react';
import { ThemeContext } from '../../context/ContextHeader';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FaFileExcel } from "react-icons/fa6";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from './Loading';
function Sites() {
    const navigate = useNavigate();
    const { sites, setSites } = useContext(ThemeContext);
    //const [sites, setSites] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 15;
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = sites.slice(firstIndex, lastIndex);
    const npage = Math.ceil(sites.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [filteredData, setFilteredData] = useState([]);
    const [query, setQuery] = useState("");
    const dt = useRef(null);
    const toast = useRef(null);
    const [data_get, setData_get] = useState([]);
    const [id, setId] = useState(0);
    const [societe, setSociete] = useState("");
    const [loading,setLoading]= useState(true);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const accept = () => {
        toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' + societe, life: 3000 });

    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }
    const confirm2 = (id, societe) => {

        Swal.fire({
            title: "Questions ?",
            text: "Voulez-vous supprimer ce site ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: 'Non',
            confirmButtonText: "Oui"
        }).then((result) => {
            if (result.isConfirmed) {
           
                setId(id);
                setSociete(societe);
                delete_site(id);
            }
        });

    };

    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status === 200) {
                setSites(response.data.sites);
                setLoading(false)
                //setLogin(response.data.sites.length)
                //setFilteredData(response.data.sites);
            }
        } catch (Error) {

        }

    }
    const delete_site = async (id) => {
        try {
        
            const response = await axios.post(URL_AXIOS + `delete_site/${id}`);
            if (response.data.status === 200) {
                Swal.fire({
                    title: "Réussi !",
                    text: "Le site a été supprimé avec success!",
                    icon: "success"
                });
                liste_site();

            }else{
                Swal.fire({
                    title: "Echec !",
                    text: "Le site n'a pas été supprimé avec success!"+response.data.message,
                    icon: "error"
                });
            }
        } catch (Error) {

        }

    }
    const handleQueryText = (e) => {
        try {
            const query_name = e.target.value;
            setQuery(query_name);
            setCurrentPage(1);

            if (query_name.length > 0) {
                const search_data = sites.filter(item =>
                    item.societe.toLowerCase().includes(query.toLowerCase())
                );
                setSites(search_data);
            } else {
                liste_site();

            }
        } catch (error) {

        }

    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(sites);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'sites');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };



    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }

    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: 10 }}>
                <button type='button' onClick={() => navigate(`/sites_edit/${data.id}`)} className='btn btn-warning'  >
                    <i className='fa fa-edit'></i>
                </button>
                <button type='button' style={{marginLeft:10}} onClick={() => confirm2(data.id, data.societe)} className='btn btn-danger'>
                    <i className='fa fa-trash-o'></i>
                </button>
            </div>
        )
    }
    useEffect(() => {
        liste_site();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
        //liste_site_filtered()
    }, []);

    return (
        <section id="container">
            <Toast ref={toast} />
            <ConfirmDialog />
            <Header />
            <AsideBar username={usernameconnected} />
         { loading ? <Loading /> :   <section id="main-content">
                <section class="wrapper">
                    <div className='row ' style={{ padding: 10, marginTop: 20 }}>
                        <div className="col col-md-8">
                            <Button className='btn btn-info' onClick={() => navigate('/addsites')} label='Nouveau site' />
                            <button type="button" className='btn btn-success' title='Exporter les données'
                                onClick={exportExcel}
                                style={{ marginLeft: 10, color: 'white' }}
                            >
                                <FaFileExcel />
                                <span style={{ marginLeft: 10 }}>Exporter les données</span>
                            </button>
                        </div>
                        { /*  <div className='col col-md-4'>
                            <input type="text" className='form-control' placeholder='Recherche ...'
                                value={query} onChange={handleQueryText}
                                style={{ borderRadius: 100 }} />
    </div>*/}
                    </div>
                    <div class="row " style={{ padding: 10 }}>
                        <DataTable value={sites} paginator emptyMessage="Aucun site disponible!" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className="custom-datatable">
                            <Column header={() => { return <div style={{ marginLeft: 20, padding: '0 10px'}}>#</div> }} bodyStyle={{ textAlign: 'center', padding: '0 10px' }} headerStyle={{ marginLeft: "100px", padding: '0 10px' }} field='id' style={{ width: '5%', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='societe' header="CENTRE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field={'type'} header="TYPE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field={'email'} header="EMAIL" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='telephone' header="TELEPHONE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='nomresponsable' header="RESPONSABLE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='fonction' header="FONCTION" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='ville' header="VILLE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='created_at' header="DATE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column header="ACTIONS" body={ActionBody} style={{ width: '8%', height: '40px', padding: '0 10px' }}></Column>
                        </DataTable>
                        {/* <table ref={dt} class="table table-bordered table-striped table-condensed">
                            <thead>
                                <tr style={{ backgroundColor: 'white' }}>

                                    <th style={{ color: 'red' }}><center>#</center></th>
                                    <th style={{ color: 'red' }}>CENTRE</th>
                                    <th style={{ color: 'red' }}>TYPE</th>
                                    <th style={{ color: 'red' }}>EMAIL</th>
                                    <th style={{ color: 'red' }}>TELEPHONE</th>
                                    <th style={{ color: 'red' }}>RESPONSABLE</th>
                                    <th style={{ color: 'red' }}>FONCTION</th>
                                    <th style={{ color: 'red' }}>VILLE</th>

                                    <th style={{ color: 'red' }}>DATE</th>
                                    <th colSpan={2} style={{ color: 'red' }}><center>ACTIONS</center></th>

                                </tr>
                            </thead>
                            <tbody>
                                {records
                                    .map((si, index) => (
                                        <tr key={si.id}>

                                            <td><center>{index + 1}</center></td>
                                            <td>{si.societe}</td>
                                            <td>{si.type}</td>
                                            <td>{si.email}</td>
                                            <td>{si.telephone}</td>
                                            <td>{si.nomresponsable}</td>
                                            <td>{si.fonction}</td>
                                            <td>{si.ville}</td>

                                            <td>{si.created_at}</td>

                                            <td><center><button type='button' onClick={() => navigate(`/sites_edit/${si.id}`)} className='btn btn-warning'  >
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            </center></td>
                                            <td><center><button type='button' onClick={() => confirm2(si.id, si.societe)} className='btn btn-danger'>
                                                <i className='fa fa-trash-o'></i>
                                            </button>
                                            </center></td>

                                        </tr>
                                    ))}
                            </tbody>

                        </table> */}

                        {/* <nav>
                            <ul className="pagination">
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={prevPage}
                                    >Prev</a>
                                </li>
                                {
                                    numbers.map((n, i) => (
                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                            <a href='#' className='page-link'
                                                onClick={() => changeCPage(n)}
                                            >{n}</a>
                                        </li>
                                    ))
                                }
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={nextPage}
                                    >Next</a>
                                </li>
                            </ul>
                        </nav> */}
                    </div>
                </section>
            </section>}

            <Footer />
        </section >
    );
}

export default Sites;