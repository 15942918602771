import React from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import { useEffect } from 'react';
import { useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useContext } from 'react';
import Footer from '../Footer';
import { Toast } from 'primereact/toast';
import { ThemeContext } from '../../context/ContextHeader';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FaFileExcel } from "react-icons/fa6";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from './Loading';
function Membres() {
    const navigate = useNavigate();
    const { membres, setMembres } = useContext(ThemeContext);
    //const [membres, setMembres] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 15;
    const dt = useRef(null);
    const toast = useRef(null);
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = membres.slice(firstIndex, lastIndex);
    const npage = Math.ceil(membres.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [membres_data, setMembres_data] = useState([]);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState("");

    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(membres);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'sites');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    const liste_membres = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'membres');
            if (response.data.status === 200) {
                setMembres(response.data.membres);
                setLoading(false)
                //setMembre(response.data.membres.length);
            }
        } catch (Error) {

        }

    }
    const delete_membre = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_membre/${id}`);
            if (response.data.status == 200) {


            }
        } catch (Error) {

        }

    }
    const confirm2 = (id) => {

        Swal.fire({
            title: "Questions ?",
            text: "Voulez-vous supprimer ce membre ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: 'Non',
            confirmButtonText: "Oui"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Réussi !",
                    text: "Le membre a été supprimé avec succès",
                    icon: "success"
                });
                delete_membre(id);
                liste_membres();
            }
        });

    };



    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: 10 }}>
                <button type='button' onClick={() => navigate(`/editionMembre/${data.id}`)} className='btn btn-warning'  >
                    <i className='fa fa-edit'></i>
                </button>

                <button type='button' style={{ marginLeft: 10 }} onClick={() => confirm2(data.id)} className='btn btn-danger'>
                    <i className='fa fa-trash-o'></i>
                </button>
            </div>
        )
    }
    useEffect(() => {
        liste_membres();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }

    }, []);
    return (
        <section id="container">
            <ConfirmDialog />
            <Toast ref={toast} />
            <Header />
            <AsideBar username={usernameconnected} />
            {loading ? <Loading /> : <section id="main-content">
                <section class="wrapper">
                    <div className='row ' style={{ padding: 10, marginTop: 20 }}>
                        <div className="col col-md-8">
                            <Button className='btn btn-info' onClick={() => navigate('/addMembre')} label='Nouveau membre'
                                style={{ display: 'none' }}
                            />
                            <button type="button" className='btn btn-success' title='Exporter les données'
                                onClick={exportExcel}
                                style={{ marginLeft: 10, color: 'white' }}
                            >
                                <FaFileExcel />
                                <span style={{ marginLeft: 10 }}>Exporter les données</span>
                            </button>
                        </div>
                        { /* <div className='col col-md-4'>
                            <input type="text" className='form-control' placeholder='Recherche ...'
                                value={query} onChange={handleQueryText}
                                style={{ borderRadius: 100 }} />
    </div>*/}
                    </div>
                    <div class="row " style={{ padding: 10 }}>
                        <DataTable value={membres} emptyMessage="Aucun membre disponible!" paginator rows={10} rowClassName={(data) => data.statut == "0" ? "bgColorRowAbonnExp" : "bgColorRowAgenda"} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className="custom-datatable">
                            <Column header={() => { return <div style={{ marginLeft: 20 }}>#</div> }} bodyStyle={{ textAlign: 'center', padding: '0 10px' }} headerStyle={{ marginLeft: "100px", padding: '0 10px' }} field='id' style={{ width: '5%', height: '40px', padding: '0 10px' }}></Column>
                            {/* <Column field={(data)=>{return <div>{data.nom + " " + data.prenom}</div> }} header="NOM(S) ET PRENOM(S)" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column> */}
                            <Column field={(data) => {
                                    const nom = data.nom || '';
                                    const prenom = data.prenom || '';
                                    return <div>{`${nom} ${prenom}`}</div>;
                                }}
                                header="NOM(S) ET PRENOM(S)"
                                style={{ width: 'auto', height: '40px', padding: '0 10px' }}
                            ></Column>
                            <Column field={'sexe'} header="SEXE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field={'email'} header="EMAIL" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='telephone' header="TELEPHONE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='pays' header="PAYS" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='ville' header="VILLE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='specialite' header="SPECIALITE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column header="STATUS" body={(data) => data.statut == "0" ? "En attente de validation" : "Validé"} style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='created_at' header="DATE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column header="ACTIONS" body={ActionBody} style={{ width: '8%', height: '40px', padding: '0 10px' }}></Column>
                        </DataTable>
                        {/* <table ref={dt} class="table table-bordered table-striped table-condensed">
                            <thead>
                                <tr style={{ backgroundColor: 'white' }}>
                                    <th style={{ color: 'red' }}><center>#</center></th>
                                    <th style={{ color: 'red' }}>NOM ET PRENOM</th>
                                    <th style={{ color: 'red' }}><center>SEXE</center></th>
                                    <th style={{ color: 'red' }}>EMAIL</th>
                                    <th style={{ color: 'red' }}>TELEPHONE</th>
                                    <th style={{ color: 'red' }}>PAYS</th>
                                    <th style={{ color: 'red' }}>VILLE</th>
                                    <th style={{ color: 'red' }}>SPECIALITE</th>
                                    <th style={{ color: 'red' }}>DATE</th>
                                    <th colSpan={2} style={{ color: 'red' }}><center>ACTIONS</center></th>

                                </tr>
                            </thead>
                            <tbody>
                                {records
                                    .map((si, index) => (
                                        <tr key={si.id}>
                                            <td><center>{index + 1}</center></td>
                                            <td>{si.nom + " " + si.prenom}</td>
                                            <td><center>{si.sexe}</center></td>
                                            <td>{si.email}</td>
                                            <td>{si.telephone}</td>
                                            <td>{si.pays}</td>
                                            <td>{si.ville}</td>
                                            <td>{si.specialite}</td>
                                            <td>{si.created_at}</td>
                                            <td><center><button type='button' onClick={() => navigate(`/editionMembre/${si.id}`)} className='btn btn-warning'  >
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            </center></td>
                                            <td><center><button type='button' onClick={() => confirm2(si.id)} className='btn btn-danger'>
                                                <i className='fa fa-trash-o'></i>
                                            </button>
                                            </center></td>
                                        </tr>
                                    ))}
                            </tbody>

                        </table> */}

                        {/* <nav>
                            <ul className="pagination">
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={prevPage}
                                    >Prev</a>
                                </li>
                                {
                                    numbers.map((n, i) => (
                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                            <a href='#' className='page-link'
                                                onClick={() => changeCPage(n)}
                                            >{n}</a>
                                        </li>
                                    ))
                                }
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={nextPage}
                                    >Next</a>
                                </li>
                            </ul>
                        </nav> */}
                    </div>
                </section>
            </section>}
            <Footer />
        </section>
    );
}

export default Membres;