import React from 'react'

function Loading() {
    return (
        <div style={{ 
            position: "absolute", 
            height: "100%", 
            width: "100%", 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            alignContent: "center", 
            flexDirection: "column" 
        }}>
            <img src='../../../img/loading.svg' alt="Loading" /><br/>
            
        </div>
    )
}

export default Loading
