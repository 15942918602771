import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from "primereact/checkbox";
import { Divider } from 'primereact/divider';
import { ThemeContext } from '../context/ContextHeader';
import { URL_AXIOS } from '../URL/URL';
import axios from 'axios';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BiLogOutCircle } from "react-icons/bi";
function Header() {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState('top');
    const [bool, setBool] = useState(true);
    const [ingredients, setIngredients] = useState([]);
    //const [membres, setMembres] = useState([]);
    //const [getURLFolder, setGetURLFolder] = useState('');
    //const [dataMembre, setDataMembre] = useState([]);


    const {
        items, setItems,
        disable, setDisable,
        disableAvis, setDisableAvis,
        dataAvisSend, setDataAvisSend,
        dataAvisReceive, setDataAvisReceive,
        medecinID, setMedecinID,
        datedebut, setDatedebut,
        dossiersEn, setDossiersEn,
        dossiers, setDossiers,
        datefin, setDateFin,
        nbFoldersSend, setNbFoldersSend,
        nbFoldersArchive, setNbFoldersArchive,
        notifi, setNotifi,
        notifiLimit, setNotifiLimit,
        objet, setObjet,
        contenu, setContenu,
        name_recep, setName_recep,
        societe_recep, setSociete_recep,
        fichier_notification, setFichier_notification,
        created_at, setCreated_at,
        name_sender, setName_sender,
        societe_sender, setSociete_sender,
        dataMembre, setDataMembre,
        getURLFolder, setGetURLFolder,
        notifiRecep, setNotifiRecep,
        nbFolderAvisReceive,
        userID, setUserID,
        notifications, setNotifications,
        disableRefresh, setDisableRefresh,
        user_data, setUser_data,
        sites, setSites,
        membres, setMembres,
        examens, setExamens,
        gains, setGains,
        siteMembre, setSiteMembre,
        siteManipulateurs, setSiteManipulateurs,
        mcr, setMcr,
        abonnementSite, setabonnementSite,
        DossiersRecu, setDossiersRecu,
        dataExamenStatistique, setDataExamenStatistique,
        periodeContext, setPeriodeContext,
        membreValueContext, setMembreValueContext,
        dossiersCorbeille, setdossiersCorbeille,
        DossiersArchives, setDossiersArchives,
        dossiersConfigGains, setDossiersConfigGains
    } = useContext(ThemeContext);
    const location = useLocation();
   

    const user_id = parseInt(sessionStorage.getItem("user_id"));

    const load_data_exams_stat = async (membre, periode) => {
        try {

            const response = await axios.get(URL_AXIOS + `load_data_exams/${membre}/${periode}`);
            if (response.data.status == 200) {
                setDataExamenStatistique(response.data.statistiqueAllData);

            } else {

            }


        } catch (error) {

        }
    }
    const LogOut = () => {
        sessionStorage.removeItem("nom");
        navigate('/')

    }
    const lister_contrat = async () => {
        try {

            const response = await axios.get(URL_AXIOS + 'index');
            if (response.data.status === 200) {
                setSiteMembre(response.data.data_membres_sites);

            }
        } catch (Error) {

        }
    };
    const liste_abonnement_sites = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_abonnements_sites');
            if (response.data.status === 200) {
                setabonnementSite(response.data.vue_sites_abonnements);
            }
        } catch (Error) {

        }

    }
    const liste_site_manipulateurs = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_manipulation');
            if (response.data.status == 200) {
                setSiteManipulateurs(response.data.vue_manipulateurs_site);

            }
        } catch (Error) {

        }

    }
    const liste_notificationByUsers = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `ListeNotificationReception/${userID}`);
            if (response.data.status === 200) {
                setNotifiRecep(response.data.notifications);
                setDataMembre(response.data);
                setGetURLFolder(response.data.folderUrl)


            }
        } catch (Error) {

        }
    }
 
    const AlertSave = () => {
        Swal.fire({
            title: 'Informations',
            text: 'Les données ont été enregistrées avec succès !',
            icon: 'success',

        })
    }
    const AddAvis = async () => {
        try {
            // Create an array to accumulate data
            const requestData = [];

            for (const value of items) {
                for (const ing of ingredients) {
                    const data = {
                        dossier_id: value,
                        membre_id_send: medecinID,
                        membre_id_receive: ing
                    };

                    // Push data into the array
                    requestData.push(data);
                }
            }

            // Make a single request with all the data
            const response = await axios.post(URL_AXIOS + 'addAvis', {
                dataAvis: requestData
            });

            if (response.data.status === 200) {
                // Handle success message or data after making the request
                setVisible(false);

                AlertSave();
            } else {
                alert("ERREUR 500 " + response.data.data);
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    };
    const ListeAvisSend = async (val) => {
        try {
            if (val !== null) {
                const response = await axios.get(URL_AXIOS + `liste_avis_envoye/${val}`);
                if (response.data.status == 200) {
                    setDataAvisSend(response.data.data_avis_send);

                } else {

                }
            }

        } catch (error) {

        }
    }

    const ListeAvisReceive = async (val) => {
        try {
            if (val !== null) {
                const response = await axios.get(URL_AXIOS + `liste_avis_sollicite/${val}`);
                if (response.data.status == 200) {
                    setDataAvisReceive(response.data.data_avis_sollicite);

                } else {

                }
            }

        } catch (error) {

        }
    }
    const onIngredientsChange = (e) => {
        let _ingredients = [...ingredients];

        if (e.checked)
            _ingredients.push(e.value);
        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setIngredients(_ingredients);
    }
    const liste_dossiersCorbeille = async () => {
        try {

            const response = await axios.get(URL_AXIOS + "liste_dossiers_retireAdmin");
            if (response.data.status === 200) {
                setdossiersCorbeille(response.data.dossier_send);
            }
        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }

    const liste_dossiers_all_Not_Gains_Config = async () => {
        try {
            const response = await axios.get(URL_AXIOS + "liste_dossiersConfigGains");
            if (response.data.status == 200) {
                setDossiersConfigGains(response.data.dossier_send);

            }
        } catch (error) {
            // alert("Erreur " + error.message);
        }
    }
    const [query, setQuery] = useState("");
    const handleQueryText = async (e) => {
        try {
            const query_name = e.target.value;
            setQuery(query_name);

            if (location.pathname == "/resultat") {
                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers

                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_pl_by_input_recherche/${query_name}/${medecinID}`);
                    if (response.data.status === 200) {
                        //setDossiersEn(response.data.vue_dossier_send_pl_input);
                        setDossiers(response.data.vue_dossier_send_pl_input);


                    }
                } else {
                    liste_dossiers_by_siteEnteteAccordion(medecinID);
                }
            } else if (location.pathname == "/compte_expertise") {
                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers

                    const response = await axios.post(URL_AXIOS + `rechercher_solliciteAndReceive/${query_name}/${medecinID}`);
                    if (response.data.status === 200) {
                        //setDossiersEn(response.data.vue_dossier_send_pl_input);
                        setDataAvisSend(response.data.data_avis_send);
                        setDataAvisReceive(response.data.data_avis_sollicite);


                    }
                } else {
                    ListeAvisReceive(medecinID)
                    ListeAvisSend(medecinID)
                }
            } else if (location.pathname == "/notifications") {
                if (query_name.length > 0) {
                    const response = await axios.post(URL_AXIOS + `recherche_notification_by_objet_or_siteBySite/${userID}/${query_name}`);
                    if (response.data.status === 200) {
                        setNotifications(response.data.notifications);
                        setDataMembre(response.data);
                        setGetURLFolder(response.data.folderUrl)

                    } else {

                    }
                } else {
                    liste_notificationByUsers();
                }
            } else if (location.pathname == "/lireNotification") {
                if (query_name.length > 0) {
                    const response = await axios.post(URL_AXIOS + `RechercheNotificationsLecture/${userID}/${query_name}`);
                    if (response.data.status === 200) {
                        setNotifiRecep(response.data.notifications);
                        //setDataMembre(response.data);
                        // setGetURLFolder(response.data.folderUrl)

                        console.log("Data " + response.data.notifications)

                    } else {
                        console.log("Erreur")
                    }
                } else {
                    liste_notificationByUsers();
                }
            } else if (location.pathname == "/users") {
                if (query_name.length > 0) {
                    const search_data = user_data.filter(item =>
                        item.name.toLowerCase().includes(query.toLowerCase())
                    );

                    setUser_data(search_data);
                } else {
                    liste_site_users();
                }
            } else if (location.pathname == "/sites") {
                if (query_name.length > 0) {
                    const search_data = sites.filter(item =>
                        item.societe.toLowerCase().includes(query.toLowerCase())
                    );
                    setSites(search_data);

                } else {
                    liste_site();
                }
            } else if (location.pathname == "/examens") {
                if (query_name.length > 0) {
                    const search_data = examens.filter(item =>
                        item.libelle_exam.toLowerCase().includes(query.toLowerCase())
                    );

                    setExamens(search_data);

                } else {
                    liste_examens();
                }
            } else if (location.pathname === "/gains") {
                if (query_name.length > 0) {

                    const data = {
                        input: query_name
                    }
                    const response = await axios.get(URL_AXIOS + `rechercheByCategorieExamen/${query_name}`);
                    if (response.data.status === 200) {
                        //alert(response.data.gains)
                        setGains(response.data.gains);
                    } else {

                    }

                } else {
                    liste_gains();
                }
            } else if (location.pathname == "/contrats") {
                if (query_name.length > 0) {
                    const search_data = siteMembre.filter(item =>
                        item.nom.toLowerCase().includes(query.toLowerCase()) || item.societe.toLowerCase().includes(query.toLowerCase()) || item.prenom.toLowerCase().includes(query.toLowerCase())
                    );

                    setSiteMembre(search_data);

                } else {
                    lister_contrat();
                }
            } else if (location.pathname == "/manipulateurs") {
                if (query_name.length > 0) {
                    const search_data = siteManipulateurs.filter(item =>
                        item.nom.toLowerCase().includes(query.toLowerCase()) || item.societe.toLowerCase().includes(query.toLowerCase()) || item.prenom.toLowerCase().includes(query.toLowerCase())
                    );

                    setSiteManipulateurs(search_data);

                } else {
                    liste_site_manipulateurs();
                }
            } else if (location.pathname == "/modelcompterendu") {
                if (query_name.length > 0) {
                    const search_data = mcr.filter(item =>
                        item.titre.toLowerCase().includes(query.toLowerCase())
                    );

                    setMcr(search_data);

                } else {
                    list_mcr();
                }
            } else if (location.pathname == "/abonnement") {
                if (query_name.length > 0) {
                    const search_data = abonnementSite.filter(item =>
                        item.societe.toLowerCase().includes(query.toLowerCase())
                    );

                    setabonnementSite(search_data);

                } else {
                    liste_abonnement_sites();
                }
            } else if (location.pathname == "/dossiersTransferes") {
                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers

                    const response = await axios.post(URL_AXIOS + `recherche_dossier_transfererByAdmin/${query_name}`);
                    if (response.data.status === 200) {
                        //setDossiersEn(response.data.vue_dossier_send_pl_input);
                        setDossiers(response.data.dossier_transfere);


                    }
                } else {
                    liste_dossiers_transfere();
                }
            } else if (location.pathname == "/config-gains") {
                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers

                    const response = await axios.post(URL_AXIOS + `recherche_dossier_transfererByAdminConfigGains/${query_name}`);
                    if (response.data.status === 200) {
                        //setDossiersEn(response.data.vue_dossier_send_pl_input);
                        setDossiersConfigGains(response.data.dossier_transfere);


                    }
                } else {
                    liste_dossiers_all_Not_Gains_Config();
                }
            } else if (location.pathname == "/resultats") {
                if (query_name.length > 0) {
                    const response = await axios.post(URL_AXIOS + `liste_resultatByAllSiteSearchByInput/${query_name}`);
                    if (response.data.status === 200) {
                        setDossiersRecu(response.data.vue_dossier_interpreter);
                    }
                } else {
                    liste_resultat();
                }
            } else if (location.pathname == "/statistiques") {
                if (query_name.length > 0) {

                    const response = await axios.post(URL_AXIOS + `recherche_in_vue_autres_statistique/${query_name}/${membreValueContext}/${periodeContext}`);
                    if (response.data.status === 200) {
                        setDataExamenStatistique(response.data.statistiqueAllData);
                    } else {
                        load_data_exams_stat(membreValueContext, periodeContext)
                    }
                } else {

                }
            } else if (location.pathname == "/corbeille") {
                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers

                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_pl_by_inputAdminCorbeille/${query_name}`);
                    if (response.data.status === 200) {
                        //setDossiersEn(response.data.vue_dossier_send_pl_input);
                        setdossiersCorbeille(response.data.vue_dossier_send_pl_input);

                    }
                } else {
                    liste_dossiersCorbeille();
                }
            } else if (location.pathname == "/archives") {
                if (query_name.length > 0) {
                    const response = await axios.post(URL_AXIOS + `liste_resultatArchiveSearchByInputAdmin/${query_name}`);
                    if (response.data.status === 200) {
                        setDossiersArchives(response.data.vue_dossier_interpreter);
                    }
                } else {
                    liste_dossiersArchive_by_site();
                }
            } else if (location.pathname == "/membres") {
                if (query_name.length > 0) {
                    const response = await axios.get(URL_AXIOS + `rechercher_membreByInput/${query_name}`);
                    if (response.data.status === 200) {
                        setMembres(response.data.membres);
                     
                    }else{
                     
                    }
                } else {
                    liste_membres();
                }
            }




        } catch (error) {
        }
    };
    const list_mcr = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'list_mcr_all');
            if (response.data.status === 200) {
                setMcr(response.data.mcr);
            }
        } catch (Error) {

        }
    }
    const liste_gains = async () => {
        try {

            const response = await axios.get(URL_AXIOS + 'liste_gains');
            if (response.data.status === 200) {
                setGains(response.data.gains);
            }
        } catch (Error) {

        }

    }
    const liste_examens = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_examen');
            if (response.data.status === 200) {
                setExamens(response.data.examens);
            }
        } catch (Error) {

        }

    }
    const liste_membres = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'membres');
            if (response.data.status === 200) {
                setMembres(response.data.membres);
                //setMembre(response.data.membres.length);
            }
        } catch (Error) {

        }

    }
    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status === 200) {
                setSites(response.data.sites);
                //setLogin(response.data.sites.length)
                //setFilteredData(response.data.sites);
            }
        } catch (Error) {

        }

    }
    const liste_site_users = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'users_sites');
            if (response.data.status === 200) {
                setUser_data(response.data.users_sites);
                //setUser(response.data.users_sites.length)
            }
        } catch (Error) {

        }

    }

    const liste_dossiers_transfere = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_dossiers_all_trasnfers');
            if (response.data.status == 200) {
                setDossiers(response.data.dossier_transferer);

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    };
    const liste_resultat = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_resultatAdmin');
            if (response.data.status == 200) {
                setDossiersRecu(response.data.vue_dossier_interpreter);

            }
        } catch (error) {
            // alert("Erreur " + error.message);
        }
    };
    const liste_notificationlECT = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `ListeNotificationReception/${user_id}`);
            if (response.data.status === 200) {
                setNotifiRecep(response.data.notifications);
            }
        } catch (Error) {

        }
    }
    const lireNotification = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `updateStatus_notification/${id}`);
            if (response.data.status == 200) {

                navigate('/lireNotification')
                setObjet(response.data.notification.objet)
                setContenu(response.data.notification.contenu)
                setName_recep(response.data.notification.name_recep)
                setName_sender(response.data.notification.name_sender)
                setCreated_at(response.data.notification.created_at)
                setFichier_notification(response.data.notification.fichier_notification)
                setSociete_recep(response.data.notification.societe_recep);
                liste_notificationlECT()
            }
        } catch (error) {
            // alert("Erreur " + error.message);
        }
    }

    const annuler_research = () => {
        setDateFin('');
        setDatedebut('');
        setBool(true);

        if (location.pathname == "/notifications") {
            liste_notification();
        } else if (location.pathname == "/abonnement") {
            liste_abonnement_sites();
        } else if (location.pathname == "/dossiersTransferes") {
            liste_dossiers_transfere();
        } else if (location.pathname == "/resultats") {
            liste_resultat();
        } else if (location.pathname == "/corbeille") {
            liste_dossiersCorbeille();
        } else if (location.pathname == "/archives") {
            liste_dossiersArchive_by_site();
        } else if (location.pathname == "/config-gains") {
            liste_dossiers_all_Not_Gains_Config();
        } else if (location.pathname == "/membres") {
            liste_membres()
        }




    }
    const liste_notification = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_notificationsByMembreId/${user_id}`);
            if (response.data.status === 200) {
                setNotifications(response.data.notifications);
                setDataMembre(response.data);
                setGetURLFolder(response.data.folderUrl)


            }
        } catch (Error) {

        }
    }
    const liste_dossiersArchive_by_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + "liste_resultatArchiveByAdmin");
            if (response.data.status == 200) {
                setDossiersArchives(response.data.vue_dossier_interpreter);

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const rechercher_dossier = async () => {

        try {
            if (location.pathname == "/notifications") {
                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `recherche_notificationByUsers/${datedebut}/${datefin}/${user_id}`);
                    if (response.data.status == 200) {
                        setNotifications(response.data.notifications);
                        setBool(false);
                    } else {

                    }
                } else {
                    setBool(true);
                    liste_notification();
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/abonnement") {


                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `recherche_vue_sites_abonnements/${datedebut}/${datefin}`);
                    if (response.data.status === 200) {
                        setabonnementSite(response.data.vue_sites_abonnements)
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/dossiersTransferes") {
                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_plAdmin/${datedebut}/${datefin}`);
                    if (response.data.status === 200) {
                        setDossiers(response.data.vue_dossier_send_pl);
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/config-gains") {
                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_plAdminConfigGains/${datedebut}/${datefin}`);
                    if (response.data.status === 200) {
                        setDossiersConfigGains(response.data.vue_dossier_send_pl);
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/resultats") {

                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `liste_resultatByAllSiteSearchByPeriod/${datedebut}/${datefin}`);
                    if (response.data.status === 200) {
                        setDossiersRecu(response.data.vue_dossier_interpreter);
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    //alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/corbeille") {
                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_plAdmin/${datedebut}/${datefin}`);
                    if (response.data.status === 200) {
                        setdossiersCorbeille(response.data.vue_dossier_send_pl);
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/archives") {


                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `liste_resultatArchiveBySiteSearchByPeriodAdmin/${datedebut}/${datefin}`);
                    if (response.data.status === 200) {
                        setDossiersArchives(response.data.vue_dossier_interpreter);
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/membres") {


                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `rechercher_membreByDate/${datedebut}/${datefin}`);
                    if (response.data.status === 200) {
                        setMembres(response.data.membres);
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    alert("Veuillez remplir les deux dates svp")
                }
            }
        } catch (error) {
            // alert("Erreur " + error.message);
        }
    }
    const liste_dossiers_by_siteEnteteAccordion = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete/${id}`);
            if (response.data.status == 200) {
                setDossiersEn(response.data.dossier_send);
                setDossiers(response.data.data_dossier_sub);


            }
        } catch (error) {
            // alert("Erreur " + error.message);
        }
    }

  

    useEffect(() => {
        const validPaths = [
            "/users", "/dossiersTransferes", "/resultats", "/sites", 
            "/membres", "/modalite", "/examens", "/gains", "/contrats", 
            "/manipulateurs", "/notifications", "/modelcompterendu", 
            "/statistiques", "/abonnement", "/agenda", "/corbeille", "/config-gains","/lireNotification"
        ];

        if (validPaths.includes(location.pathname)) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [location.pathname]);
    useEffect(() => {
        // liste_membre();
        //liste_notification();
        // if (location.pathname != "/addNotifications" || location.pathname != "/notifications") {
        //     setDisable(true)
        // } else {

        // }
    }, [notifiRecep]);

    useEffect(() => {

    }, [dossiersConfigGains])



    return (
        <header class="header black-bg">
            <ConfirmDialog />
            <Dialog visible={visible} position={position} style={{ width: '30vw', height: '100vh' }} onHide={() => setVisible(false)} draggable={false} resizable={false}>
                <center><h3>Demander l'avis d'un autre medécin</h3></center>


                <h3 style={{ color: 'red', fontSize: "15px" }}>Disponibilités</h3>
                <Divider />
                {membres.map((val) => (
                    <div className='row'>
                        <div className='col col-md-10'>
                            <h6 style={{ fontSize: 15, }}>{val.nom + " " + val.prenom}</h6>
                        </div>

                        <div className='col col-md-2'>
                            <Checkbox inputId={`dossier_${val.id}`} style={{ marginTop: 5, marginLeft: 20 }} name={val.id} value={val.id} onChange={onIngredientsChange} checked={ingredients.includes(val.id)} />
                        </div>



                        <Divider />
                    </div>


                ))}


                <button type='button' onClick={() => AddAvis()} className='btn btn-success form-control'>Envoyer</button>
            </Dialog>
            <a href="#" class="logo"><b><span style={{ color: 'red' }}>TERA</span><span>TEX</span></b></a>
            <div class="nav notify-row" id="top_menu" style={{ marginLeft: "58px" }}>
                <ul class="nav top-menu">
                    <li class="dropdown" title='Dossiers transférés'>
                        <Link to={'/dossiersTransferes'}>
                            <i class="fa fa-folder"></i>
                            <span class="badge bg-theme">{dossiers.length > 0 ? dossiers.length : 0}</span>
                        </Link>

                    </li>
                    <li id="header_inbox_bar" class="dropdown" title='Avis reçus'>
                        <Link to={'/resultats'}>
                            <i class="fa fa-copy"></i>
                            <span class="badge bg-theme">{DossiersRecu.length > 0 ? DossiersRecu.length : 0}</span>
                        </Link>

                    </li>
                    <li id="header_inbox_bar" class="dropdown" title='Corbeille'>
                        <Link to={'/corbeille'}>
                            <i class="fa fa-bitbucket"></i>
                            <span class="badge " style={{ backgroundColor: 'red' }}>{dossiersCorbeille.length > 0 ? dossiersCorbeille.length : 0}</span>
                        </Link>

                    </li>
                    <li id="header_notification_bar" class="dropdown" title='Notifications reçus'>
                        <a data-toggle="dropdown" class="dropdown-toggle" href="index.html#">
                            <i class="fa fa-bell-o"></i>
                            <span class="badge bg-warning">{notifiLimit.length}</span>
                        </a>
                        <ul class="dropdown-menu extended notification">
                            <div class="notify-arrow notify-arrow-yellow"></div>
                            <li>
                                <p class="yellow">Vous avez {notifiLimit.length} notifications</p>
                            </li>
                            {
                                notifiLimit.map((noti) => (
                                    <li >
                                        <Link onClick={() => lireNotification(noti.id_notification)}>
                                            <span class="label label-success"><i class="fa fa-envelop"></i></span>
                                            &nbsp;{noti.name_sender}<br />
                                            <span class="small italic" style={{ marginLeft: 20, marginTop: 10 }}>{noti.objet}</span>
                                        </Link>
                                    </li>
                                ))
                            }




                            <li>
                                <Link to={'/lireNotification'} style={{ color: "black" }}>Voir toutes les notifications</Link>
                            </li>
                        </ul>
                    </li>
                    <li id="header_inbox_bar" class="dropdown" title='Configuration gains'>
                        <Link to={'/config-gains'}>
                            <i class="fa fa-money"></i>
                            <span class="badge " style={{ backgroundColor: '#1a6868' }}>{dossiersConfigGains.length > 0 ? dossiersConfigGains.length : 0}</span>
                        </Link>

                    </li>
                </ul>

            </div>
            <div class="top-menu">
                <div style={{
                    marginTop: 13,
                    position: 'absolute',
                    marginLeft: "34%",
                    display: "flex"
                }}>
                    <h6 style={{ color: 'white', marginTop: 10 }}>Date début *:</h6>
                    <input type="date" className='form-control'
                        style={{ width: "15%", marginLeft: 5 }}
                        onChange={(e) => setDatedebut(e.target.value)}
                        value={datedebut}
                        disabled={disable}
                    />
                    <h6 style={{ marginLeft: 5, color: 'white', marginTop: 10 }}>Date Fin *:</h6>
                    <input type="date"
                        className='form-control'
                        style={{ width: "15%", marginLeft: 5 }}
                        value={datefin}
                        onChange={(e) => setDateFin(e.target.value)}
                        disabled={disable}
                    />
                    <button disabled={disable} type='button' title='Filtrer par dates' onClick={() => bool ? rechercher_dossier() : annuler_research()} style={{ marginLeft: 5 }} className={bool ? 'btn btn-success' : 'btn btn-danger'}>
                        <i style={{ color: 'white' }} className={bool ? 'fa fa-filter' : 'fa fa-remove'}></i>
                    </button>
                    <input type='text' className='form-control '
                        style={{
                            width: 300,
                            marginLeft: 5
                        }}
                        placeholder='Recherche...'
                        onChange={handleQueryText}
                        disabled={disable}
                    />
                    <button type='button' disabled={disable} className='btn btn-primary' style={{ marginLeft: 5 }}>
                        <i style={{ color: 'white' }} className='fa fa-search'></i>

                    </button>

                    { /*  <button type='button' style={{ marginLeft: 5 }} disabled={disable} onClick={confirm1} className={(location.pathname == "/corbeille" || location.pathname == "/archives")  ? 'btn btn-primary' : 'btn btn-danger'} title='Suppression dossiers'>
                        <i style={{ color: 'white' }} className={(location.pathname == "/corbeille" || location.pathname == "/archives") ? 'fa fa-refresh' : 'fa fa-trash'}></i>
                    </button>
                    <button type='button' onClick={() => setVisible(true)} disabled={disableAvis} style={{ marginLeft: 5 }} title="Demandez un avis sur les dossiers sélectionnés(Contre-expertise)" className='btn btn-success'>
                        <i style={{ color: 'white' }} className='fa fa-desktop'></i>
                    </button>*/}

                </div>
                <button type='button' className="btn btn-danger" onClick={() => LogOut()} style={{ height: 35, marginLeft: 5, float: 'right', marginTop: 13 }}>
                    <BiLogOutCircle style={{ fontSize: 15 }} />
                </button>
            </div>
        </header >
    );
}

export default Header;