import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../Header';
import AsideBar from '../AsideBar';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import { Divider } from 'primereact/divider';
import { Badge } from 'primereact/badge';
function DetailFolder() {
    const navigate = useNavigate();
    const { id } = useParams();
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [visible, setVisible] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [statutUrg, setStatutUrg] = useState(1);
    const [categorie_fichier, setCategorie_fichier] = useState("");
    const [Examen_1, setExamen_1] = useState('');
    const [Examen_2, setExamen_2] = useState('');
    const [chemin_fichier_1, setChemin_fichier_1] = useState('');
    const [chemin_fichier_2, setChemin_fichier_2] = useState('');
    const [bulletin_examen, setBulletin_examen] = useState('');
    const [chemin_bulletin, setChemin_bulletin] = useState('');
    const [nom_pat, setNom_pat] = useState('');
    const [age, setAge] = useState("");
    const [LibelleAge, setLibelleAge] = useState("");
    const [sexe, setSexe] = useState("");
    const [prescripteur, setPrescripteur] = useState("");
    const [statut, setStatut] = useState("");
    const [etat, setEtat] = useState("");
    const [type, setType] = useState('');
    const [mail, setMail] = useState('');
    const [ville, setVille] = useState('');
    const [pays, setPays] = useState('');
    const [created_at, setCreated_at] = useState('');
    const [gain, setGain] = useState('');
    const [nom_medecin, setNom_medecin] = useState('');
    const [qualification, setQualification] = useState('');
    const [specialite, setSpecialite] = useState('');
    const [tel_medecin, setTel_medecin] = useState('');
    const [pourcentage, setPourcentage] = useState('');
    const [pays_medecin, setPays_medecin] = useState('');
    const [ville_medecin, setVille_medecin] = useState('');
    const [exp, setExp] = useState('');
    const liste_dossiers_by_siteId = async (dossier_id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_dossiersId/${dossier_id}`);
            if (response.data.status === 200) {
                // setDossiers(response.data.dossier_send);

                setSiteName(response.data.dossier_send.societe);
                setCategorie_fichier(response.data.dossier_send.categorie_fichier);
                setExamen_1(response.data.dossier_send.nom_fichier);
                setExamen_2(response.data.dossier_send.nom_fichier_2);
                setChemin_fichier_1(response.data.dossier_send.chemin_fichier);
                setChemin_fichier_2(response.data.dossier_send.chemin_fichier_2);
                setBulletin_examen(response.data.dossier_send.bulletin_examen);
                setChemin_bulletin(response.data.dossier_send.chemin_bulletin);
                setNom_pat(response.data.dossier_send.nom_pat);
                setAge(response.data.dossier_send.age_pat);
                setSexe(response.data.dossier_send.sexe_pat);
                setLibelleAge(response.data.dossier_send.libelle_age_pat);
                setPrescripteur(response.data.dossier_send.prescripteur);
                setStatut(response.data.dossier_send.statut);
                setEtat(response.data.dossier_send.etat);
                setType(response.data.dossier_send.type_societe)
                setMail(response.data.dossier_send.email_societe)
                setVille(response.data.dossier_send.ville_societe)
                setPays(response.data.dossier_send.pays_societe)
                setCreated_at(response.data.dossier_send.created_at)
                setGain(response.data.dossier_send.gain)
                const nomcomplet = response.data.dossier_send.nom_medecin + " " + response.data.dossier_send.prenom_medecin;
                setNom_medecin(nomcomplet)
                const fonction = response.data.dossier_send.qualification + " " + response.data.dossier_send.specialite;
                setSpecialite(fonction);
                setExp(response.data.dossier_send.exp);
                setTel_medecin(response.data.dossier_send.tel_medecin)
                setPourcentage(response.data.dossier_send.pourcentage)
                setPays_medecin(response.data.dossier_send.pays_medecin)
                setVille_medecin(response.data.dossier_send.ville_medecin)


            } else {

            }

        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }
    useEffect(() => {
        liste_dossiers_by_siteId(id)
    }, [])
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div className='container-fluid'>
                        <div className='row card' style={{ height: "50px" }}>
                            <h4 style={{ marginLeft: "10px" }}>Détails</h4>
                        </div>
                        <div className='row' >
                            <div class="col col-md-4">
                                <div class="card font-bold border-round" style={{ width: "100%", height: 'auto', position: 'relative',padding:"0 0 10px" }}>
                                    <h2 style={{ color: 'red', fontSize: '20px', marginLeft: 10, textAlign: 'left' }}>Sites</h2>
                                    <Divider />
                                    <h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{siteName}</h2>
                                    <Divider />
                                    <h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{type}</h2>
                                    <Divider />
                                    <h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{mail}</h2>
                                    <Divider />
                                    <h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{pays + "-" + ville}</h2>

                                </div>
                            </div>
                            <div class="col col-md-4">
                            <div class="card font-bold border-round m-1" style={{ width: "100%", height: 'auto', position: 'relative',padding:"0 0 10px" }}>
                                    <h2 style={{ color: 'red', fontSize: '20px', marginLeft: 10, textAlign: 'left' }}>Dossier</h2>
                                    <Divider />
                                    <h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{nom_pat}</h2>
                                    <Divider />
                                    {Examen_1 && <div><h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{Examen_1}</h2>
                                        <Divider /> </div>}
                                    {chemin_fichier_1 && <div><h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{chemin_fichier_1}</h2>
                                        <Divider /> </div>}
                                    {Examen_2 && <div><h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{Examen_2}</h2>
                                        <Divider /> </div>}
                                    {chemin_fichier_2 && <div><h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{chemin_fichier_2}</h2>
                                        <Divider /> </div>}
                                    <h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{categorie_fichier}</h2>
                                    <Divider />
                                    <h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{created_at}</h2>
                                    <Divider />
                                    <h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{new Intl.NumberFormat().format(gain) + " XAF"}</h2>

                                </div>
                            </div>
                            <div class="col col-md-4">
                                 
                            <div class="card font-bold border-round m-1" style={{ width: "100%", height: 'auto', position: 'relative',padding:"0 0 10px" }}>
                                    <h2 style={{ color: 'red', fontSize: '20px', marginLeft: 10, textAlign: 'left' }}>Médecin</h2>
                                    <Divider />
                                    <h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{nom_medecin}</h2>
                                    <Divider />
                                    <h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{specialite}</h2>
                                    <Divider />
                                    <div style={{ display: 'flex' }}>
                                        <Badge severity={exp == "O" ? "success" : "danger"} value="" style={{ width: 10, height: 10, marginLeft: 10, marginTop: 7 }}></Badge><h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left', marginTop: '0px' }}>{exp == "O" ? 'Disponible'+ ' - ' + tel_medecin : 'Indisponible' + ' - ' + tel_medecin}</h2>
                                    </div>
                                    <h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{pourcentage}</h2>
                                    <Divider />
                                    <h2 style={{ fontSize: '15px', marginLeft: 10, textAlign: 'left' }}>{pays_medecin + "-" + ville_medecin}</h2>
                                </div>
                            </div>
                     

                        </div>
                    </div>
                </section>
            </section>
        </div>
    )
}

export default DetailFolder
