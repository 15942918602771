import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import { useNavigate } from 'react-router-dom';
// import { MultiSelect } from 'react-multi-select-component';
import { Editor } from "primereact/editor";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { MultiSelect } from 'primereact/multiselect';


function AddNotification() {
    const { datedebut, dossiersEn, setDossiersEn, dossiers, setDossiers, items, setItems,
        nbFoldersSend, setNbFoldersSend,
        disable, setDisable,
        disableAvis, setDisableAvis
    } = useContext(ThemeContext);

    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const [isListening, setIsListening] = useState(false);
    const [recognition, setRecognition] = useState(null);
    const [objet, setObjet] = useState("");
    const [contenu, setContenu] = useState("");
    const [user_id_sender, setUser_id_sender] = useState(11);
    const [user_id_reception, setUser_id_reception] = useState(0);

    const navigate = useNavigate();
    const [membres, setMembres] = useState([]);
    const [text, setText] = useState('');
    const [selected, setSelected] = useState([]);
    const [validaStatus, setValidaStatus] = useState(false);
    const toast = useRef(null);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    const AlertSave = () => {
        Swal.fire({
            title: 'Réussi !',
            text: 'La notification a été envoyée avec succès !',
            icon: 'success',

        })
    }
    const addNotificat = async () => {
        try {
            if (objet != "" && text != "" && selected.length>0) {

                const formData = new FormData();
                formData.append('objet', objet);
                formData.append('contenu', text);
                formData.append('user_id_sender', user_id);
                formData.append('user_id_reception', selected);
                formData.append('file', selectedFile);

                const data_notifi = {
                    objet: objet,
                    contenu: text.replace(/<[^>]*>/g, ''),
                    user_id_sender: user_id,
                    user_id_reception: selected,
                    file: selectedFile
                }

                const res = await axios.post(URL_AXIOS + "addNotifications", data_notifi, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Use 'multipart/form-data' for FormData
                    },
                });
                if (res.data.status === 200) {
                    const message = "Les informations ont été enregistrées";
                    AlertSave();
                    setText("");
                    setObjet('');
                    setSelected([]);
                    setSelectedFile(null);
                    navigate('/notifications')

                } else {
                    Swal.fire({
                        title: 'Erreur !',
                        text: "La notification n'a pas été enregistré !",
                        icon: 'error',
                    });
                }
            } else {
          
                Swal.fire({
                    title: 'Erreur !',
                    text: 'Veuillez renseigner tous les champs obligatoires!',
                    icon: 'error',
                });
            }
        } catch (error) {
            alert("Error saving : " + error.message)
        }

    }


    const handleSelected = (selected) => {
        setSelected(selected)
    }

    const liste_membres = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'users_sites');
            if (response.data.status === 200) {
                setMembres(response.data.users_sites);
                // alert(JSON.stringify(response.data.users_sites))
            }
        } catch (Error) {

        }
    }
    useEffect(() => {
        setDisableAvis(true);
        liste_membres();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [])
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div className='row card' style={{ height: 'auto' }}>

                        <div class="">
                            <form role="form" class="">
                                <div className="form-group">
                                    <div className='col col-md-4'>
                                        <input type='text' value={objet} onChange={(e) => setObjet(e.target.value)} className='form-control'
                                            placeholder='Objet * '
                                        />
                                    </div>
                                    <div className='col col-md-4'>

                                        <MultiSelect
                                            options={membres}
                                            value={selected}
                                            filter
                                            onChange={(e) => handleSelected(e.value)}
                                            optionLabel='name'
                                            optionValue='id'
                                            style={{ width: "100%", height: 35 }}
                                            placeholder="Sélectionner un ou plusieurs utilisateurs *"
                                            />
                                               
                                        
                                    </div>
                                    <div className='col col-lg-4'>
                                            <div className="">
                                                <input type="file" onChange={handleFileChange} className='form-control' />
                                            </div>
                                        </div>
                                </div>
                             
                                <div className='form-group'>
                                    <div className='col col-lg-12'>
                                        <div className="">
                                            <Editor value={text}
                                                onTextChange={(e) => setText(e.htmlValue)}
                                                style={{ height: '500px' }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="col col-lg-12">
                                        <button type='button' onClick={() => addNotificat()} className='btn btn-info'>Enregistrer</button>
                                        <button type='button' onClick={() => navigate('/notifications')} className='btn btn-danger ' style={{ marginLeft: 10 }}>Annuler</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </div>
    );
}

export default AddNotification;