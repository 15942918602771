import React, { useEffect, useRef } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import PhoneInput from 'react-phone-input-2'
import { useState } from 'react';
import { Country, State, City } from 'country-state-city';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import { useContext } from 'react';
import Footer from '../Footer';
function Membre() {

    const [matricule, setMatricule] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [sexe, setSexe] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [qualification, setQualification] = useState("");
    const [specialite, setSpecialite] = useState("");
    const [experience, setExperience] = useState("");
    const [pays, setPays] = useState('');
    const [ville, setVille] = useState("");
    const [biographie, setBiographie] = useState("");
    const [image, setImage] = useState("");
    const [statut, setStatut] = useState("");
    const [etat, setEtat] = useState("");
    const [exp, setExp] = useState("");
    const [signature, setSignature] = useState(null);

    const navigate = useNavigate();

    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [phone, setPhone] = useState("242");
    const [countries, setCountries] = useState([]);

    const [region, setRegion] = React.useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [departement, setDepartement] = useState('');
    const fileInputRef = useRef(null);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setSignature(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const addMembre = () => {
        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('matricule', matricule);
        formData.append('nom', nom);
        formData.append('prenom', prenom);
        formData.append('sexe', sexe);
        formData.append('email', email);
        formData.append('telephone', telephone);
        formData.append('qualification', qualification);
        formData.append('specialite', specialite);
        formData.append('experience', experience);
        formData.append('pays', pays);
        formData.append('ville', departement);
        formData.append('biographie', biographie);
        formData.append('signature', signature);
        axios.post(URL_AXIOS + 'addMembre', formData)
            .then((response) => {
                alert(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const handleCountryChange = (e) => {
        const name_get = e.target.value;
        setPays(name_get);

        const filtered = countries.filter((item) => item.name === name_get);
        setFilteredData(filtered);

        filtered.forEach((filter) => {
            const states = State.getStatesOfCountry(filter.isoCode);
            const phonecode = filter.phonecode;
            setRegion(states);
            // setCode(phonecode)
        });
    };

    useEffect(() => {

        const fetchCountries = async () => {
            try {
                //const response = await axios.get('https://api.countrystatecity.in/v1/countries');
                setCountries(Country.getAllCountries());
            } catch (error) {
                console.log(error);
            }
        };

        fetchCountries();

        if(usernameconnected===null || usernameconnected===""){
            navigate('/')
        }
    }, []);
    return (
        <section id="container">
            <Header />
            <AsideBar  username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div class="row">
                        <div class="form-panel">
                            <form role="form" class="form-horizontal style-form">


                                <div className='form-group'>

                                    <div className='col col-lg-4'>
                                        <input type='text' className='form-control' placeholder='Nom(s) *'
                                            value={nom} onChange={(e) => setNom(e.target.value)}
                                        />
                                    </div>
                                    <div className='col col-lg-4'>
                                        <input type='text' className='form-control' placeholder='Prenom(s) *'
                                            value={prenom} onChange={(e) => setPrenom(e.target.value)}
                                        />
                                    </div>
                                    <div className='col col-lg-4'>
                                        <select className='form-control' value={sexe} onChange={(e) => setSexe(e.target.value)}>
                                            <option>Sexe *</option>
                                            <option value='M'>M</option>
                                            <option value='F'>F</option>
                                        </select>
                                    </div>




                                </div>
                                <div className='form-group'>
                                    <div className='col col-lg-6'>
                                        <input type='text' className='form-control' placeholder='E-mail *'
                                            value={email} onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className='col col-lg-6'>
                                        <PhoneInput
                                            inputStyle={{ width: '100%' }}
                                            country={'cg'}
                                            enableSearch={true}
                                            value={telephone}
                                            onChange={(phone) => setTelephone(phone)}
                                            placeholder="Code * "
                                            //countryCodeEditable={false}
                                            inputProps={{
                                                required: true,

                                            }}

                                        />
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <div className='col col-lg-4'>
                                        <select className='form-control' value={qualification} onChange={(e) => setQualification(e.target.value)}>
                                            <option>Qualification *</option>
                                            <option value='Médecin'>Médecin</option>

                                        </select>
                                    </div>
                                    <div className='col col-lg-4'>
                                        <select className='form-control' value={specialite} onChange={(e) => setSpecialite(e.target.value)}>
                                            <option>Spécialité *</option>
                                            <option value='Radiologue'>Radiologue</option>

                                        </select>
                                    </div>
                                    <div className='col col-lg-4'>
                                        <select className='form-control' value={experience} onChange={(e) => setExperience(e.target.value)}>
                                            <option>Expérience *</option>
                                            <option value='1-2 an(s)'>1-2 an(s)</option>
                                            <option value='2-5 an(s)'>2-5 an(s)</option>
                                            <option value='5-10 an(s)'>5-10 an(s)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <div className='col col-lg-6'>
                                        <select className='form-control' value={pays} onChange={handleCountryChange}>
                                            <option value=""> Sélectionner un pays *</option>
                                            {countries.map((country) => (
                                                <option key={country.isoCode} value={country.name}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div class="col-lg-6">
                                        <select className='form-control' value={departement} onChange={(e) => setDepartement(e.target.value)}>
                                            <option value="">Sélectionner une ville *</option>
                                            {region.map((region) => (
                                                <option key={region.isoCode} value={region.name}>
                                                    {region.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>


                                </div>
                                <div className='form-group'>
                                    <div className='col col-md-2'>
                                        <img onClick={handleClick} style={{ width: '100%', height: '100%', cursor: 'pointer' }} src={image ? image : "https://img.freepik.com/vecteurs-premium/modele-vectoriel-pour-autocollant-banniere-autocollant-affiche-typographie-etc_98908-478.jpg"} />
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    <div className='col col-lg-10'>
                                        <textarea rows={10} className='form-control' placeholder='Biographie' value={biographie} onChange={(e) => setBiographie(e.target.value)}>

                                        </textarea>
                                    </div>
                                </div>



                                <div class="form-group">
                                    <div class="col-lg-10">
                                        <button class="btn btn-primary" type="button"
                                            onClick={() => addMembre()}

                                        >Enregistrer</button>
                                        <button class="btn btn-warning" type="button" style={{ marginLeft: 10 }}
                                            onClick={() => navigate('/membres')} >Retour</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </section>
    );
}

export default Membre;