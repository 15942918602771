import React from 'react';

function Footer() {
  return (
    <footer className="site-footer">
      <div className="text-center">
        <p>
          Copyright &copy; E-METECH, all rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;