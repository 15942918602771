import React from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import { useEffect } from 'react';
import "antd/dist/reset.css";  
import { AutoComplete } from 'antd';
import { useContext } from 'react';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
function EditionExamens() {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        boolDF, setBoolDF,
        boolResultat, setBoolResult,
        boolCorbeille, setBoolCorbeille,
        boolAbonnement, setBoolAbonnement,
        boolAgenda, setBoolAgenda,
        boolModalites, setBoolModalites,
        boolStatistiques, setBoolStatistiques,
        boolMcr, setBoolMcr,
        boolNotification, setBoolNotification,
        boolManipulateur, setBoolManipulateur,
        boolContrat, setBoolContrat,
        boolGains, setBoolGains,
        boolExamens, setBoolExamens,
        boolMembres, setBoolMembres,
        boolUsers, setBoolUsers,
        boolSites, setBoolSites
    } = useContext(ThemeContext);
    const usernameconnected=sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [examens, setExamens] = useState([]);
    const [examen, setExamen] = useState('');
    const data = examens.map(item => ({
        label: item.libelle_exam,
        value: item.libelle_exam
    }));
    const [message_examen, setMessage_examen] = useState('');

    const handleInputExamen = (value) => {
        
        if (value !== "") {
            setExamen(value)
            setMessage_examen("");
        } else {
            setExamen("")
        }
    }

    const liste_examens = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_examen');
            if (response.data.status == 200) {
                setExamens(response.data.examens);
               
            }
        } catch (Error) {

        }

    }


    const validationFields = () => {
        if (examen == "") {

            setMessage_examen("Veuillez saisir le type d'examen");

        }


    }
    const liste_examen_by_id = async () => {
        try {
            await axios.get(URL_AXIOS + `get_examens_by_id/${id}`)
                .then(response => {
                    setExamen(response.data.libelle_exam)
                });
        } catch (Error) {

        }

    }
    const updateExamens = async () => {
        try {
            const data_examens = {
                libelle_exam: examen.toUpperCase()

            }
            if (examen != "") {
                const response = await axios.post(URL_AXIOS + `update_examen/${id}`, data_examens);
                if (response.data.status == 200) {

                    setExamen("");
                    navigate('/examens')
                } else {


                }
            } else {
                validationFields()
            }


        } catch (error) {
            //show();
        }

    }

    useEffect(() => {
      
        liste_examen_by_id();
        liste_examens();
        if (usernameconnected === null || usernameconnected === "") {
            navigator('/')
        }
    }, []);
    return (
        <section id="container">
            <Header />
            <AsideBar  username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div class="row">
                        <div class="form-panel">
                            <form role="form" class="form-horizontal style-form">
                                <div className='form-group'>
                                    <div className='col col-lg-6'>
                                    <AutoComplete
                                        
                                        options={data}
                                        style={{width:'100%',textTransform:'uppercase' }}
                                        onChange={handleInputExamen}
                                        filterOption={true}
                                        value={examen}
                                        placeholder="Veuillez saisir le nom de l'examen"
                                    />
                                      
                                        {message_examen !== "" ? <label style={{ color: 'red' }}>{message_examen}</label> : ""}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-10">
                                        <button class="btn btn-primary" type="button"
                                            onClick={() => updateExamens()}
                                        >Mettre à jour</button>
                                        <button class="btn btn-warning" type="button" style={{ marginLeft: 10 }}
                                            onClick={() => navigate('/examens')}>Retour</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </section>
            <Footer/>
        </section>
    );
}

export default EditionExamens;