import React from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { useContext } from 'react';
import Footer from '../Footer';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
function DossierRecu() {
    const navigate=useNavigate();

    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    useEffect(() => {
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [])
    return (
        <section id="container">
            <Header />
            <AsideBar  username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                </section>
            </section>
            <Footer />
        </section>
    );
}

export default DossierRecu;