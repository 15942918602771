import React, { useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { Calendar } from 'antd';
import { useContext } from 'react';
import Footer from '../Footer';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import Swal from 'sweetalert2/dist/sweetalert2';
import Loading from './Loading';
function Agenda() {
    const navigate = useNavigate();
    const [agenda, setAgenda] = useState([]);
    const [sites, setSites] = useState([]);
    const [visible, setVisible] = useState(false);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedMembre, setSelectedMembre] = useState(null);
    const [siteMembre, setSiteMembre] = useState([]);
    const [siteMembreGet, setSiteMembreGet] = useState([]);
    const [datedebut, setDatedebut] = useState('');
    const [datefin, setDateFin] = useState('');
    const [boolStatus, setBoolStatus] = useState(false);
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(true);
    const [societe, setSociete] = useState("");


    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status === 200) {
                setSites(response.data.sites);
                //setLogin(response.data.sites.length)
                //setFilteredData(response.data.sites);
            }
        } catch (Error) {

        }

    }

    const liste_agenda = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_site_membre_agenda');
            if (response.data.status === 200) {
                setAgenda(response.data.list_site_membre_agenda);
                setLoading(false)
                //setLogin(response.data.sites.length)
                //setFilteredData(response.data.sites);
            }
        } catch (Error) {

        }

    }
    const lister_contrat = async () => {
        try {

            const response = await axios.get(URL_AXIOS + 'liste_membres_sites');
            if (response.data.status === 200) {
                setSiteMembre(response.data.data_membres_sites);

            }
        } catch (Error) {

        }
    };

    const AddAgendas = async () => {
        try {

            if (datedebut !== "" && datefin !== "" && selectedMembre !== "" && user_id !== 0) {
                if (new Date(datedebut) < new Date(datefin)) {
                    const data = {
                        datedebut: datedebut,
                        datefin: datefin,
                        id_contrat: selectedMembre.id,
                        user_id: user_id
                    }
                    const response = await axios.post(URL_AXIOS + 'addAgenda', data);
                    if (response.data.status === 200) {

                        if (response.data.boolState) {
                            Swal.fire({
                                title: "Info !",
                                text: "Médecin a été déjà programmé pour la date du " + datedebut + " Au " + datefin,
                                icon: "success"
                            })
                        } else {
                            Swal.fire({
                                title: "Réussi !",
                                text: "Médécin a été programmé avec succès!",
                                icon: "success"
                            })
                            liste_agenda();
                            setVisible(false);
                        }


                    } else {

                    }
                } else {
                    setVisible(false)
                    Swal.fire({
                        title: "Erreur !",
                        text: "La date de début doît strictement être inférieure à la date de fin!",
                        icon: "error"
                    }).then((resultat) => {
                        if (resultat.isConfirmed) {
                            setVisible(true);
                        }
                    });
                }

            } else {
                setVisible(false)
                Swal.fire({
                    title: "Erreur !",
                    text: "Veuillez renseigner tous les champs obligatoires!",
                    icon: "error"
                }).then((resultat) => {
                    if (resultat.isConfirmed) {
                        setVisible(true);
                    }
                });

            }

        } catch (Error) {

        }

    }

    const UpdateAgendas = async (id_agenda) => {
        try {
            if (datedebut !== "" && datefin !== "" && selectedMembre !== "" && user_id !== 0) {
                if (new Date(datedebut) < new Date(datefin)) {
                    const data = {
                        datedebut: datedebut,
                        datefin: datefin,
                        id_contrat: selectedMembre.id,
                        user_id: user_id
                    }
                    const response = await axios.post(URL_AXIOS + `UpdateAgenda/${id_agenda}`, data);
                    if (response.data.status === 200) {
                        Swal.fire({
                            title: "Réussi !",
                            text: "La programmation a été modifiée avec succès",
                            icon: "success"
                        })
                        liste_agenda();
                        setVisible(false);

                    } else {
                        Swal.fire({
                            title: "Erreur !",
                            text: "La modification de la programmation médecin a échouée!",
                            icon: "error"
                        })
                    }
                } else {
                    setVisible(false)
                    Swal.fire({
                        title: "Erreur !",
                        text: "La date de début doît strictement être inférieure à la date de fin!",
                        icon: "error"
                    }).then((resultat) => {
                        if (resultat.isConfirmed) {
                            setVisible(true);
                        }
                    });
                }
            } else {
                setVisible(false)
                Swal.fire({
                    title: "Erreur !",
                    text: "Veuillez renseigner tous les champs obligatoires!",
                    icon: "error"
                }).then((resultat) => {
                    if (resultat.isConfirmed) {
                        setVisible(true);
                    }
                });
            }
        } catch (Error) {

        }

    }

    const DeleteAgendas = async (id_agenda) => {
        try {

            const response = await axios.post(URL_AXIOS + `DeleteAgenda/${id_agenda}`);
            if (response.data.status === 200) {
                liste_agenda();
            }
        } catch (Error) {

        }

    }
    const onSelectMembre = async (selectedSite) => {

        try {

            const filteredData = siteMembre.filter(item => {
                return (item.societe === selectedSite.societe)
            });
            setSiteMembreGet(filteredData);
        } catch (Error) {

        }

    }
    const AddAgenda = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'getLastDate');
            if (response.data.status === 200) {
                setDatedebut(response.data.list_site_membre_agenda)

            }
            setBoolStatus(false);
            setVisible(true);
            setSelectedSite(null);
            setSelectedMembre(null);

            setDateFin("");
        } catch (error) {

        }

    }

    const loadSociete = () => {
        const filteredData = sites.filter(item => {
            return (item.societe === societe)
        });
        setSites(filteredData);
    }
    const EditAgenda = async (data) => {
        try {
            setBoolStatus(true);
            setVisible(true);
            setDatedebut(data.datedebut);
            setDateFin(data.datefin);
            setSociete(data.societe);
            setId(data.id);

            // Find the site matching the societe
            const filteredData = sites.filter(item => item.societe === data.societe);
            const obj = filteredData.length ? filteredData[0] : {};
            setSelectedSite(obj);

            // Find the member matching the id_contrat
            const filteredDataMembre = siteMembre.filter(item => (item.id === data.id_contrat && item.societe === data.societe));
            const objMembre = filteredDataMembre.length ? filteredDataMembre[0] : {};
            setSelectedMembre(objMembre);



        } catch (error) {
            console.error("An error occurred while editing the agenda:", error);
        }
    };



    const confirm_delete = (id) => {

        Swal.fire({
            title: "Question ?",
            text: "Voulez-vous supprimer cette programmation",
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "NON",
            icon: 'question'
        }).then((result) => {
            if (result.isConfirmed) {

                DeleteAgendas(id);
                liste_agenda();
                Swal.fire("Réussi!", "", "success");
            } else if (result.isCanceled) {
                Swal.fire("Echec!", "", "danger");
            }
        });

    };
    const confirm_update = (id) => {
        setVisible(false);
        Swal.fire({
            title: "Question ?",
            text: "Voulez-vous modifier cette programmation ?",
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "NON",
            icon: 'question'
        }).then((result) => {
            if (result.isConfirmed) {
                UpdateAgendas(id);
                liste_agenda();


            } else if (result.isCanceled) {
                Swal.fire("Echec!", "", "danger");
            }
        });

    };
    const confirm_add = () => {
        setVisible(false);
        Swal.fire({
            title: "Question ?",
            text: "Voulez-vous programmer ce médecin",
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "NON",
            icon: 'question'
        }).then((result) => {
            if (result.isConfirmed) {
                if (datedebut < datefin) {
                    AddAgendas();
                    liste_agenda();

                } else {
                    Swal.fire("Date de début doit strictement être inférieur à la date de fin!", "", "danger");
                    setTimeout(() => {
                        setVisible(true)
                    }, 1000);


                }

            } else if (result.isCanceled) {
                setVisible(true)
            }
        });

    };
    const bodyAction = (data) => {
        return (
            <div style={{ display: 'flex', }}>
                <button type='button' onClick={() => EditAgenda(data)} className='btn btn-warning'>
                    <i className='fa fa-edit'></i>
                </button>
                <button type='button' onClick={() => confirm_delete(data.id)} style={{ marginLeft: 10 }} className='btn btn-danger'>
                    <i className='fa fa-trash-o'></i>
                </button>
            </div>
        )
    }
    const bodyActionStatus = (data) => {
        return (
            <div style={{ display: 'flex', }}>
                {data.statutag == 0 ? "En cours " : data.statutag == 1 ? "En attente" : "Expiré"}
            </div>
        )
    }
    useEffect(() => {

        liste_site();
        liste_agenda();
        lister_contrat();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
        onSelectMembre(selectedSite);
    }, [selectedSite])
    return (
        <section id="container">
            <Dialog header={boolStatus == false ? "Ajout Agenda" : "Modification Agenda"} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div className='row'>
                    <div className='col col-md-6'>
                        <Dropdown
                            value={selectedSite}
                            onChange={(e) => setSelectedSite(e.value)}
                            options={sites}
                            optionLabel="societe"
                            optionValue="societe"
                            placeholder="Sélectionner le site"
                            className="w-full "

                            checkmark={true}
                            highlightOnSelect={true}
                            filter


                        />
                    </div>
                    <div className='col col-md-6'>

                        <Dropdown
                            value={selectedMembre}
                            onChange={(e) => setSelectedMembre(e.value)}
                            options={siteMembreGet}
                            optionLabel='nomcompletMedecin'
                            placeholder="Sélectionner le médecin"
                            className="w-full "
                            optionValue='id'
                            checkmark={true}
                            highlightOnSelect={true}
                            filter
                        // onSelect={onSelectMembre}
                        //  onSelectCapture={onSelectMembre}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col col-md-6'>
                        <input type='date' placeholder='Date début' value={datedebut} onChange={(e) => setDatedebut(e.target.value)} className='form-control' />
                    </div>
                    <div className='col col-md-6'>
                        <input type='date' placeholder='Date fin' value={datefin} onChange={(e) => setDateFin(e.target.value)} className='form-control' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col col-md-12'>
                        <button type='button' onClick={boolStatus == false ? () => confirm_add() : () => confirm_update(id)} className={boolStatus == false ? 'btn btn-primary form-control' : 'btn btn-warning form-control'}>{boolStatus == false ? 'Enregistrer' : 'Modifier'}</button>
                    </div>
                </div>
            </Dialog>
            <Header />
            <AsideBar username={usernameconnected} />
            {loading ? <Loading /> : <section id="main-content">
                <section class="wrapper">
                    <div className='container-fluid'>
                        <div className="row mt">
                            <div className='col col-md-12'>
                                <button type='button' className='btn btn-info'
                                    onClick={AddAgenda}
                                >Ajouter</button>
                            </div>
                        </div>
                        <div className='row'>
                            <DataTable value={agenda} emptyMessage="Aucun agenda disponible!" rowClassName={(data) => data.statutag == 0 ? "bgColorRowAgenda" : data.statutag == 1 ? "bgColorRowAbonnProgramm" : "bgColorRowAbonnExp"} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className="custom-datatable">

                                <Column header={<div style={{ marginLeft: 25 }}>#</div>} field='id_contrat' headerStyle={{ marginLeft: 100 }} bodyStyle={{ textAlign: 'center' }} style={{ width: '5%', height: '40px' }}></Column>
                                <Column field='societe' header="CENTRE" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column field={'nom'} header="NOM" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column field={'prenom'} header="PRENOM" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column field='datedebut' header="DATE DEBUT" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column field='datefin' header="DATE FIN" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column field='created_at' header="DATE" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column header="STATUS" body={bodyActionStatus} style={{ width: 'auto', height: '40px' }}></Column>
                                <Column header="ACTIONS" body={bodyAction} style={{ width: 'auto', height: '40px' }}></Column>
                            </DataTable>
                        </div>
                    </div>

                </section>
            </section>}
            <Footer />
        </section>
    );
}

export default Agenda;