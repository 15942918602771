import React, { useEffect, useMemo } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import "antd/dist/reset.css";  
import { AutoComplete } from 'antd';
import { useContext } from 'react';
import Footer from '../Footer';
function AddExamen() {
    const navigate = useNavigate();
    const [examen, setExamen] = useState('');
    const [examens, setExamens] = useState([]);
    const [message_examen, setMessage_examen] = useState('');
    const [boolSave, setBoolSave] = useState(false);
    const usernameconnected=sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
   


    const handleInputExamen = (value) => {
      
        if (value !== "") {
            setExamen(value)
            setMessage_examen("");
        } else {
            setExamen("")
        }
    }
    //const examLabels = examens.map(exam => exam.libelle_exam);
    //console.log(examLabels)

    const data = examens.map(item => ({
        label: item.libelle_exam,
        value: item.libelle_exam
    }));
    //console.log("Options : "+data.label);
    const validationFields = () => {
        if (examen == "") {

            setMessage_examen("Veuillez saisir le type d'examen");

        }


    }

        const liste_examens = async () => {
            try {
                const response = await axios.get(URL_AXIOS + 'liste_examen');
                if (response.data.status == 200) {
                    setExamens(response.data.examens);
                
                }
            } catch (Error) {

            }
        }

    const addExamens = async () => {
        try {
            const data_examens = {
                libelle_exam: examen.toUpperCase()

            }

            if (examen != "") {
                setBoolSave(true)
                const response = await axios.post(URL_AXIOS + "addExamens", data_examens);
                if (response.data.status === 200) {
                    setBoolSave(false)
                    setExamen("")
                } else {


                }
            } else {
                validationFields();
            }


        } catch (error) {
            //show();
        }

    }

    useEffect(() => {
        liste_examens();
        if(usernameconnected===null || usernameconnected===""){
            navigate('/')
        }
    }, []);
    return (
        <section id="container">
            <Header />
            <AsideBar  username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div class="row">
                        <div class="form-panel">
                            <form role="form" class="form-horizontal style-form">
                                <div className='form-group'>
                                    <div className='col col-lg-6'>
                                        <AutoComplete
                                        
                                            options={data}
                                            style={{width:'100%',textTransform:'uppercase' }}
                                            onChange={handleInputExamen}
                                            filterOption={true}
                                            placeholder="Veuillez saisir le nom de l'examen"
                                        />
                                     
                                        {message_examen !== "" ? <label style={{ color: 'red' }}>{message_examen}</label> : ""}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-10">
                                        <button class="btn btn-primary" disabled={boolSave} type="button"
                                            onClick={() => addExamens()}
                                        >Enregistrer</button>
                                        <button class="btn btn-warning" type="button" style={{ marginLeft: 10 }}
                                            onClick={() => navigate('/examens')}>Retour</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </section>
            <Footer/>
        </section>
    );
}

export default AddExamen;