import React from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useContext } from 'react';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Dialog } from 'primereact/dialog';
import { FaFileExcel } from "react-icons/fa6";
import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from './Loading';
function Examens() {
    const navigate = useNavigate();
    const { examens, setExamens, modalite, setModalite } = useContext(ThemeContext);
    //const [examens, setExamens] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [query, setQuery] = useState("");
    const recordsPerPage = 15;
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = examens.slice(firstIndex, lastIndex);
    const npage = Math.ceil(examens.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [id, setId] = useState(0);
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [filteredCountries, setFilteredCountries] = useState(null);
    const [examen, setExamen] = useState('');
    const [message_examen, setMessage_examen] = useState('');
    const [boolSave, setBoolSave] = useState(false);
    const [examenId, setExamenId] = useState('');
    const [statusEn, setStatusEn] = useState(false);
    const [modaliteCode, setModaliteCode] = useState('');
    const [loading, setLoading] = useState(true);
    const AddExModal = () => {
        setExamen("");
        setExamenId('');
        setStatusEn(false);
        setVisible(true);

    }
    const handleInputExamen = (e) => {
        const value = e.value;
        if (value !== "") {
            setExamen(value);

            setMessage_examen("");
        } else {
            setExamen("")
        }
    }
    //const examLabels = examens.map(exam => exam.libelle_exam);
    //console.log(examLabels)
    const search = (event) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _filteredCountries;

            if (!event.query.trim().length) {
                _filteredCountries = [...examens];
            }
            else {
                _filteredCountries = examens.filter((exam) => {
                    return exam.libelle_exam.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredCountries(_filteredCountries);
        }, 250);
    }
    const data = examens.map(item => ({
        label: item.libelle_exam,
        value: item.libelle_exam
    }));
    //console.log("Options : "+data.label);
    const validationFields = () => {
        if (examen == "") {

            setMessage_examen("Veuillez saisir le type d'examen");

        }


    }



    const addExamens = async () => {
        try {
            if (typeof examen === 'object' && examen !== null) {
                let examenFormat = "";
                if (examen.libelle_exam.includes(modaliteCode)) {
                    examenFormat = examen.libelle_exam;
                } else {
                    examenFormat = examen.libelle_exam;
                }
                const data_examens = {
                    libelle_exam: JSON.stringify(examenFormat).toUpperCase().replace(/"/g, '')

                }

                if (examen != "") {

                    const response = await axios.post(URL_AXIOS + "addExamens", data_examens);
                    if (response.data.status === 200) {
                        liste_examens();
                        setExamen("")
                        setVisible(false)
                    } else {

                    }
                } else {
                    validationFields();
                }
            } else {
                let examenFormat = "";
                if (examen.includes(modaliteCode)) {
                    examenFormat = examen;
                } else {
                    examenFormat = examen;
                }
                const data_examens = {
                    libelle_exam: examenFormat.toUpperCase()
                }

                if (examen != "") {

                    const response = await axios.post(URL_AXIOS + "addExamens", data_examens);
                    if (response.data.status === 200) {
                        liste_examens();
                        setExamen("")
                        setVisible(false)
                    } else {

                    }
                } else {
                    validationFields();
                }
            }
        } catch (error) {
            alert('erreur' + error)
        }

    }
    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }


    const liste_examens = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_examen');
            if (response.data.status === 200) {
                setExamens(response.data.examens);
                setLoading(false)
            }
        } catch (Error) {

        }

    }


    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(examens);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Liste_examens');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    const delete_examens = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_examen/${id}`);
            if (response.data.status == 200) {
                Toast.current.show({ severity: 'info', summary: 'Confirmed', detail: response.data.message, life: 3000 });
                liste_examens();

            }
        } catch (Error) {

        }

    }
    const confirm2 = (id) => {



        Swal.fire({
            title: "Question ?",
            text: "Voulez-vous supprimer cet examen ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: 'Non',
            confirmButtonText: "Oui"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Réussi !",
                    text: "L'examen a été supprimé avec succès !",
                    icon: "success"
                });
                setId(id);
                delete_examens(id);
                liste_examens();
            }
        });

    };

    const liste_examen_by_id = async (id) => {
        try {
            await axios.get(URL_AXIOS + `get_examens_by_id/${id}`)
                .then(response => {
                    const updateExa = response.data.examen.libelle_exam.replace(response.data.code, "");
                    setExamen(updateExa);
                    setModaliteCode(response.data.examen.code);
                    setExamenId(response.data.examen.id)
                    setVisible(true)
                    setStatusEn(true)
                });
        } catch (Error) {

        }

    }

    const updateExamens = async (id) => {
        try {
            const data_examens = {
                libelle_exam: examen.toUpperCase()

            }
            if (examen != "") {
                const response = await axios.post(URL_AXIOS + `update_examen/${id}`, data_examens);
                if (response.data.status == 200) {

                    setExamen("");
                    liste_examens();
                    setExamenId("");
                    setStatusEn(false);
                    setVisible(false)
                } else {


                }
            } else {
                validationFields()
            }


        } catch (error) {
            //show();
        }

    }


    const liste_modalite = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'list_modalite');
            if (response.data.status === 200) {
                setModalite(response.data.modalites);
            }
        } catch (Error) {

        }
    }

    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: 10 }}>
                <button type='button' onClick={() => liste_examen_by_id(data.id)} className='btn btn-warning'  >
                    <i className='fa fa-edit'></i>
                </button>

                <button type='button' style={{ marginLeft: 10 }} onClick={() => confirm2(data.id)} className='btn btn-danger'>
                    <i className='fa fa-trash-o'></i>
                </button>
            </div>
        )
    }
    useEffect(() => {
        liste_examens();
        liste_modalite();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);
    return (
        <section id="container">

            <Dialog header="Examens" visible={visible} modal={false} style={{ width: '50vw', zIndex: 1 }} onHide={() => setVisible(false)}>
                <form role="form" class="form-horizontal style-form">

                    <div className='row'>

                        <div className='col col-lg-12 col-md-12' >

                            <AutoComplete
                                field="libelle_exam"
                                value={examen} suggestions={filteredCountries}
                                completeMethod={search}
                                //onChange={(e) => setExamen(e.value)}
                                onChange={(e) => handleInputExamen(e)}
                                inputStyle={{ width: "100%" }}
                                className="w-full"
                                placeholder='Libellé examen *'
                            />

                            {message_examen !== "" ? <label style={{ color: 'red' }}>{message_examen}</label> : ""}
                        </div>
                    </div>

                    <div className='row'>

                        <div class="col-lg-12 col-md-12" style={{ marginTop: 25 }}>
                            <button type='button' className={statusEn === false ? "btn btn-primary form-control w-full" : "btn btn-warning form-control w-full"}
                                onClick={statusEn === false ? () => addExamens() : () => updateExamens(examenId)}
                            >{statusEn === false ? 'Enregistrer' : 'Mettre à jour'}</button>

                        </div>

                    </div>
                </form>
            </Dialog>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Header />
            <AsideBar username={usernameconnected} />
           {loading ? <Loading /> :  <section id="main-content">
                <section class="wrapper">
                    <div className='row ' style={{ padding: 10, marginTop: 20 }}>
                        <div className="col col-md-8">
                            <Button className='btn btn-info' label='Nouvel examen'
                                onClick={() => AddExModal()}
                            />
                            <button type="button" className='btn btn-success' title='Exporter les données'
                                onClick={exportExcel}
                                style={{ marginLeft: 10, color: 'white' }}
                            >
                                <FaFileExcel />
                                <span style={{ marginLeft: 10 }}>Exporter les données</span>
                            </button>
                        </div>
                        {/* <div className='col col-md-4'>
                            <input type="text" className='form-control' placeholder='Recherche ...'
                                value={query} onChange={handleQueryText}
                                style={{ borderRadius: 100 }} />
    </div>*/}
                    </div>
                    <div class="row " style={{ padding: 10 }}>
                        <DataTable value={examens} emptyMessage="Aucun examen disponible" paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className="custom-datatable">
                            <Column header={() => { return <div style={{ marginLeft: 20 }}>#</div> }} bodyStyle={{ textAlign: 'center' }} headerStyle={{ marginLeft: "100px" }} field='id' style={{ width: '5%', height: '40px' }}></Column>
                            <Column field={'libelle_exam'} header="EXAMENS" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column header="ACTIONS" body={ActionBody} style={{ width: '8%', height: '40px' }}></Column>
                        </DataTable>
                        {/* <table class="table table-bordered table-striped table-condensed">
                            <thead>
                                <tr style={{ backgroundColor: 'white' }}>
                                    <th style={{ color: 'red' }}><center>#</center></th>
                                    <th style={{ color: 'red' }}>EXAMENS</th>
                                    <th style={{ color: 'red' }}>DATE</th>
                                    <th colSpan={2} style={{ color: 'red' }}><center>ACTIONS</center></th>
                                </tr>
                            </thead>
                            <tbody>
                                {records
                                    .map((si, index) => (
                                        <tr key={si.id}>
                                            <td style={{ width: 30 }}><center>{index + 1}</center></td>
                                            <td >{si.libelle_exam}</td>
                                            <td style={{ width: 150 }}>{si.created_at}</td>
                                            <td style={{ width: 20 }}><center><button type='button' onClick={() => liste_examen_by_id(si.id)} className='btn btn-warning'  >
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            </center></td>
                                            <td style={{ width: 20 }}><center><button type='button' onClick={() => confirm2(si.id)} className='btn btn-danger'>
                                                <i className='fa fa-trash-o'></i>
                                            </button>
                                            </center></td>
                                        </tr>
                                    ))}
                            </tbody>

                        </table> */}


                        {/* <nav>
                            <ul className="pagination">
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={prevPage}
                                    >Prev</a>
                                </li>
                                {
                                    numbers.map((n, i) => (
                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                            <a href='#' className='page-link'
                                                onClick={() => changeCPage(n)}
                                            >{n}</a>
                                        </li>
                                    ))
                                }
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={nextPage}
                                    >Next</a>
                                </li>
                            </ul>
                        </nav> */}
                    </div>
                </section>
            </section>}
            <Footer />
        </section>
    );
}

export default Examens;