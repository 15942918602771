import React from 'react';
import { useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import { useState } from 'react';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PDFViewer() {
    const { id } = useParams();
    const [membre_id, setMembre_id] = useState(0);
    const [path, setPath] = useState("");
    const [path_two, setPathTwo] = useState("");
    const liste_site_contrat = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `get_sites_membres_by_id/${id}`);

            let chemin = "http://127.0.0.1:8000/" +response.data.chemin_fichier_one_name;
            let chemin_two = "http://127.0.0.1:8000/" + response.data.chemin_fichier_two_name;
            setPath(chemin);
            setPathTwo(chemin_two);


        } catch (Error) {

        }

    }
    const handleError = (error) => {
        // Handle the error, e.g., display an error message to the user
        console.error('PDF loading error:', error);
    };
    useEffect(() => {
        liste_site_contrat();
    }, []);
    return (
        <div>
            <Document file={'http://mel.lemaitre.free.fr/Livres/anglais-pln.pdf'} onError={handleError}>
                <Page pageNumber={1} />
                {/* You can use multiple <Page /> components to display different pages */}
            </Document>
        </div>
    );
}

export default PDFViewer;
