import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { URL_AXIOS } from '../../URL/URL';
import axios, { Axios } from 'axios';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { Country, State, City } from 'country-state-city';
import { Toast } from 'primereact/toast';
import { InputSwitch } from "primereact/inputswitch";
import { RadioButton } from "primereact/radiobutton";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useContext } from 'react';
import Footer from '../Footer';
import Swal from 'sweetalert2/dist/sweetalert2.js';
function AddSites() {

    const navigate = useNavigate();
    const { id } = useParams();
    const [sites, setSites] = useState([]);
    const [country, setCountry] = React.useState('');
    const [region, setRegion] = React.useState([]);
    const [city, setCity] = useState([]);
    const [countries, setCountries] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredDatatwo, setFilteredDatatwo] = useState([]);
    const [code, setCode] = useState("");
    const [societe, setSociete] = useState("");
    const [type, setType] = useState("");
    const [email, setEmail] = useState("");
    const [nomresponsable, setNomresponsable] = useState("");
    const [fonction, setFonction] = useState("");
    const [adresselocal, setAdresselocal] = useState("");
    const [pays, setPays] = useState('');
    const [departement, setDepartement] = useState('');
    const [ville, setVille] = useState('');
    const [telephone, setTelephone] = useState("");
    const [description, setDescription] = useState("");
    const [logo, setLogo] = useState("");
    const [entete, setEntete] = useState("");
    const [statut, setStatut] = useState(1);
    const toast = useRef(null);
    const [checked, setChecked] = useState(false);
    const [ingredient, setIngredient] = useState('');
    const [value, setValue] = useState();
    const [phone, setPhone] = useState("+242");
    const [message_mail, setMessage_mail] = useState("");
    const [message_societe, setMessage_societe] = useState("");
    const [message_type, setMessage_type] = useState("");
    const [message_telephone, setMessage_telephone] = useState("");
    const [message_adresse, setMessage_adresse] = useState("");
    const [message_responsable, setMessage_responsable] = useState("");
    const [message_fonction, setMessage_fonction] = useState("");
    const [message_pays, setMessage_pays] = useState("");
    const [message_departement, setMessage_departement] = useState("");
    const [message_ville, setMessage_ville] = useState("");
    const [boolean, setBoolean] = useState(true);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const handleInputSociete = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSociete(value)
            setMessage_societe("");
        } else {
            setSociete("")
        }
    }
    const cleanFiels = () => {
        setSociete("");
        setAdresselocal("");
        setTelephone("");
        setPays("");
        setVille("");
        setType("");
        setEmail("");
        setNomresponsable("");
        setFonction("");
        setCode("");

    }
    const handleInputType = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setType(value)
            setMessage_type("");
        } else {
            setType("")
        }
    }
    const handleInputEmail = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setEmail(value)
            setMessage_mail("");
        } else {
            setEmail("")
        }
    }
    const handleInputDepartement = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setDepartement(value)
            setMessage_departement("");
        } else {
            setDepartement("")
        }
    }
    const handleInputAdresse = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setAdresselocal(value)
            setMessage_adresse("");
        } else {
            setAdresselocal("")
        }
    }
    const handleInputPays = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setPays(value)
            setMessage_pays("");
        } else {
            setPays("")
        }
    }
    const handleInputVille = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setVille(value)
            setMessage_ville("");
        } else {
            setVille("")
        }
    }
    const handleInputResponsable = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setNomresponsable(value)
            setMessage_responsable("");
        } else {
            setNomresponsable("")
        }
    }
    const handleInputFonction = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setFonction(value)
            setMessage_fonction("");
        } else {
            setFonction("")
        }
    }
    const handleInputTelephone = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setTelephone(value)
            setMessage_telephone("");
        } else {
            setTelephone("")
        }
    }
    const validationFields = () => {
        if (email == "") {
            setMessage_mail("Veuillez saisir le mail");
        }
        if (societe == "") {
            setMessage_societe("Veuillez saisir la société");

        }
        if (type == "") {
            setMessage_type("Veuillez saisir le type de société");

        }
        if (telephone == "") {
            setMessage_telephone("Veuillez saisir le N° téléphone");

        }
        if (adresselocal == "") {
            setMessage_adresse("Veuillez saisir l'adresse");

        }
        if (nomresponsable == "") {
            setMessage_responsable("Veuillez saisir le nom responsable");

        }
        if (fonction == "") {
            setMessage_fonction("Veuillez saisir la fonction");

        }
        if (pays == "") {
            setMessage_pays("Veuillez sélectionner le pays");

        }
        if (departement == "") {
            setMessage_departement("Veuillez saisir le département");

        }
        if (ville == "") {
            setMessage_ville("Veuillez saisir la ville");

        }
    }
    const handleNomChange = (e) => {
        setNomresponsable(e.target.value)
    }




    const EmailValidation = (email) => {
        const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
        if (regex.test(email)) {
            //setMessage_mail("Mail is valid");

            return true;
        } else if (!regex.test(email) && email !== "") {
            setMessage_mail("le mail n'est pas valide");
            return false;
        } else {
            setMessage_mail("");
            return false;
        }
    };

    const handleInputSocieteChange = (event) => {
        const value = event.target.value;
        // Use a regular expression to allow only uppercase letters
        const regex = /^[A-Z]*$/;
        if (regex.test(value)) {
            setSociete(value);
        }
    };

    const handleInputChangeNumberTel = (event) => {
        const value = event.target.value;
        // Use a regular expression to allow only numbers (including negative numbers)
        const regex = /^-?\d*$/;

        if (regex.test(value)) {
            // If the input value contains only numbers or negative numbers,
            // update the telephone state with the value.
            setTelephone(value);
            setMessage_telephone(""); // Clear any previous error messages.
        } else {
            // If the input value is invalid (contains non-numeric characters),
            // do not update the telephone state and display an error message.
            setTelephone("");
            setMessage_telephone("Veuillez saisir le numéro");
        }
    };

    const handleInputChangeMail = (event) => {
        const value = event.target.value;
        if (value !== "") {
            EmailValidation(value)
        }


    };
    const show = () => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: 'message' });
    };
    const handleCityChange = (val) => {
        setCity(val);
    };
    const handleCountryChange = (e) => {
        const name_get = e.target.value;


        if (name_get !== "") {
            setPays(name_get);
            setMessage_pays("");
        } else {
            setPays("")
        }
        const filtered = countries.filter((item) => item.name === name_get);
        setFilteredData(filtered);

        filtered.forEach((filter) => {
            const states = State.getStatesOfCountry(filter.isoCode);
            const phonecode = filter.phonecode;
            setRegion(states);
            // setCode(phonecode)
        });
    };


    const handleRegionChange = (e) => {
        const name_get = e.target.value;
        if (name_get !== "") {
            setDepartement(name_get);
            setMessage_departement("");
        } else {
            setDepartement("")
        }
        const filtered = region.filter((item) => item.name === name_get);
        setFilteredDatatwo(filtered);
        filtered.forEach((filter) => {
            const states = City.getCitiesOfState(filter.countryCode, filter.isoCode);
            setCity(states);
            console.log("Region: ", states);
        });
    };
    const handleVilleChange = (e) => {
        const name_get = e.target.value;

        if (name_get !== "") {
            setVille(name_get);
            setMessage_ville("");
        } else {
            setVille("")
        }
    }


    const addConfirm=()=>{
        Swal.fire({
            title: "Question",
            text: "Voulez-vous enregistrer ce site",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui",
            cancelButtonText:"Non"
          }).then((result) => {
            if (result.isConfirmed) {
           
              add_site_state();
            }
          });
    }
    const add_site_state = async () => {
        try {
            const site_info = {

                societe: societe.replace(' ', '_').toUpperCase(),
                type: type.toUpperCase(),
                email: email,
                nomresponsable: nomresponsable.toUpperCase(),
                fonction: fonction.toUpperCase(),
                adresselocal: adresselocal.toUpperCase(),
                pays: pays.toUpperCase(),
                ville: ville.toUpperCase(),
                telephone: telephone,
                description: description,
                statut: ingredient == "oui" ? 1 : 0,
                code: (phone)

            }
            validationFields();
            if (EmailValidation(email)) {
                if (societe != "" || type != "" || email != "" || nomresponsable != "" || fonction != "" || adresselocal != "" || pays != "" || ville != "" || telephone != "" || phone != "") {
                    if (ingredient != "") {
                        const response = await axios.post(URL_AXIOS + "add_sites", site_info);

                        if (response.data.status == 200) {
                            Swal.fire({
                                title: "Réussi !",
                                text: "Le site a été enregistré avec succès !",
                                icon: "success"
                              });
                            cleanFiels();
                        } else {
                            // toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Error 12!' });
                            alert(JSON.stringify(site_info))
                        }
                    } else {

                        toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Veuillez cocher le type de validation', life: 3000 });
                    }

                } else {

                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'veuillez renseigner les champs obligatoires svp !', life: 3000 });
                }
            }
        } catch (error) {

        }



    }


    useEffect(() => {

        const fetchCountries = async () => {
            try {
                //const response = await axios.get('https://api.countrystatecity.in/v1/countries');
                setCountries(Country.getAllCountries());
            } catch (error) {
                console.log(error);
            }
        };

        fetchCountries();

        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);




    return (
        <section id="container">
            <Toast ref={toast} />
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="form-panel">


                                <form role="form" class="form-horizontal style-form">
                                    <div class="form-group ">

                                        <div class="col-lg-6">
                                            <input type="text" value={societe} onChange={handleInputSociete} placeholder="Centre *" class="form-control" />
                                            {message_societe !== "" ? <label style={{ color: 'red' }}>{message_societe}</label> : ""}
                                        </div>
                                        <div class="col-lg-6">
                                            <select className='form-control' placeholder='Type *' value={type} onChange={handleInputType}>
                                                <option>Type *</option>
                                                <option value="Centre imagerie">Centre imagerie</option>
                                                <option value="Centre médical">Centre médical</option>
                                                <option value="Clinique médicale">Clinique médicale</option>
                                                <option value="Hôpital">Hôpital</option>
                                                <option value="Laboratoire d'analyse médicale">Laboratoire d'analyse médicale</option>
                                                <option value="Plateforme d'interpretation">Plateforme d'interpretation</option>
                                            </select>
                                            {message_type !== "" ? <label style={{ color: 'red' }}>{message_type}</label> : ""}
                                        </div>
                                    </div>
                                    <div class="form-group ">

                                        <div class="col-lg-6">
                                            <input type="email" required value={email} placeholder="Email *" onChange={handleInputEmail} class="form-control" />
                                            {message_mail !== "" ? <label style={{ color: 'red' }}>{message_mail}</label> : ""}
                                        </div>

                                        <div class="col-lg-6">
                                            <input type="text"  value={adresselocal} onChange={handleInputAdresse} placeholder="Adresse *" class="form-control" />
                                            {message_adresse !== "" ? <label style={{ color: 'red' }}>{message_adresse}</label> : ""}
                                        </div>
                                    </div>
                                    <div class="form-group">

                                        <div class="col-lg-4">

                                            <select className='form-control' value={id !== "" ? pays : pays} onChange={handleCountryChange} >
                                                <option value=""> Sélectionner un pays *</option>
                                                {countries.map((country) => (
                                                    <option key={country.isoCode} value={country.name}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {message_pays !== "" ? <label style={{ color: 'red' }}>{message_pays}</label> : ""}

                                        </div>
                                        <div class="col-lg-4">
                                            <select className='form-control' value={departement} onChange={handleRegionChange} >
                                                <option value="">Sélectionner un département *</option>
                                                {region.map((region) => (
                                                    <option key={region.isoCode} value={region.name}>
                                                        {region.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {message_departement !== "" ? <label style={{ color: 'red' }}>{message_departement}</label> : ""}


                                        </div>
                                        <div class="col-lg-4">

                                            <select className='form-control' value={ville} onChange={handleVilleChange} >
                                                <option value="">Sélectionner une ville *</option>

                                                {city.map((city) => (
                                                    <option key={city.isoCode} value={city.name}>
                                                        {city.name}
                                                    </option>
                                                ))}
                                            </select>

                                            {message_ville !== "" ? <label style={{ color: 'red' }}>{message_ville}</label> : ""}

                                        </div>
                                    </div>
                                    <div class="form-group">

                                        <div class="col-lg-3">
                                            <input type="text" value={nomresponsable} onChange={handleInputResponsable} placeholder="Responsable *" id="l-name" class="form-control"  />

                                            {message_responsable !== "" ? <label style={{ color: 'red' }}>{message_responsable}</label> : ""}
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" value={fonction} onChange={handleInputFonction} placeholder="Fonction *" id="l-name" class="form-control"  />

                                            {message_fonction !== "" ? <label style={{ color: 'red' }}>{message_fonction}</label> : ""}
                                        </div>
                                        <div class="col-lg-2" >

                                            <PhoneInput
                                                country={'cg'}
                                                inputStyle={{ width: '100%' }}
                                                enableSearch={true}
                                                value={phone}
                                                onChange={(phone) => setPhone(phone)}
                                                placeholder="Code * "
                                                countryCodeEditable={false}
                                                disabled={false}
                                                disableCountryCode={true}
                                                inputProps={{
                                                    required: true,

                                                }}
                                            />

                                        </div>
                                        <div class="col-lg-4">
                                            <input type="text" value={telephone} onChange={handleInputChangeNumberTel} placeholder="Téléphone *" id="l-name" class="form-control" />

                                            {message_telephone !== "" ? <label style={{ color: 'red' }}>{message_telephone}</label> : ""}
                                        </div>

                                    </div>
                                    <div className="form-group ">

                                        <div className="flex align-items-center" style={{ marginLeft: '40%' }}>
                                            <label className="ml-2" style={{ marginLeft: 5 }}> Status de validation :</label>
                                            <RadioButton inputId="ingredient1" name="pizza" style={{ marginLeft: 10 }} value="oui" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'oui'} />
                                            <label htmlFor="ingredient1" className="ml-2" style={{ marginLeft: 10 }}>Oui</label>
                                            <RadioButton inputId="ingredient2" name="pizza" value="non"
                                                onChange={(e) => setIngredient(e.value)} checked={ingredient === 'non'}
                                                style={{ marginLeft: 10 }} />
                                            <label htmlFor="ingredient2" style={{ marginLeft: 10 }} className="ml-2">Non</label>
                                        </div>



                                    </div>
                                    <div class="form-group">
                                        <div class="col-lg-10">
                                            <button class="btn btn-primary" type="button"
                                                onClick={() => addConfirm()}>Enregistrer</button>
                                            <button class="btn btn-warning" type="button" style={{ marginLeft: 10 }}
                                                onClick={() => navigate('/sites')}>Retour</button>
                                        </div>
                                    </div>
                                </form>



                            </div>

                        </div>

                    </div>


                </section>
            </section>
            <Footer />
        </section>
    );
}

export default AddSites;