import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useContext } from 'react';
import { ThemeContext } from '../../context/ContextHeader';
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import { useRef } from 'react';
import { Button } from 'primereact/button';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from './Loading';

function Abonnement() {
    const navigate = useNavigate();
    const { sites, setSites, abonnementSite, setabonnementSite } = useContext(ThemeContext);
    const [visible, setVisible] = useState(false);
    const usernameconnected = sessionStorage.getItem("nom");
    const [site_id, setSite_id] = useState(0);
    const [datedebut, setDatedebut] = useState('');
    const [datefin, setDateFin] = useState('');
    const [etat, setEtat] = useState('');
    const user_id = sessionStorage.getItem("user_id");
    const member_id = sessionStorage.getItem("membre_id");
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 15;
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = abonnementSite.slice(firstIndex, lastIndex);
    const npage = Math.ceil(abonnementSite.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const dt = useRef(null);
    const [abId, setAbId] = useState(0);
    const [statusBool, setStatusBool] = useState(false);
    const [securite, setSecurite] = useState(7);
    const [loading, setLoading] = useState(true);

    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }
    const liste_abonnement_sites = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_abonnements_sites');
            if (response.data.status === 200) {
                setabonnementSite(response.data.vue_sites_abonnements);
                setLoading(false)
            }
        } catch (Error) {

        }

    }
    const confirm2 = (id) => {
        Swal.fire({
            title: "Suppression",
            text: "Voulez-vous supprimer cet abonnement ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: 'Non',
            confirmButtonText: "Oui"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Réussi !",
                    text: "L'abonnement a été supprimé avec succès !",
                    icon: "success"
                });

                delete_abonnement(id);
                liste_abonnement_sites();
            }
        });

    };

    const load_data = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_abonnements_sitesById/${id}`);
            if (response.data.status === 200) {
                setAbId(response.data.vue_sites_abonnements.abonnement_id);
                setSite_id(response.data.vue_sites_abonnements.site_id);
                setEtat(response.data.vue_sites_abonnements.etat);
                setDatedebut(response.data.vue_sites_abonnements.datedebut);
                setDateFin(response.data.vue_sites_abonnements.datefin);
                setSecurite(response.data.vue_sites_abonnements.securite);
                setVisible(true);
                setStatusBool(true);


            }
        } catch (Error) {

        }

    }
    const delete_abonnement = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_abonnement/${id}`);
            if (response.data.status == 200) {

                liste_abonnement_sites();

            }
        } catch (Error) {

        }

    }

    const addAbonnement = () => {
        setAbId("");
        setDateFin("");
        setDatedebut("");
        setSecurite(7);
        setSite_id('');
        setVisible(true);
        setStatusBool(false)
    }
    const AddAbonnement = async () => {
        try {
            if (site_id !== "" && datedebut !== "" && datefin !== "") {
                if (new Date(datedebut) < new Date(datefin)) {
                    const data = {
                        site_id: parseInt(site_id),
                        user_id: parseInt(user_id),
                        datedebut: datedebut,
                        datefin: datefin,
                        securite: securite
                    }
                    const response = await axios.post(URL_AXIOS + 'addAbonnement', data);
                    if (response.data.status === 200) {
                        setVisible(false);
                        setSite_id("");
                        setDateFin("");
                        setDatedebut("");
                        setEtat("");
                        liste_abonnement_sites();
                        Swal.fire({
                            title: "Réussi !",
                            text: "L'abonnement a été enregistré avec succès !",
                            icon: "success"
                        });


                    } else {
                        Swal.fire({
                            title: "Erreur !",
                            text: "L'enregistrement de l'abonnement a échoué !",
                            icon: "error"
                        });
                    }
                } else {
                    setVisible(false);
                    Swal.fire({
                        title: "Erreur !",
                        text: "La date de début doît strictement être inférieure à la date de fin!",
                        icon: "error"
                    }).then((resultat) => {
                        if (resultat.isConfirmed) {
                            setVisible(true);
                        }
                    });
                }
            } else {
                setVisible(false);
                Swal.fire({
                    title: "Erreur !",
                    text: "Veuillez renseigner tous les champs obligatoires!",
                    icon: "error"
                }).then((resultat) => {
                    if (resultat.isConfirmed) {
                        setVisible(true);
                    }
                });
            }

        } catch (error) {

        }

    }
    const EditAbonnement = async () => {
        try {
            if (site_id !== "" && datedebut !== "" && datefin !== "") {
                if (new Date(datedebut) < new Date(datefin)) {
                    const data = {
                        site_id: site_id,
                        user_id: user_id,
                        datedebut: datedebut,
                        datefin: datefin,
                        securite: securite
                    }
                    const response = await axios.post(URL_AXIOS + `updateAbonnement/${abId}`, data);
                    if (response.data.status === 200) {
                        setVisible(false);
                        setSite_id("");
                        setDateFin("");
                        setDatedebut("");
                        setEtat("");
                        liste_abonnement_sites()

                    } else {
                        Swal.fire({
                            title: "Erreur !",
                            text: "La modification de l'abonnement a échouée !",
                            icon: "error"
                        })
                    }
                } else {
                    setVisible(false);
                    Swal.fire({
                        title: "Erreur !",
                        text: "La date de début doît strictement être inférieure à la date de fin!",
                        icon: "error"
                    }).then((resultat) => {
                        if (resultat.isConfirmed) {
                            setVisible(true);
                        }
                    });
                }

            } else {
                setVisible(false);
                Swal.fire({
                    title: "Erreur !",
                    text: "Veuillez renseigner tous les champs obligatoires!",
                    icon: "error"
                }).then((resultat) => {
                    if (resultat.isConfirmed) {
                        setVisible(true);
                    }
                });
            }

        } catch (error) {

        }

    }

    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                <button type='button' onClick={() => load_data(data.abonnement_id)} className='btn btn-warning' disabled={data.etat !== "0" ? false : true}>
                    <i className='fa fa-edit'></i>
                </button>
            </div>
        )
    }
    useEffect(() => {
        liste_abonnement_sites();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [])
    return (
        <section id="container">
            <Dialog header="Abonnements" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col col-md-2'>
                            <label style={{ fontSize: '15px', fontWeight: 600, marginTop: 10 }}>Site *:</label>
                        </div>
                        <div className='col col-md-10' style={{ display: 'flex', justifyItems: 'space-between' }}>

                            <select className='form-control' value={site_id} onChange={(e) => setSite_id(e.target.value)} disabled={statusBool === false ? false : true}>
                                <option>Veuillez sélectionner le site</option>
                                {sites.map((s) => (
                                    <option value={s.id}>{s.societe}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col col-md-2'>
                            <label style={{ fontSize: '15px', fontWeight: 600, marginTop: 10 }}>Sécurtite *:</label>
                        </div>
                        <div className='col col-md-10' style={{ display: 'flex', justifyItems: 'space-between' }}>

                            <input type='number' value={securite} onChange={(e) => setSecurite(e.target.value)} className='form-control'
                                style={{ width: '20%' }}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col col-md-2'>
                            <label style={{ fontSize: '13px', fontWeight: 600, marginTop: 10 }}>Date début *:</label>
                        </div>
                        <div className='col col-md-4'>
                            <input type='date' className='form-control'
                                value={datedebut} onChange={(e) => setDatedebut(e.target.value)} disabled={statusBool === false ? false : true}
                            />
                        </div>
                        <div className='col col-md-2'>
                            <label style={{ fontSize: '13px', fontWeight: 600, marginTop: 10 }}>Date fin *:</label>
                        </div>
                        <div className='col col-md-4'>
                            <input type='date' className='form-control'
                                value={datefin} onChange={(e) => setDateFin(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: 20 }}>
                        <button type='button'
                            onClick={statusBool === false ? () => AddAbonnement() : () => EditAbonnement()}
                            className={statusBool === false ? 'btn btn-primary form-control' : 'btn btn-warning form-control'}

                        >{statusBool === false ? 'Enregistrer' : 'Mettre à jour'}</button>
                    </div>
                </div>
            </Dialog>
            <Header />
            <AsideBar username={usernameconnected} />
            {loading ? <Loading /> : <section id="main-content">
                <section class="wrapper">
                    <div className="row mt">
                        <div className='col col-md-12'>
                            <button type='button' onClick={() => addAbonnement()} className='btn btn-primary'>Nouveau</button>
                        </div>
                    </div>
                    <div class="row " style={{ padding: 10 }}>
                        <DataTable value={abonnementSite} emptyMessage="Aucun abonnement disponible!" rowClassName={(data) => data.etat == "0" ? "bgColorRowAbonnExp" : data.etat == "2" ? "bgColorRowAbonnProgramm" : "bgColorRowAbonnEnCours"} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className="custom-datatable">
                            <Column field='abonnement_id' header={() => { return <div style={{ marginLeft: 20 }}>#</div> }} bodyStyle={{ textAlign: 'center' }} headerStyle={{ marginLeft: "100px" }} style={{ width: '5%', height: '40px' }}></Column>
                            <Column field={'societe'} header="CENTRE" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field={'type'} header="TYPE" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field={'datedebut'} header="DATE DEBUT" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field={'datefin'} header="DATE FIN" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field={'duree'} header="DUREE" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field={'jourRestant'} header="JOURS RESTANT" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column header="ETAT" body={(data) => { return <div style={{ marginLeft: 1 }}>{data.etat === "0" ? "Expiré" : data.etat === "1" ? 'En cours' : 'Programmé'}</div> }} style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field={'created_at'} header="DATE" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column header="ACTIONS" body={ActionBody} style={{ width: '8%', height: '40px' }}></Column>
                        </DataTable>
                        {/* <table ref={dt} class="table table-bordered table-striped table-condensed">
                            <thead>
                                <tr style={{ backgroundColor: 'white' }}>
                                    <th style={{ color: 'red' }}><center>#</center></th>
                                    <th style={{ color: 'red' }}>CENTRE</th>
                                    <th style={{ color: 'red' }}>TYPE</th>
                                    <th style={{ color: 'red' }}>DATE DEBUT</th>
                                    <th style={{ color: 'red' }}>DATE FIN</th>
                                    <th style={{ color: 'red' }}>DUREE</th>
                                    <th style={{ color: 'red' }}>JOURS RESTANT</th>
                                    <th style={{ color: 'red' }}>ETAT</th>

                                    <th style={{ color: 'red' }}>DATE</th>
                                    <th style={{ color: 'red' }}><center>ACTIONS</center></th>

                                </tr>
                            </thead>
                            <tbody>
                                {records
                                    .map((si, index) => (
                                        <tr key={si.id}>

                                            <td><center>{index + 1}</center></td>
                                            <td>{si.societe}</td>
                                            <td>{si.type}</td>
                                            <td>{si.datedebut}</td>
                                            <td>{si.datefin}</td>
                                            <td>{si.duree}</td>
                                            <td>{si.jourRestant}</td>
                                            <td>{si.etat}</td>

                                            <td>{si.created_at}</td>

                                            <td><center><button type='button' onClick={() => load_data(si.abonnement_id)} className='btn btn-warning' disabled={si.etat !== "expiré" ? false : true}>
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            </center></td>
                                            {/*   <td><center><Button onClick={() => load_data(si.abonnement_id)} icon={"pi pi-user-edit"} style={{ backgroundColor: "orange" }}  />
                                            </center></td>
                                        <td><center><Button icon="pi pi-trash" onClick={() => confirm2(si.abonnement_id)} style={{ backgroundColor: "red" }} />
                                            </center></td>
                                        </tr>
                                    ))}
                            </tbody>

                        </table> */}

                        {/* <nav>
                            <ul className="pagination">
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={prevPage}
                                    >Prev</a>
                                </li>
                                {
                                    numbers.map((n, i) => (
                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                            <a href='#' className='page-link'
                                                onClick={() => changeCPage(n)}
                                            >{n}</a>
                                        </li>
                                    ))
                                }
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={nextPage}
                                    >Next</a>
                                </li>
                            </ul>
                        </nav> */}
                    </div>
                </section>
            </section>}
            <Footer />
        </section>
    );
}

export default Abonnement;