import React from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import { useEffect } from 'react';
import "antd/dist/reset.css";
import { AutoComplete } from 'antd';
import { useContext } from 'react';
import Footer from '../Footer';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import Swal from 'sweetalert2/dist/sweetalert2.js';
function AddGains() {

    const navigate = useNavigate();
    const [examen, setExamen] = useState('');
    const [examenOne, setExamenOne] = useState("");
    const [examenTwo, setExamenTwo] = useState("");
    const [examenThree, setExamenThree] = useState("");
    const [examenFour, setExamenFour] = useState("");
    const [devise, setDevise] = useState('');
    const [gain, setGain] = useState("");
    const [pourcentage, setPourcentage] = useState('');
    const [categorie_fichier, setCategorie_fichier] = useState('');
    const [mode_paiement, setMode_paiement] = useState('');
    const [site_id, setSite_id] = useState('');
    const [examensTwo, setExamensTwo] = useState([]);
    const [examensThree, setExamensThree] = useState([]);
    const [examensFour, setExamensFour] = useState([]);
    const [sites, setSites] = useState([]);
    const [examens, setExamens] = useState([]);
    const [message_devise, setMessage_devise] = useState("");
    const [message_gain, setMessage_gain] = useState("");
    const [message_pourcentage, setMessage_pourcentage] = useState("");
    const [message_categorie_fichier, setMessage_categorie_fichier] = useState("");
    const [message_mode_paiement, setMessage_mode_paiement] = useState("");
    const [message_site_id, setMessage_site_id] = useState("");

    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const [modalite, setModalite] = useState([]);
    const [selectedModalite, setSelectedModalite] = useState(null);
    const [boolStatus, setBoolStatus] = useState(false);
    const [gainId, setGainId] = useState(0);
    const [nbrExamen, setNbrExamen] = useState('');
    const [typeExamen, setTypeExamen] = useState('');
    const [categorieExamen, setCategorieExamen] = useState('');
    const [pu, setPu] = useState(0);

    const [boolExamenOne,setBoolExamenOne]=useState(false);
    const [boolExamenTwo,setBoolExamenTwo]=useState(true);
    const [boolExamenThree,setBoolExamenThree]=useState(true);
    const [boolExamenFour,setBoolExamenFour]=useState(true);
    const liste_modalite = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'list_modalite');
            if (response.data.status === 200) {
                setModalite(response.data.modalites);
            }
        } catch (Error) {

        }
    }

    const categoriesExamen = [
        { name: 'SIMPLE' },
        { name: 'SPECIAL' },
        { name: 'DEUX ORGANE' },
        { name: 'TROIS ORGANES' },
        { name: 'QUATRE ORGANES' }
    ];

    const ModePayement = [
        { name: 'COMPTANT' },
        { name: 'VIREMENT' },
    ];
    const [currency, setCurrency] = useState([
        {
            "code": "XAF",
            "name": "CFA Franc BEAC",
            "name_plural": "CFA francs BEAC",
            "symbol": "FCFA",
            "symbol_native": "FCFA",
            "decimal_digits": 0,
            "rounding": 0
        },
        {
            "code": "AOA",
            "name": "Angolan Kwanza",
            "name_plural": "Angolan Kwanza",
            "symbol": "Kz",
            "symbol_native": "Kz",
            "decimal_digits": 0,
            "rounding": 0
        },
        {
            "code": "XOF",
            "name": "CFA Franc BCEAO",
            "name_plural": "CFA francs BCEAO",
            "symbol": "CFA",
            "symbol_native": "CFA",
            "decimal_digits": 0,
            "rounding": 0
        },
        {
            "code": "EUR",
            "name": "Euro",
            "name_plural": "euros",
            "symbol": "€",
            "symbol_native": "€",
            "decimal_digits": 2,
            "rounding": 0
        },
        {
            "code": "CDF",
            "name": "Congolese Franc",
            "name_plural": "Congolese francs",
            "symbol": "CDF",
            "symbol_native": "FrCD",
            "decimal_digits": 2,
            "rounding": 0
        },
        {
            "code": "RWF",
            "name": "Rwandan Franc",
            "name_plural": "Rwandan francs",
            "symbol": "RWF",
            "symbol_native": "FR",
            "decimal_digits": 0,
            "rounding": 0
        },

        {
            "code": "ZAR",
            "name": "South African Rand",
            "name_plural": "South African Rand",
            "symbol": "R",
            "symbol_native": "R",
            "decimal_digits": 2,
            "rounding": 0
        },

    ]);
    const onSelectExamenOne = async () => {

        try {

           if(examenOne!=="" || examenOne!==null){
            setBoolExamenTwo(false);
           }

        } catch (Error) {

        }

    }
    const onSelectExamenTwo = async () => {

        try {

            const filteredData = examens.filter(item => {
                return !item.libelle_exam.includes(examenOne)
            });
            setExamensTwo(filteredData);
            if(examensTwo.length>0){
                setBoolExamenThree(false)
            }else{
                setBoolExamenThree(true)
            }

        } catch (Error) {

        }

    }

    const onSelectExamenThree = async () => {

        try {
            const filteredData = examens.filter(item =>
                !item.libelle_exam.includes(examenOne) && !item.libelle_exam.includes(examenTwo)
            );
            setExamensThree(filteredData);

            if(examensThree.length>0){
                setBoolExamenFour(false)
            }else{
                setBoolExamenFour(true)
            }
        } catch (Error) {

        }

    }

    const onSelectExamenFour = async () => {

        try {
            const filteredData = examens.filter(item =>
                !item.libelle_exam.includes(examenOne) && !item.libelle_exam.includes(examenTwo) && !item.libelle_exam.includes(examenThree)
            );
            setExamensFour(filteredData);
        } catch (Error) {

        }

    }
    const liste_examens = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_examen');
            if (response.data.status === 200) {
                setExamens(response.data.examens);
            }
        } catch (Error) {

        }

    }

    const addGainsA = async () => {
        try {
            let categorie_fichier_one="";
            const plus = "+";
            if (selectedModalite != "" && (examenOne !== "" || examenTwo !== "" || examenThree !== "" || examenFour !== "")) {
                categorie_fichier_one = typeExamen +
                    (examenOne !== "" ? plus + examenOne : "") +
                    (examenTwo !== "" ? plus + examenTwo : "") +
                    (examenThree !== "" ? plus + examenThree : "") +
                    (examenFour !== "" ? plus + examenFour : "")
            }
           
            if ( categorie_fichier_one !== "") {

                const data_gains = {
                    devise: devise,
                    gain: gain,
                    pourcentage: 0,
                    categorie_fichier:categorie_fichier_one,
                    mode_paiement: mode_paiement,
                    site_id: site_id,
                    user_id: user_id,
                    nbrExamen:nbrExamen,
                    typeExamen: typeExamen,
                    categorieExamen: categorieExamen
                }
                validationFields();
                if (devise != "" || gain != "" || categorie_fichier_one != "" || mode_paiement != "" || site_id != ""  || categorieExamen != "") {
                    const response = await axios.post(URL_AXIOS + "addGains", data_gains);
                    if (response.data.status === 200) {
                        ClearFields();
                        //setVisible(false);
                        // liste_gains();
                        // setBoolStatus(false)
                        Swal.fire({
                            title: "Réussi !",
                            text: "Le gain a été enregistré avec succès",
                            icon: "success"
                        });
                 
                    } else {
                        setBoolStatus(false)
                    }
                } else {
                 
                }
            } 



        } catch (error) {
            //show();
        }

    }
    const handleInputMP = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setMode_paiement(value)
            setMessage_mode_paiement("");
        } else {
            setMode_paiement("")
        }
    }
    const handleInputCategorie = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setCategorie_fichier(value)
            setMessage_categorie_fichier("");
        } else {
            setCategorie_fichier("")
        }
    }

    const handleInputDevise = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setDevise(value)
            setMessage_devise("");
        } else {
            setDevise("")
        }
    }

    const handleInputSite = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSite_id(value)
            setMessage_site_id("");
        } else {
            setSite_id("")
        }
    }
    const ClearFields = () => {
        setCategorie_fichier("");
        setDevise("");
        setGain("");
        setSite_id("");
        setMode_paiement("");
        setPourcentage("");
    }
    const validationFields = () => {
        if (categorie_fichier == "") {

            setMessage_categorie_fichier("Veuillez sélectionner l'examen");
        }

        if (gain == "") {

            setMessage_gain("Veuillez saisir le gain");
        }
        if (devise == "") {

            setMessage_devise("Veuillez sélectionner la dévise");
        }

        if (mode_paiement == "") {

            setMessage_mode_paiement("Veuillez sélectionner le mode de paiement");
        }
        if (pourcentage == "") {

            setMessage_pourcentage("Veuillez saisir le pourcentage");
        }

        if (site_id == "") {

            setMessage_site_id("Veuillez sélectionner le site");
        }
    }

    const handleInputExamen = (value) => {

        if (value !== "") {
            setCategorie_fichier(value)
            setMessage_categorie_fichier("");
        } else {
            setCategorie_fichier("")
        }
    }
    const handleInputGains = (event) => {
        const value = event.target.value;
        const regex = /^-?\d*$/;
        if (value !== "") {
            setGain(value)
            setMessage_gain("");
        } else {
            setGain("")
        }
        if (regex.test(value)) {
            setGain(value);
        } else {
            setGain("");
        }
    };

    const handleInputPourcentage = (event) => {
        const value = event.target.value;
        const regex = /^-?\d*$/;

        if (value !== "") {
            setPourcentage(value)
            setMessage_pourcentage("");
        } else {
            setPourcentage("")
        }
        if (regex.test(value)) {
            setPourcentage(value);
        } else {
            setPourcentage("");
        }
    };
    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status == 200) {
                setSites(response.data.sites);
                //setFilteredData(response.data.sites);
            }
        } catch (Error) {

        }

    }
    const handleNombre = (e) => {
        const value = e.target.value;
        if (value != null) {
            setNbrExamen(value);
        }
    }

    const addGains = async () => {
        try {
            const data_gains = {
                devise: devise,
                gain: gain,
                pourcentage: pourcentage,
                categorie_fichier: categorie_fichier.toUpperCase(),
                mode_paiement: mode_paiement,
                site_id: site_id,
                user_id: user_id
            }
            validationFields();
            if (devise != "" || gain != "" || pourcentage != "" || categorie_fichier != "" || mode_paiement != "" || site_id != "") {
                const response = await axios.post(URL_AXIOS + "addGains", data_gains);
                if (response.data.status == 200) {
                    ClearFields();

                } else {


                }
            } else {

            }


        } catch (error) {
            //show();
        }

    }

    useEffect(() => {
        liste_site();
        liste_examens();
        liste_modalite();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);
    return (
        <section id="container">
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div class="row" style={{ padding: 10 }}>
                        <Panel header="Nouveau gain">
                            <div className='row'>
                                <div className='col col-md-6'>
                                    <Dropdown value={site_id}
                                        style={{ fontWeight: 500, fontSize: 15, }}
                                        filter onChange={(e) => setSite_id(e.value)}
                                        options={sites} optionLabel="societe"
                                        placeholder="Examen 1" className="w-full"
                                        optionValue='id'

                                    />

                                </div>
                                <div className='col col-md-3'>
                                    <Dropdown value={typeExamen}
                                        style={{ fontWeight: 500, fontSize: 15 }}
                                        filter onChange={(e) => setTypeExamen(e.value)}
                                        options={modalite} optionLabel="libelle_modalite"
                                        placeholder="Modalité" className="w-full"
                                        optionValue='libelle_modalite'

                                    />
                                </div>
                                <div className='col col-md-3'>
                                    <Dropdown value={categorieExamen}
                                        style={{ fontWeight: 500, fontSize: 15, }}
                                        onChange={(e) => setCategorieExamen(e.value)}
                                        options={categoriesExamen} optionLabel="name"
                                        placeholder="Catégorie Examen" className="w-full"
                                        optionValue='name'

                                    />
                                </div>
                            </div>
                            <Divider />
                            <div className='row'>
                                <div className='col col-md-3'>
                                    <Dropdown value={examenOne}
                                        style={{ fontWeight: 500, fontSize: 15, }}
                                        filter onChange={(e) => setExamenOne(e.value)}
                                        onSelect={() => onSelectExamenOne()}
                                        options={examens} optionLabel="libelle_exam"
                                        placeholder="Examen 1" className="w-full"
                                        optionValue='libelle_exam'

                                    />
                                </div>
                                <div className='col col-md-3'>
                                    <Dropdown value={examenTwo}
                                        style={{ fontWeight: 500, fontSize: 15 }}
                                        filter onChange={(e) => setExamenTwo(e.value)}
                                        options={examensTwo} optionLabel="libelle_exam"
                                        placeholder="Examen 2" className="w-full"
                                        onSelect={() => onSelectExamenTwo()}
                                        optionValue='libelle_exam'
                                        disabled={boolExamenTwo}
                                    />
                                </div>
                                <div className='col col-md-3'>
                                    <Dropdown value={examenThree}
                                        style={{ fontWeight: 500, fontSize: 15 }}
                                        filter onChange={(e) => setExamenThree(e.value)}
                                        options={examensThree} optionLabel="libelle_exam"
                                        placeholder="Examen 3" className="w-full "
                                        onSelect={() => onSelectExamenThree()}
                                        optionValue='libelle_exam'
                                        disabled={boolExamenThree}
                                    />
                                </div>
                                <div className='col col-md-3'>
                                    <Dropdown value={examenFour}
                                        style={{ fontWeight: 500, fontSize: 15 }}
                                        filter onChange={(e) => setExamenFour(e.value)}
                                        options={examensFour} optionLabel="libelle_exam"
                                        placeholder="Examen 4" className="w-full"
                                        onSelect={() => onSelectExamenFour()}
                                        optionValue='libelle_exam'
                                        disabled={boolExamenFour}

                                    />
                                </div>

                            </div>
                            <Divider />
                            <div className='row'>

                                <div className='col col-md-3'>
                                    <input type='number'
                                        value={nbrExamen}
                                        onChange={(e) => handleNombre(e)}
                                        className='form-control'
                                        placeholder='NOMBRE EXAMEN' />
                                </div>
                                <div className='col col-md-3'>
                                    <input type='text' className='form-control' placeholder='GAIN *'
                                        value={gain} onChange={handleInputGains}
                                        style={{ textTransform: 'uppercase' }}
                                    />
                                </div>
                                <div className='col col-md-3'>

                                    <Dropdown value={devise}
                                        style={{ fontWeight: 500, fontSize: 15, }}
                                        onChange={(e) => setDevise(e.value)}
                                        options={currency} optionLabel="code"
                                        placeholder="Sélectionner une devise *" className="w-full"
                                        optionValue='code'

                                    />
                                </div>
                                <div className='col col-md-3'>
                                    <Dropdown value={mode_paiement}
                                        style={{ fontWeight: 500, fontSize: 15, }}
                                        onChange={(e) => setMode_paiement(e.value)}
                                        options={ModePayement} optionLabel="name"
                                        placeholder="Mode de paiement" className="w-full"
                                        optionValue='name'
                                    />
                                </div>
                            </div>
                            <Divider />
                            <div className='row'>
                                <div className='col col-md-12'>
                                    <center><button type='button' className='btn btn-info' onClick={()=>addGainsA()}>Enregistrer</button></center>
                                </div>

                            </div>
                        </Panel>
                    </div>
                </section>
            </section>
            <Footer />
        </section>
    );
}

export default AddGains;