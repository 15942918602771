import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import AsideBar from '../AsideBar';
import JoditEditor from 'jodit-react';
import Swal from 'sweetalert2/dist/sweetalert2';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';

function AddCompteRendu() {
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [titreCompteRendu, setTitreCompteRendu] = useState("");
    const [observation, setObservation] = useState("");
    const [content, setContent] = useState('');
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const useRefTitre = useRef(null);
    const config = {

        style: {
            position: 'absolute',
            height: '500px',
        },
        height: 500,
        allowResizeX: false,
        allowResizeY: false,
        autofocus: true,
        toolbarAdaptive: false,
        editorClassName: 'editorComponent',
        hidePoweredByJodit: false,
        zIndex: 0,
        readonly: false,
        placeholder: 'Ecrivez ici...',
        //activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
        toolbarButtonSize: 'middle',
        theme: 'default',
        saveModeInCookie: true,
        spellcheck: true,
        editorCssClass: true,
        triggerChangeEvent: true,
        width: 'auto',
        direction: '',
        language: 'auto',
        debugLanguage: false,
        i18n: 'fr',
        tabIndex: -1,
        toolbar: true,
        saveModeInStorage: true,
        useSplitMode: false,
        autofocus: false,

        colors: {
            greyscale: ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
            palette: ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
            full: [
                '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
                '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
                '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
                '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
                '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
                '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
            ]
        },

        colorPickerDefaultTab: 'background',
        removeButtons: [],
        disablePlugins: [],
        extraButtons: [],
        sizeLG: 900,
        sizeMD: 700,
        statusbar: false,
        shadowRoot: null,
        //sizeSM: 400,
        //sizeSM: 400,
        buttons: [
            'source', '|',
            'bold',
            'strikethrough',
            'underline',
            'italic', '|',
            'ul',
            'ol', '|',
            'outdent', 'indent', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'table',
            'link', '|',
            'align', 'undo', 'redo', '|',
            'hr',
            'eraser',
            'print'
        ],
        /*buttonsXS: [
            'bold',
            'image', '|',
            'paragraph', '|',
            'align', '|',
            'undo', 'redo', '|',
            'eraser',
            'dots'
        ],*/
        events: {},
        textIcons: false,
    }
    const handleChange = (newContent) => {
        setObservation(newContent);

    }
    const addMcr = async () => {
        try {
            //var editeurValue = document.querySelector(".editor").innerHTML;

            if (editorRef.current.value !== "" && titreCompteRendu != "") {
                const data = {
                    titre: titreCompteRendu,
                    observation: editorRef.current.value,
                    statut: '0',
                    id_membre: ''
                }
                const response = await axios.post(URL_AXIOS + 'addMcr', data);
                if (response.data.status === 200) {
                    Swal.fire({
                        title: "Réussi!",
                        text: "Le compte rendu a été enregistré avec succès !",
                        icon: "success"
                    });
                    setTitreCompteRendu("");
                    setContent("");
                    navigate('/modelcompterendu')
                } else {
                    alert("Erreur savings")
                }



            } else {
                Swal.fire({
                    title: 'Erreur',
                    text: 'Veuillez mettre le titre du compte rendu svp !',
                    icon: 'error',

                })
                useRefTitre.current.focus();

            }
        } catch (error) {
            alert("erreur 12" + error)
        }
    }

    const addConfirmMrc = () => {
        try {
            Swal.fire({
                title: "Question !",
                text: "Voulez-vous enregistrer ce compte rendu ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non"
            }).then((result) => {
                if (result.isConfirmed) {
                  
                    addMcr();

                }
            });
        } catch (error) {

        }
    }
    useEffect(() => {
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [])
    return (
        <section id="container">
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper card" style={{ height: "90vh" }}>
                    <div className='container-fluid ' >
                        <div className='row' style={{ marginTop: 10 }}>
                            <div className='col col-md-12'>
                                <center>
                                    <input type='text' className='form-control'
                                        placeholder="Titre *"
                                        value={titreCompteRendu}
                                        ref={useRefTitre}
                                        onChange={(e) => setTitreCompteRendu(e.target.value)}
                                        style={{ fontWeight: 'bold', width: "400px" }}
                                    />
                                </center>
                            </div>

                        </div>
                        <div className='row' style={{ padding: 10 }}>
                            <JoditEditor
                                ref={editorRef}
                                config={config}
                                tabIndex={1}
                            // value={content}
                            // onBlur={newContent => setContent(newContent)}
                            // onChange={(newContent) => setContent(newContent)}

                            />
                            <button type='button' onClick={addConfirmMrc} className='btn btn-primary' style={{ marginTop: 10, float: "right" }}>Enregistrer</button>
                        </div>
                    </div>
                </section>
            </section>
        </section >
    )
}

export default AddCompteRendu
