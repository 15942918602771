import React, { useEffect } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { URL_AXIOS } from '../../URL/URL';
import { useContext } from 'react';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import { Dialog } from 'primereact/dialog';
import { AutoComplete } from "primereact/autocomplete";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FaFileExcel } from "react-icons/fa6";
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import Loading from './Loading';
function Gains() {

    const navigate = useNavigate();
    const { gains, setGains, examens, setExamens, modalite, setModalite } = useContext(ThemeContext);
    //const [gains, setGains] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [query, setQuery] = useState("");
    const recordsPerPage = 25;
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = gains.slice(firstIndex, lastIndex);
    const npage = Math.ceil(gains.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [id, setId] = useState(0);
    const toast = useRef(null);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const [visible, setVisible] = useState(false);
    const [devise, setDevise] = useState('');
    const [gain, setGain] = useState("");
    const [pourcentage, setPourcentage] = useState(25);
    const [categorie_fichier, setCategorie_fichier] = useState('');
    const [mode_paiement, setMode_paiement] = useState('');
    const [site_id, setSite_id] = useState('');
    const [boolStatus, setBoolStatus] = useState(false);
    const [sites, setSites] = useState([]);
    //const [examens, setExamens] = useState([]);
    const [message_devise, setMessage_devise] = useState("");
    const [message_gain, setMessage_gain] = useState("");
    const [message_pourcentage, setMessage_pourcentage] = useState("");
    const [message_categorie_fichier, setMessage_categorie_fichier] = useState("");
    const [message_mode_paiement, setMessage_mode_paiement] = useState("");
    const [message_site_id, setMessage_site_id] = useState("");
    const [gainId, setGainId] = useState(0);
    const [nbrExamen, setNbrExamen] = useState('');
    const [typeExamen, setTypeExamen] = useState('');
    const [categorieExamen, setCategorieExamen] = useState('');
    const [pu, setPu] = useState(0);
    const [selectedGains, setSelectedGains] = useState(null);
    const [metaKey, setMetaKey] = useState(true);
    const [examensTwo, setExamensTwo] = useState([]);
    const [examensThree, setExamensThree] = useState([]);
    const [examensFour, setExamensFour] = useState([]);
    const [examen_2, setExamen_2] = useState("");
    const [examen_3, setExamen_3] = useState("");
    const [examen_4, setExamen_4] = useState("");
    const [libelleModalite, setLibelleModalite] = useState("");
    const [examen_1_bool, setExamen_1_bool] = useState(false);
    const [examen_2_bool, setExamen_2_bool] = useState(true);
    const [examen_3_bool, setExamen_3_bool] = useState(true);
    const [examen_4_bool, setExamen_4_bool] = useState(true);
    const [loading, setLoading] = useState(true);
    const AddGains = () => {
        setCategorie_fichier("");
        setDevise("");
        setGain("");
        setSite_id("");
        setMode_paiement("");
        setPourcentage("");
        setVisible(true);
        setBoolStatus(false);
        setNbrExamen('');
        setTypeExamen("");
        setCategorieExamen('');
        setPu("");
        setMessage_categorie_fichier("");
        setMessage_devise("");
        setMessage_gain("");
        setMessage_mode_paiement("");
        setMessage_pourcentage("");
        setMessage_site_id("");
        setExamen_1_bool(false);
        setExamen_2_bool(true);
        setExamen_3_bool(true);
        setExamen_4_bool(true);
    }

    const [currency, setCurrency] = useState([
        {
            "code": "XAF",
            "name": "CFA Franc BEAC",
            "name_plural": "CFA francs BEAC",
            "symbol": "FCFA",
            "symbol_native": "FCFA",
            "decimal_digits": 0,
            "rounding": 0
        },
        {
            "code": "AOA",
            "name": "Angolan Kwanza",
            "name_plural": "Angolan Kwanza",
            "symbol": "Kz",
            "symbol_native": "Kz",
            "decimal_digits": 0,
            "rounding": 0
        },
        {
            "code": "XOF",
            "name": "CFA Franc BCEAO",
            "name_plural": "CFA francs BCEAO",
            "symbol": "CFA",
            "symbol_native": "CFA",
            "decimal_digits": 0,
            "rounding": 0
        },
        {
            "code": "EUR",
            "name": "Euro",
            "name_plural": "euros",
            "symbol": "€",
            "symbol_native": "€",
            "decimal_digits": 2,
            "rounding": 0
        },
        {
            "code": "CDF",
            "name": "Congolese Franc",
            "name_plural": "Congolese francs",
            "symbol": "CDF",
            "symbol_native": "FrCD",
            "decimal_digits": 2,
            "rounding": 0
        },
        {
            "code": "RWF",
            "name": "Rwandan Franc",
            "name_plural": "Rwandan francs",
            "symbol": "RWF",
            "symbol_native": "FR",
            "decimal_digits": 0,
            "rounding": 0
        },

        {
            "code": "ZAR",
            "name": "South African Rand",
            "name_plural": "South African Rand",
            "symbol": "R",
            "symbol_native": "R",
            "decimal_digits": 2,
            "rounding": 0
        },

    ]);


    const handleInputMP = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setMode_paiement(value)
            setMessage_mode_paiement("");
        } else {
            setMode_paiement("")
        }
    }
    const handleInputCategorie = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setCategorie_fichier(value)
            setMessage_categorie_fichier("");
        } else {
            setCategorie_fichier("")
        }
    }

    const handleInputDevise = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setDevise(value)
            setMessage_devise("");
        } else {
            setDevise("")
        }
    }

    const handleInputSite = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSite_id(value)
            setMessage_site_id("");
        } else {
            setSite_id("")
        }
    }
    const ClearFields = () => {
        setCategorie_fichier("");
        setDevise("");
        setGain("");
        setSite_id("");
        setMode_paiement("");
        setPourcentage("");
    }
    const validationFields = () => {
        if (categorie_fichier == "") {

            setMessage_categorie_fichier("Veuillez sélectionner l'examen");
        }

        // if (gain == "") {

        //     setMessage_gain("Veuillez saisir le gain");
        // }
        // if (devise == "") {

        //     setMessage_devise("Veuillez sélectionner la dévise");
        // }

        // if (mode_paiement == "") {

        //     setMessage_mode_paiement("Veuillez sélectionner le mode de paiement");
        // }
        // if (pourcentage == "") {

        //     setMessage_pourcentage("Veuillez saisir le pourcentage");
        // }

        if (site_id == "") {

            setMessage_site_id("Veuillez sélectionner le site");
        }
    }

    const handleInputExamen = (e) => {
        const value = e.value;
        if (value !== "") {
            setCategorie_fichier(value)
            setMessage_categorie_fichier("");
        } else {
            setCategorie_fichier("")
        }
    }
    const handleInputGains = (event) => {
        const value = event.target.value;
        const regex = /^-?\d*$/;
        if (value !== "") {
            setGain(value)
            setMessage_gain("");
        } else {
            setGain("")
        }
        if (regex.test(value)) {
            setGain(value);
        } else {
            setGain("");
        }
    };





    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status == 200) {
                setSites(response.data.sites);
                //setFilteredData(response.data.sites);
            }
        } catch (Error) {

        }

    }
    const addGains = async () => {

        try {
            // const catExament = ((categorieExamen !== undefined || categorieExamen !== '' || categorieExamen.libelle_exam !== null) ? categorieExamen.code : "") + " " + categorie_fichier.libelle_exam + ((examen_2.libelle_exam !== "" && examen_2.libelle_exam !==undefined) ? "+" : "") + ((examen_2.libelle_exam !== "" && examen_2.libelle_exam !==undefined) ?  examen_2.libelle_exam : "") + ((examen_3.libelle_exam !== "" && examen_3.libelle_exam !== undefined) ? "+" : "") + (examen_3.libelle_exam !== "" && examen_3.libelle_exam !== undefined) ? examen_3.libelle_exam :"" + ((examen_4.libelle_exam !== "" && examen_4.libelle_exam !==undefined) ? "+" : "") + (examen_4.libelle_exam !== "" && examen_4.libelle_exam !==undefined) ? examen_4.libelle_exam:"";
            const catExament =
                ((categorieExamen !== undefined && categorieExamen !== '' && categorieExamen.libelle_exam !== null) ? categorieExamen.code : "") +
                " " +
                (categorie_fichier.libelle_exam ? categorie_fichier.libelle_exam : "") +
                ((examen_2.libelle_exam !== "" && examen_2.libelle_exam !== undefined) ? "+" : "") +
                ((examen_2.libelle_exam !== "" && examen_2.libelle_exam !== undefined) ? examen_2.libelle_exam : "") +
                ((examen_3.libelle_exam !== "" && examen_3.libelle_exam !== undefined) ? "+" : "") +
                ((examen_3.libelle_exam !== "" && examen_3.libelle_exam !== undefined) ? examen_3.libelle_exam : "") +
                ((examen_4.libelle_exam !== "" && examen_4.libelle_exam !== undefined) ? "+" : "") +
                ((examen_4.libelle_exam !== "" && examen_4.libelle_exam !== undefined) ? examen_4.libelle_exam : "");

            if (catExament !== null || catExament !== "") {

                const data_gains = {
                    devise: devise,
                    gain: gain !== "" ? gain : 0,
                    pourcentage: 0,
                    categorie_fichier: catExament,
                    mode_paiement: mode_paiement,
                    site_id: parseInt(site_id),
                    user_id: user_id,
                    nbrExamen: nbrExamen !== "" ? nbrExamen : 0,
                    typeExamen: typeExamen,
                    categorieExamen: categorieExamen !== "" ? categorieExamen.code : ""
                }

                validationFields();

                if (catExament != "" && site_id != "") {
                   
                    const response = await axios.post(URL_AXIOS + "addGains", data_gains);
                    if (response.data.status == 200) {
                       
                        if (response.data.statusBool) {

                            setVisible(false)
                            Swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: "L'examen existe déjà",
                                showConfirmButton: true,
                                // timer: 1500
                            }).then((result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (result.isConfirmed) {
                                    setVisible(true)
                                } else if (result.isDenied) {
                                    Swal.fire("Changes are not saved", "", "info");
                                }
                            });
                        } else {
                            ClearFields();
                            setVisible(false);
                            liste_gains();
                            setBoolStatus(false)
                        }


                    } else {
                        setBoolStatus(false)
                       
                    }
                } else {

                }
            }

            // else {
            //     const data_gains = {
            //         devise: devise,
            //         gain: gain,
            //         pourcentage: 0,
            //         categorie_fichier: categorie_fichier.toUpperCase(),
            //         mode_paiement: mode_paiement,
            //         site_id: site_id,
            //         user_id: user_id,
            //         typeExamen: typeExamen,
            //         categorieExamen: categorieExamen
            //     }
            //     validationFields();
            //     if (devise != "" || gain != "" || categorie_fichier != "" || mode_paiement != "" || site_id != "" || typeExamen != "" || categorieExamen != "") {
            //         const response = await axios.post(URL_AXIOS + "addGains", data_gains);
            //         if (response.data.status == 200) {
            //             ClearFields();
            //             setVisible(false);
            //             liste_gains();
            //         } else {


            //         }
            //     } else {

            //     }
            // }



        } catch (error) {
            //show();
        }

    }

    const liste_gains_by_id = async (id) => {
        try {
            await axios.get(URL_AXIOS + `get_gains_by_id/${id}`)
                .then(response => {
                    setDevise(response.data.gains.devise);
                    setGain(response.data.gains.gain);
                    setCategorie_fichier(response.data.gains.categorie_fichier);
                    setMode_paiement(response.data.gains.mode_paiement);
                    setSite_id(response.data.gains.site_id);
                    setPourcentage(response.data.gains.pourcentage);
                    setGainId(response.data.gains.id)
                    setNbrExamen(response.data.gains.nbrExamen);
                    setTypeExamen(response.data.gains.typeExamen);
                    setCategorieExamen(response.data.gains.categorieExamen);
                    setBoolStatus(true);
                    setVisible(true);

                });
        } catch (Error) {

        }

    }
    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(gains);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Liste_gains');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const confirm2 = (id) => {
        Swal.fire({
            title: "Question ?",
            text: "Voulez-vous supprimer ce gain ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: 'Non',
            confirmButtonText: "Oui"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Réussi!",
                    text: "La suppression a été effectuée avec succès !",
                    icon: "success"
                });
                setId(id);
                delete_gains(id);
                liste_gains();
            }
        });

    };
    const liste_gains = async () => {
        try {

            const response = await axios.get(URL_AXIOS + 'liste_gains');
            if (response.data.status === 200) {
                setGains(response.data.gains);
                setLoading(false)
            }
        } catch (Error) {

        }

    }
    const delete_gains = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_gains/${id}`);
            if (response.data.status == 200) {
                liste_gains();
            }
        } catch (Error) {

        }

    }

    const handleNombre = (e) => {
        const value = e.target.value;
        if (value != null) {
            setNbrExamen(value);
        }
    }

    const updateGainsDataTable = async (id, categorie_fichier, devise, gain, mode_paiement, nbrExamen, typeExamen, categorieExamen, site_id) => {
        try {

            const data_gains = {
                devise: devise,
                gain: gain,
                pourcentage: 0,
                categorie_fichier: categorie_fichier,
                mode_paiement: mode_paiement,
                site_id: site_id,
                user_id: user_id,
                nbrExamen: nbrExamen,
                typeExamen: typeExamen,
                categorieExamen: categorieExamen

            }
            validationFields();
            if (devise != "" || gain != "" || categorie_fichier != "" || mode_paiement != "" || nbrExamen != "" || typeExamen != "" || categorieExamen != "") {
                const response = await axios.post(URL_AXIOS + `update_gains/${id}`, data_gains);
                if (response.data.status == 200) {
                    liste_gains();

                } else {

                    //alert("La mise à jour a été effectuée avec succès 12! "+site_id)
                }
            } else {
                alert("not saved")
            }


        } catch (error) {
            alert('erreur ' + error)
        }

    }
    const updateGains = async (id) => {
        try {



            if (categorie_fichier !== null || categorie_fichier !== "") {
                const data_gains = {
                    devise: devise,
                    gain: gain,
                    pourcentage: 0,
                    categorie_fichier: categorie_fichier,
                    mode_paiement: mode_paiement,
                    site_id: site_id,
                    user_id: user_id,
                    nbrExamen: nbrExamen,
                    typeExamen: typeExamen,
                    categorieExamen: categorieExamen

                }
                validationFields();
                if (devise != "" || gain != "" || categorie_fichier != "" || mode_paiement != "" || nbrExamen != "" || typeExamen != "" || categorieExamen != "") {
                    const response = await axios.post(URL_AXIOS + `update_gains/${id}`, data_gains);
                    if (response.data.status == 200) {
                        setBoolStatus(false)
                        setVisible(false);

                    } else {


                    }
                } else {
                    alert("not saved")
                }
            }
            // else {
            //     const data_gains = {
            //         devise: devise,
            //         gain: gain,
            //         pourcentage: 0,
            //         categorie_fichier: categorie_fichier.toUpperCase(),
            //         mode_paiement: mode_paiement,
            //         site_id: site_id,
            //         user_id: user_id,
            //         nbrExamen: nbrExamen,
            //         typeExamen: typeExamen,
            //         categorieExamen: categorieExamen,
            //         pu: pu
            //     }
            //     validationFields();
            //     if (devise != "" || gain != "" || categorie_fichier != "" || mode_paiement != "" || typeExamen != "" || categorieExamen != "") {
            //         const response = await axios.post(URL_AXIOS + `update_gains/${id}`, data_gains);
            //         if (response.data.status == 200) {
            //             setBoolStatus(false)
            //             setVisible(false);

            //         } else {


            //         }
            //     } else {
            //         alert("not saved")
            //     }
            // }
            liste_gains();
        } catch (error) {
            alert('erreur ' + error)
        }

    }
    const [products, setProducts] = useState(null);
    let incrementID = 0;
    const ActionTemplate = (rowData) => {
        return (
            <div style={{ display: 'flex' }}>
                <button type='button' onClick={() => liste_gains_by_id(rowData.id)} className='btn btn-warning'><i className='fa fa-edit'></i></button>
                <button type='button' onClick={() => confirm2(rowData.id)} className='btn btn-danger ml-2'><i className='fa fa-trash-o'></i></button>
            </div>
        );
    };
    const columns = [
        // { id: "id", field: "id", header: '#', style: { width: "5%", textAlign: 'left', height: '40px' } },
        // { id: "id", field: 'societe', header: 'CENTRE', style: { width: "24%", textAlign: 'left', height: '40px' } },
        // { id: "id", field: 'categorie_fichier', header: 'EXAMEN', style: { width: "24%", textAlign: 'left', height: '40px' } },
        { id: "id", field: 'gain', header: 'PRIX', style: { width: "10%", textAlign: 'left', height: '40px' } },
        { id: "id", field: 'devise', header: 'DEVISE', style: { width: "5%", textAlign: 'left', height: '40px' } },
        // { id: "id", field: 'mode_paiement', header: 'PAIEMENT', style: { width: "8%", textAlign: 'left', height: '40px' } },
        { id: "id", field: 'created_at', header: 'DATE', style: { width: "8%", textAlign: 'left', height: '40px' } },
    ];
    // const isPositiveInteger = (val) => {
    //     let str = String(val);

    //     str = str.trim();

    //     if (!str) {
    //         return false;
    //     }

    //     str = str.replace(/^0+/, '') || '0';
    //     let n = Math.floor(Number(str));

    //     return n !== Infinity && String(n) === str && n >= 0;
    // };

    //     const onCellEditComplete = (e) => {
    //         let { rowData, newValue, field, originalEvent: event } = e;

    //         switch (field) {

    //             case 'gain':
    //                 updateGainsDataTable(rowData.id, rowData.categorie_fichier, rowData.devise, rowData.gain, rowData.mode_paiement, rowData.nbrExamen, rowData.typeExamen, rowData.categorieExamen, rowData.site_id)
    //                 if (isPositiveInteger(newValue)) rowData[field] = newValue;
    //                 else event.preventDefault();
    //                 break;
    //             case 'devise':

    //                 updateGainsDataTable(rowData.id, rowData.categorie_fichier, rowData.devise, rowData.gain, rowData.mode_paiement, rowData.nbrExamen, rowData.typeExamen, rowData.categorieExamen, rowData.site_id)
    //                 if (newValue.trim().length > 0) rowData[field] = newValue;
    //                 else event.preventDefault();
    //                 break;

    //             default:
    //                 if (newValue.trim().length > 0) rowData[field] = newValue;
    //                 else event.preventDefault();
    //                 break;
    //         }
    //     };


    //     const onCellEditCompleteDevise = (e) => {
    //         let { rowData, newValue, field, originalEvent: event } = e;

    //         switch (field) {

    //             case 'devise':
    //                 updateGainsDataTable(rowData.id, rowData.categorie_fichier, rowData.devise, rowData.gain, rowData.mode_paiement, rowData.nbrExamen, rowData.typeExamen, rowData.categorieExamen, rowData.site_id)
    //                 if (isPositiveInteger(newValue)) rowData[field] = newValue;
    //                 else event.preventDefault();
    //                 break;

    //             default:
    //                 break;
    //         }
    //     };

    //     const onCellEditCompleteGain = (e) => {
    //         let { rowData, newValue, field, originalEvent: event } = e;

    //         switch (field) {

    //             case 'gain':
    //                 updateGainsDataTable(rowData.id, rowData.categorie_fichier, rowData.devise, rowData.gain, rowData.mode_paiement, rowData.nbrExamen, rowData.typeExamen, rowData.categorieExamen, rowData.site_id)
    //                 if (isPositiveInteger(newValue)) rowData[field] = newValue;
    //                 else event.preventDefault();
    //                 break;

    //             default:
    //                 // if (newValue.trim().length > 0) rowData[field] = newValue;
    //                 // else event.preventDefault();
    //                 break;
    //         }
    //     };
    //     const cellEditor = (options) => {
    //         if (options.field === 'gain') return priceEditor(options);
    //         else return textEditor(options);
    //     };

    //     const textEditor = (options) => {
    //         return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} onKeyDown={(e) => onCellEditCompleteDevise(e)} />;
    //     };

    //     const priceEditor = (options, id) => {
    //         return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} onKeyDown={(e) => onCellEditCompleteGain(e)} />;
    //     };

    //     const priceBodyTemplate = (rowData) => {
    //         return new Intl.NumberFormat().format(rowData.gain);
    //     };

    const handleCategorie = (e) => {
        setCategorie_fichier(e.value)
        setExamen_2_bool(false)
    }
    const onSelectExamenOne = async (e) => {


        try {

            const value = e.target.value;
            if (value !== "") {
                setExamen_1_bool(false)
            } else {
                setExamen_1_bool(true)
            }



        } catch (Error) {

        }

    }
    const onSelectExamenTwo = async (e) => {


        try {

            const filteredData = examens.filter(item => {
                return !item.libelle_exam.includes(categorie_fichier.libelle_exam)
            });
            setExamensTwo(filteredData);
            setExamen_3_bool(false)


        } catch (Error) {

        }

    }

    const onSelectExamenThree = async (e) => {
        try {
            const filteredData = examens.filter(item =>
                !item.libelle_exam.includes(categorie_fichier.libelle_exam) && !item.libelle_exam.includes(examen_2.libelle_exam)
            );
            setExamensThree(filteredData);
            setExamen_4_bool(false)

        } catch (Error) {

        }

    }

    const onSelectExamenFour = async (e) => {
        try {
            const filteredData = examens.filter(item =>
                !item.libelle_exam.includes(categorie_fichier.libelle_exam) && !item.libelle_exam.includes(examen_2.libelle_exam) && !item.libelle_exam.includes(examen_3.libelle_exam)
            );
            setExamensFour(filteredData);
        } catch (Error) {

        }

    }
    const liste_modalite = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'list_modalite');
            if (response.data.status === 200) {
                setModalite(response.data.modalites);
            }
        } catch (Error) {

        }
    }
    useEffect(() => {
        liste_modalite();
        liste_site();
        liste_gains();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);
    return (
        <section id="container">
            <Dialog header="Gains" visible={visible} style={{ width: '60vw' }} onHide={() => setVisible(false)}>
                <form role="form" class="form-horizontal style-form">
                    <div className='form-group container-fluid'>

                        {boolStatus == false && <div className='row'>
                            <div className='col col-md-4'>
                                <Dropdown value={categorieExamen}
                                    name='libelleModalite'
                                    onChange={(e) => setCategorieExamen(e.value)}
                                    options={modalite}
                                    optionLabel="code"
                                    optionValue='code'
                                    placeholder="Sélectionnez modalité "
                                    filter className="w-full "
                                // onSelect={(e) => onSelectExamenOne(e)}
                                />

                            </div>
                            <div className='col col-md-8'>
                                <Dropdown value={categorie_fichier}
                                    name='examen_one'
                                    onChange={(e) => handleCategorie(e)}
                                    options={examens} optionLabel="libelle_exam" optionValue='libelle_exam'
                                    placeholder="Sélectionnez examen 1 *"
                                    filter className="w-full "
                                    disabled={examen_1_bool}
                                />
                            </div>

                        </div>}

                        {boolStatus == false && <div className='row'>
                            <div className='col col-md-4'>
                                <Dropdown
                                    value={examen_2}
                                    name='examen_two'
                                    onChange={(e) => setExamen_2(e.value)}
                                    options={examensTwo}
                                    optionLabel="libelle_exam"
                                    optionValue='libelle_exam'
                                    placeholder="Sélectionnez examen 2"
                                    filter className="w-full "
                                    onSelect={(e) => onSelectExamenTwo(e)}
                                    disabled={examen_2_bool}

                                />
                            </div>
                            <div className='col col-md-4'>
                                <Dropdown
                                    value={examen_3}
                                    name='examen_three'
                                    onChange={(e) => setExamen_3(e.value)}
                                    options={examensThree}
                                    optionLabel="libelle_exam"
                                    optionValue='libelle_exam'
                                    placeholder="Sélectionnez examen 3"
                                    filter className="w-full "
                                    onSelect={(e) => onSelectExamenThree(e)}
                                    disabled={examen_3_bool}

                                />
                            </div>
                            <div className='col col-md-4'>
                                <Dropdown
                                    value={examen_4}
                                    name='examen_four'
                                    onChange={(e) => setExamen_4(e.value)}
                                    options={examensFour}
                                    optionLabel="libelle_exam"
                                    optionValue='libelle_exam'
                                    placeholder="Sélectionnez examen 4"
                                    filter className="w-full "
                                    onSelect={(e) => onSelectExamenFour(e)}
                                    disabled={examen_4_bool}

                                />
                            </div>
                        </div>}
                        {boolStatus == true && <div className='row'>
                            <div className='col col-lg-12'>
                                <input type='text' disabled
                                    value={categorie_fichier}
                                    className='form-control' />
                            </div>
                        </div>}


                        <div className='row'>

                            <div className='col col-md-3'>
                                <select
                                    className='form-control'
                                    placeholder='Type examen'
                                    value={typeExamen}

                                    onChange={(e) => setTypeExamen(e.target.value)}
                                    style={{ fontSize: "12px" }}
                                >
                                    <option>Type examen</option>
                                    <option value={'SIMPLE'}>SIMPLE</option>
                                    <option value={'SPECIAL'}>SPECIAL</option>
                                    <option value={'DEUX ORGANES'}>DEUX ORGANES</option>
                                    <option value={'TROIS ORGANES'}>TROIS ORGANES</option>
                                    <option value={'QUATRE ORGANES'}>QUATRE ORGANES</option>
                                </select>
                            </div>
                            <div className='col col-md-3'>
                                <input type='number'
                                    value={nbrExamen}
                                    onChange={(e) => handleNombre(e)}
                                    className='form-control'
                                    placeholder='Nombre examen'
                                    style={{ fontSize: "12px" }}
                                />
                            </div>
                            <div className='col col-md-3'>
                                <input type='text' className='form-control' placeholder='gain'
                                    value={gain} onChange={handleInputGains} style={{ fontSize: "12px" }}
                                />
                                {/* {message_gain !== "" ? <label style={{ color: 'red' }}>{message_gain}</label> : ""} */}
                            </div>
                            <div className='col col-md-3'>
                                <select className='form-control' value={devise} onChange={handleInputDevise}
                                    style={{ fontSize: "12px" }}>
                                    <option value="">Sélectionner une devise </option>

                                    {currency.map((current) => (
                                        <option key={current.code} value={current.code}>
                                            {current.code}
                                        </option>
                                    ))}
                                </select>
                                {/* {message_devise !== "" ? <label style={{ color: 'red' }}>{message_devise}</label> : ""} */}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col col-lg-3'>
                                <select className='form-control' value={mode_paiement} onChange={handleInputMP}
                                    style={{ fontSize: "12px" }}
                                >
                                    <option>Mode paiement </option>
                                    <option value="COMPTANT">COMPTANT</option>
                                    <option value={"VIREMENT"}>VIREMENT</option>
                                </select>
                                {/* {message_mode_paiement !== "" ? <label style={{ color: 'red' }}>{message_mode_paiement}</label> : ""} */}
                            </div>

                            <div className='col col-md-9'>

                                <select className='form-control' value={site_id} onChange={handleInputSite}
                                    style={{ fontSize: "12px" }}
                                >
                                    <option value="">Sélectionner un site *</option>

                                    {sites.map((city) => (
                                        <option key={city.id} value={city.id}>
                                            {city.societe}
                                        </option>
                                    ))}
                                </select>
                                {message_site_id !== "" ? <label style={{ color: 'red' }}>{message_site_id}</label> : ""}
                            </div>
                        </div>


                    </div>
                    <div class="form-group">
                        <div class="col-lg-12 col-md-12">

                            <button class={boolStatus === false ? "btn btn-primary form-control" : 'btn btn-warning form-control'} type="button"
                                onClick={boolStatus === false ? () => addGains() : () => updateGains(gainId)}
                            >{boolStatus === false ? 'Enregistrer' : 'Mettre à jour'}</button>

                        </div>
                    </div>
                </form>
            </Dialog>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Header />
            <AsideBar username={usernameconnected} />
            {loading ? <Loading /> : <section id="main-content">
                <section class="wrapper">
                    <div className='row ' style={{ padding: 10, marginTop: 20 }}>
                        <div className="col col-md-8">

                            <Button className='btn btn-info' label='Nouveau gain'
                                onClick={() => AddGains()}
                            />
                            <button type="button" className='btn btn-success' title='Exporter les données'
                                onClick={exportExcel}
                                style={{ marginLeft: 10, color: 'white' }}
                            >
                                <FaFileExcel />
                                <span style={{ marginLeft: 10 }}>Exporter les données</span>
                            </button>
                        </div>
                        { /* <div className='col col-md-4'>
                            <input type="text" className='form-control' placeholder='Recherche ...'
                                value={query} onChange={handleQueryText}
                                style={{ borderRadius: 100 }} />
    </div>*/}
                    </div>
                    <div class="row " style={{ padding: 10 }}>

                        <DataTable value={gains} paginator rows={10}
                            tableStyle={{ minWidth: '50rem' }}
                            emptyMessage="Aucun gain disponible!"
                            className="custom-datatable"

                        >
                            <Column field='id' header="#" headerStyle={{ textAlign: 'center', padding: '0 10px' }} style={{ width: "3%", textAlign: 'center', padding: '0 10px' }}></Column>
                            <Column field='societe' header="CENTRE" style={{ width: "auto", height: "40px", padding: '0 10px' }}></Column>
                            <Column field='categorie_fichier' header="EXAMEN" style={{ width: "auto", height: "40px", padding: '0 10px' }}></Column>
                            <Column field='gain' header="GAIN" style={{ width: "auto", height: "40px", padding: '0 10px' }}></Column>
                            <Column field='mode_paiement' header="MP" style={{ width: "auto", height: "40px", padding: '0 10px' }}></Column>
                            <Column header="ACTIONS" body={ActionTemplate} style={{ width: "5%", height: "40px", padding: '0 10px' }}></Column>
                        </DataTable>
                    </div>
                </section>
            </section>}
            <Footer />
        </section>
    );
}

export default Gains;