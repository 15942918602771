import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { AutoComplete } from 'antd';
import axios from 'axios';
import { URL_AXIOS, URL_FOLDER_API } from '../../URL/URL';
import Footer from '../Footer';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format } from 'date-fns';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import { RadioButton } from "primereact/radiobutton";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { ContextMenu } from 'primereact/contextmenu';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../context/ContextHeader';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import Loading from './Loading';
function Corbeille(props) {
    const { dossiersCorbeille, setdossiersCorbeille,setDossiers } = useContext(ThemeContext);
    const [visible, setVisible] = useState(false);
    const [sites, setSites] = useState([]);
    //const [dossiers, setDossiers] = useState([]);
    const [site, setSite] = useState('');
    const [allcheck, setAllcheck] = useState(true);
    const [query, setQuery] = useState("");
    const [selectedMembres, setSelectedMembres] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [medecinradio, setMedecinradio] = useState(0);
    const [medecinradioSetValue, setMedecinradioSetValue] = useState(0);
    const recordsPerPage = 30;
    const dt = useRef(null);
    const cm = useRef(null);
    const toast = useRef(null);
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = dossiersCorbeille.slice(firstIndex, lastIndex);
    const npage = Math.ceil(dossiersCorbeille.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [isChecked, setIsChecked] = useState([]);
    const [queryPatient, setQueryPatient] = useState("");
    const [queryExamen, setQueryExamen] = useState("");
    const [dataPatient, setDataPatient] = useState([]);
    const [dataExamen, setDataExamen] = useState([]);
    const [membres, setMembres] = useState([]);
    const [dateDebut, setDateDebut] = useState(new Date());
    const [dateFin, setDateFin] = useState(new Date());
    const [bool, setBool] = useState(true);
    const [boolD, setBoolD] = useState(false);
    const [statusM, setStatusM] = useState(false);
    const navigate = useNavigate();
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const site_id = sessionStorage.getItem("site_id");
    const [boolBtn, setBoolBtn] = useState(false);
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [loading, setLoading] = useState(true);
    const items = [
        { label: 'Affectation', icon: 'pi pi-fw pi-users', command: () => confirm1() }
    ];
    const accept = () => {
        //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });

        setVisible(false);
        setMedecinradio('');


    }

    const reject = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        //setMedecinradio('')
    }
    const confirm1 = () => {
        confirmDialog({
            message: "Voulez-vous effectuer l'affectation médecin ?",
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
        });
    };
    const confirm2 = () => {

        if (isChecked.length > 0) {

            Swal.fire({
                title: "Question",
                text: "Voulez-vous restaurer ce dossier ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.isConfirmed) {
                    restaurer_dossier();
                    liste_dossiers_transferer(site_id)
                    setStatusM(false)
                    setIsChecked([])
                    Swal.fire({
                        text: "Le dossier a été restauré avec succès !",
                        icon: "success"
                    });
                } else if (result.isDenied) {
                    //setVisible(false);
                }
            });

        } else {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Veuillez cocher les dossiers à modifier",
                showConfirmButton: false,
                timer: 2500
            });
        }

    };

    const confirm3 = () => {

            Swal.fire({
                title: "Question",
                text: "Voulez-vous vider la corbeille ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.isConfirmed) {
                    vider_corbeille();
                    Swal.fire({
                        text: "La corbeille a été vidée avec succès !",
                        icon: "success"
                    });
                    liste_dossiers()
                } else if (result.isDenied) {
                    //setVisible(false);
                }
            });

    };
    function formatDate(date) {
        const datecreated = new Date(date)
        const year = datecreated.getFullYear();
        const month = String(datecreated.getMonth() + 1).padStart(2, '0');
        const day = String(datecreated.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const cancel_research = () => {
        setDateDebut('');
        setDateFin('');
        setBool(true);
        liste_dossiers();
        setBoolD(false);
    }

    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }

    const handleCheckboxChange = () => {
        setAllcheck(!allcheck);
    };


    const liste_site = async () => {
        try {

            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status === 200) {
                setSites(response.data.sites);
            }
        } catch (Error) {

        }
    }

    const vider_corbeille = async () => {
        try {
            const data={
                ids:isChecked
            }
            const response = await axios.post(URL_AXIOS + 'vider_corbeille',data);
            if (response.data.status === 200) {
                liste_dossiers();
            }
        } catch (Error) {

        }
    }
    const liste_dossiers = async () => {
        try {

            const response = await axios.get(URL_AXIOS + "liste_dossiers_retireAdmin");
            if (response.data.status === 200) {
                setdossiersCorbeille(response.data.dossier_send);
                setLoading(false)
            }
        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }

    const liste_dossiers_transferer = async (id_site) => {
        try {
            const response = await axios.get(URL_AXIOS + "liste_dossiers");
            if (response.data.status == 200) {
                setDossiers(response.data.dossier_send);

            }
        } catch (error) {
           // alert("Erreur " + error.message);
        }
    }

    // const lister_contrat = async () => {
    //     try {

    //         const response = await axios.get(URL_AXIOS + 'index');
    //         if (response.data.status === 200) {
    //             setMembres(response.data.data_membres_sites);

    //         }
    //     } catch (Error) {

    //     }
    // };

    const handleCheckedInput = (e) => {
        const value = e.target.value; // Get the value of the checkbox
        const checked = e.target.checked;
        let updatedArray;
        if (checked) {
            updatedArray = [...isChecked, value];
        } else {
            updatedArray = isChecked.filter((item) => item !== value);
        }
        setIsChecked(updatedArray);

        if (updatedArray.length === 0) {
            setBoolBtn(false);
        } else {
            setBoolBtn(true);
        }

    };
    const handleAffectation = () => {
        if (isChecked.length > 0) {
            setVisible(true);
        } else {
            alert("Veuillez cocher la ligne");
        }
    }
    const handleRadio = (e) => {
        const value = e.target.value;

        setMedecinradio(value);
        setMedecinradioSetValue(value);
        //confirm1();
    }

    const restaurer_dossier = async () => {
        try {

            for (const val of isChecked) {
                const response = await axios.post(URL_AXIOS + `restaurer_dossier/${val}`);
                if (response.data.status === 200) {
                    liste_dossiers();
                    liste_dossiersTransferes();

                }

            }

        } catch (error) {
           // alert("Erreur " + error.message);
        }
    }
    const handleDownload = async (e, value, value2) => {
        e.preventDefault();
    
        const downloadFile = (fileName, url) => {
            return new Promise((resolve) => {
                const link = document.createElement('a');
                link.download = fileName;
                link.href = url;
                document.body.appendChild(link); // Append link to the body
                link.click();
                document.body.removeChild(link); // Remove link from the body
                resolve();
            });
        };
    
        try {
            if (value !== null) {
                await downloadFile('Example-PDF-File', URL_FOLDER_API + value);
            }
            if (value2 !== null) {
                // Increase delay to ensure the browser has time to handle the first download
                await new Promise((resolve) => setTimeout(resolve, 2000));
                await downloadFile('Example-PDF-File2', URL_FOLDER_API + value2);
            }
        } catch (error) {
            console.error('Error during download:', error);
        }
    };
    // const handleDownload = (e, value, value2) => {
    //     e.preventDefault();
    //     const link = document.createElement('a');
    //     if (value !== null) {

    //         link.download = 'Example-PDF-File';
    //         link.href = value;
    //         link.click();
    //     }
    //     if (value2 !== null) {
    //         // Delay the creation and click event of the second anchor
    //         setTimeout(() => {
    //             const link2 = document.createElement('a');
    //             link2.download = 'Example-PDF-File2';
    //             link2.href = value2;
    //             link2.click();
    //         }, 100); // Adjust the delay time as needed
    //     }
    // };
    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: 10 }}>


                <Checkbox value={data.id} name={`check_${data.id}`} onChange={(e) => handleCheckedInput(e)} checked={isChecked.includes(data.id)} />
                <i className='fa fa-hand-o-up'
                    onClick={() => navigate(`/member_detail_folder/${data.id}`)}
                    style={{
                        color: 'red',
                        cursor: 'pointer',
                        marginLeft: 10,
                        fontSize: 14
                    }}
                    title='Détails'
                >
                </i>
             {data.nom_fichier !== null || data.nom_fichier_2 !== null ?  <i className='fa fa-download'
                    onClick={(e) => handleDownload(e, (data.chemin_fichier), (data.chemin_fichier_2))}
                    style={{
                        color: 'green',
                        cursor: 'pointer',
                        marginLeft: 10,
                        fontSize: 14
                    }}
                    title='Télécharger examen'
                >
                </i> :""}
            </div>
        )
    }

    const ActionBodyF = (data) => {
        return (

            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10 }}>

                <span style={{ backgroundColor: data.nom_fichier !== null ? 'green' : 'red', height: 10, width: 10, borderRadius: '50%', position: 'absolute' }}>
                </span>
                <span style={{ backgroundColor: data.nom_fichier_2 !== null ? 'green' : 'red', height: 10, width: 10, borderRadius: '50%', position: 'absolute', marginLeft: '15px' }}>

                </span>
            </div>
        )
    }

    const ActionBodyR = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                <span style={{ backgroundColor: data.etat_medecin === 1 ? 'green' : 'red', height: 10, width: 10, borderRadius: '50%', position: 'absolute' }}>

                </span>
            </div>
        )
    }
    const HeaderF = () => {
        return (
            <p style={{ marginLeft: 10 }}>F</p>
        )
    }
    const liste_dossiersTransferes = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_dossiers');
            if (response.data.status == 200) {
                setDossiers(response.data.dossier_send);

            }
        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }
    useEffect(() => {

        liste_site();
        liste_dossiers();
        // lister_contrat();

        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);


    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <Toast ref={toast} />
            <ConfirmDialog />
        
            <ContextMenu model={items} ref={cm} breakpoint="767px" />
            <Sidebar visible={visible} onContextMenu={(e) => cm.current.show(e)} position="right" onHide={() => setVisible(false)}>

                <center><h5>Liste des médecins</h5></center>

                <hr style={{ width: '100%', height: 2, backgroundColor: 'red' }} />
                <div className="flex justify-content-center">
                    <div className="flex flex-column ">
                        {membres.map((sitemembre) => {
                            return (
                                <ul style={{ padding: 2 }}>
                                    <li>
                                        <div key={sitemembre.membre_id} className="flex align-items-center">
                                            <RadioButton inputId={`sitemembre_${sitemembre.membre_id}`} name={sitemembre.membre_id} value={sitemembre.membre_id} onChange={handleRadio} checked={medecinradio === sitemembre.membre_id} />
                                            <label htmlFor={`sitemembre_${sitemembre.membre_id}`} style={{ fontWeight: 'bolder', marginLeft: 10 }} className="ml-2">{sitemembre.nom + " " + sitemembre.prenom}</label>

                                        </div>
                                    </li>
                                </ul>
                            )


                        })}
                    </div>
                </div>
            </Sidebar>

           {loading ? <Loading /> :  <section id="main-content">
                <section class="wrapper ">
                    <div className='container-fluid mt'>
                        <div className='row'>
                            <div className='col col-md-10'>
                            </div>
                            <div className='col col-md-2'>
                                <div style={{ float: 'right' }}>
                                    <button type='button'  title='Vider la corbeille' onClick={confirm3} className='btn btn-danger' style={{ marginLeft: 10 }}>
                                        <i class=" fa fa-trash-o"></i>
                                    </button>
                                    <button type='button' disabled={boolBtn === false ? true : false} title='Restaurer dossiers' onClick={confirm2} className='btn btn-success' style={{ marginLeft: 10 }}>
                                        <i class=" fa fa-undo"></i>
                                    </button>
                                </div>

                            </div>



                        </div>
                        <Divider />
                        <div className='row' style={{ paddingBottom: "40px" }}>

                            <DataTable emptyMessage="Aucun dossier disponible!" value={dossiersCorbeille}  paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className="custom-datatable">
                                <Column header={() => { return <div style={{ marginLeft: 20 }}>#</div> }}  bodyStyle={{ textAlign: 'center' }} headerStyle={{ marginLeft: "100px" }} field='id' style={{ width: '5%', height: '40px' }}></Column>
                                <Column field='societe' header="CENTRE" style={{ width: 'auto', height: '40px' }} headerStyle={{ textAlign: 'center' }}></Column>
                                <Column field='nom_pat' header="PATIENTS" style={{ width: 'auto', height: '40px' }} headerStyle={{ textAlign: 'center' }}></Column>
                                <Column field={'categorie_fichier'} header="EXAMENS" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column field={(data) => { return data.nom_medecin + ' ' + data.prenom_medecin }} headerStyle={{ textAlign: 'center !important' }} header="RADIOLOGUE" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column field='created_at' header="ENVOYE LE" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column header={"F"} body={ActionBodyF} style={{ width: '4%', height: '40px', textAlign: 'center' }} headerStyle={{ textAlign: 'center !important' }}></Column>
                                <Column header="R" body={ActionBodyR} style={{ width: '2%', height: '40px' }} headerStyle={{ textAlign: 'center' }}></Column>
                                <Column header="" body={ActionBody} style={{ width: 'auto', height: '40px' }} headerStyle={{ textAlign: 'center' }}></Column>
                            </DataTable>
                            {/*  <div class="content-panel" style={{ height: '540px', position: 'relative', overflow: 'auto' }} >
                            
                                <section id="unseen" >
                             <table class="table table-bordered table-striped table-condensed" >
                                        <thead>
                                            <tr>

                                                <th style={{ color: 'red' }}><center>#</center></th>
                                                <th style={{ color: 'red' }}>PATIENTS</th>
                                                <th style={{ color: 'red' }}>EXAMENS</th>
                                                <th style={{ color: 'red' }}>ENVOYE LE</th>
                                                <th style={{ color: 'red' }}>RADIOLOGUE</th>
                                                <th style={{ color: 'red', width: '50px' }}><center>F</center></th>
                                                <th style={{ color: 'red' }}>R</th>
                                                <th colSpan={2}></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.map((dossier, index) => (

                                                <tr key={index}>

                                                    <td><center>{index + 1}</center></td>
                                                    <td>{dossier.nom_pat}</td>
                                                    <td>{dossier.categorie_fichier}</td>
                                                    <td>{dossier.created_at}</td>
                                                    <td>{dossier.nom_medecin + " " + dossier.prenom_medecin}</td>

                                                    <td >

                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginLeft: '-15px' }}>

                                                            <span style={{ backgroundColor: dossier.nom_fichier !== null ? 'green' : 'red', height: 10, width: 10, borderRadius: '50%', position: 'absolute' }}>

                                                            </span>
                                                            <span style={{ backgroundColor: dossier.nom_fichier_2 !==null ? 'green' : 'red', height: 10, width: 10, borderRadius: '50%', position: 'absolute', marginLeft: '30px' }}>
                                                                
                                                            </span>
                                                        </div>


                                                    </td>
                                                    <td>

                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                                                            <span style={{ backgroundColor: 'green', height: 10, width: 10, borderRadius: '50%', position: 'absolute' }}>

                                                            </span>
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <center>
                                                         
                                                        <Checkbox value={dossier.id} name={`check_${dossier.id}`} onChange={(e) => handleCheckedInput(e)} checked={isChecked.includes(dossier.id)} />
                                                            {/*<input type="checkbox" value={dossier.id} onChange={(e) => handleCheckedInput(e)} />
                                                        </center>
                                                    </td>
                                                    <td>
                                                        <center>
                                                        <i className='fa fa-hand-o-up'
                                                                onClick={() => setVisibleDetail(true)}
                                                                style={{ color: 'red',cursor:'pointer' }}>
                                                            </i>
                                                        </center>
                                                    </td>

                                                </tr>
                                            ))}


                                        </tbody>
                                    </table> 
                                    <nav style={{ marginLeft: 10 }}>
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <a href='#' className='page-link'
                                                    onClick={prevPage}
                                                >Prev</a>
                                            </li>
                                            {
                                                numbers.map((n, i) => (
                                                    <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                        <a href='#' className='page-link'
                                                            onClick={() => changeCPage(n)}
                                                        >{n}</a>
                                                    </li>
                                                ))
                                            }
                                            <li className="page-item">
                                                <a href='#' className='page-link'
                                                    onClick={nextPage}
                                                >Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </section>

                            </div>
*/}

                        </div>
                    </div>
                </section>
            </section>}
            <Footer />
        </div>
    );
}

export default Corbeille;