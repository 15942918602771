import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { AutoComplete } from 'antd';
import axios from 'axios';
import { URL_AXIOS, URL_FOLDER_API } from '../../URL/URL';
import Footer from '../Footer';
import { format } from 'date-fns';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import { RadioButton } from "primereact/radiobutton";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { ContextMenu } from 'primereact/contextmenu';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { ThemeContext } from '../../context/ContextHeader';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from './Loading';
function ConfigGains(props) {
    const navigate = useNavigate();
    const { dossiersConfigGains, setDossiersConfigGains,disableOption, setDisableOption } = useContext(ThemeContext);
    const [visible, setVisible] = useState(false);
    const [sites, setSites] = useState([]);
    //const [dossiers, setDossiers] = useState([]);
    const [site, setSite] = useState('');
    const [allcheck, setAllcheck] = useState(true);
    const [query, setQuery] = useState("");
    const [selectedMembres, setSelectedMembres] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [medecinradio, setMedecinradio] = useState(0);
    const [medecinradioSetValue, setMedecinradioSetValue] = useState(0);
    const recordsPerPage = 10;
    const dt = useRef(null);
    const cm = useRef(null);
    const toast = useRef(null);
    const [isChecked, setIsChecked] = useState(null);
    const [queryPatient, setQueryPatient] = useState("");
    const [queryExamen, setQueryExamen] = useState("");
    const [dataPatient, setDataPatient] = useState([]);
    const [dataExamen, setDataExamen] = useState([]);
    const [membres, setMembres] = useState([]);
    const [dateDebut, setDateDebut] = useState(new Date());
    const [dateFin, setDateFin] = useState(new Date());
    const [bool, setBool] = useState(true);
    const [boolD, setBoolD] = useState(false);
    const [statusM, setStatusM] = useState(false);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [boolBtn, setBoolBtn] = useState(false);
    const [site_id, setSite_id] = useState("");
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [visibleConfigGain, setVisibleConfigGain] = useState(false);
    const [dossierGetId, setDossierGetId] = useState("");


    const [devise, setDevise] = useState('');
    const [gain, setGain] = useState("");
    const [pourcentage, setPourcentage] = useState(25);
    const [categorie_fichier, setCategorie_fichier] = useState('');
    const [mode_paiement, setMode_paiement] = useState('');
    const [boolStatus, setBoolStatus] = useState(false);
    //const [examens, setExamens] = useState([]);
    const [message_devise, setMessage_devise] = useState("");
    const [message_gain, setMessage_gain] = useState("");
    const [message_pourcentage, setMessage_pourcentage] = useState("");
    const [message_categorie_fichier, setMessage_categorie_fichier] = useState("");
    const [message_mode_paiement, setMessage_mode_paiement] = useState("");
    const [message_site_id, setMessage_site_id] = useState("");
    const [gainId, setGainId] = useState(0);
    const [nbrExamen, setNbrExamen] = useState('');
    const [typeExamen, setTypeExamen] = useState('');
    const [categorieExamen, setCategorieExamen] = useState('');
    const [pu, setPu] = useState(0);
    const [selectedGains, setSelectedGains] = useState(null);
    const [metaKey, setMetaKey] = useState(true);
    const [examensTwo, setExamensTwo] = useState([]);
    const [examensThree, setExamensThree] = useState([]);
    const [examensFour, setExamensFour] = useState([]);
    const [examen_2, setExamen_2] = useState("");
    const [examen_3, setExamen_3] = useState("");
    const [examen_4, setExamen_4] = useState("");
    const [libelleModalite, setLibelleModalite] = useState("");
    const [examen_1_bool, setExamen_1_bool] = useState(false);
    const [examen_2_bool, setExamen_2_bool] = useState(true);
    const [examen_3_bool, setExamen_3_bool] = useState(true);
    const [examen_4_bool, setExamen_4_bool] = useState(true);
    const [loading, setLoading] = useState(true);



    const [currency, setCurrency] = useState([
        {
            "code": "XAF",
            "name": "CFA Franc BEAC",
            "name_plural": "CFA francs BEAC",
            "symbol": "FCFA",
            "symbol_native": "FCFA",
            "decimal_digits": 0,
            "rounding": 0
        },
        {
            "code": "AOA",
            "name": "Angolan Kwanza",
            "name_plural": "Angolan Kwanza",
            "symbol": "Kz",
            "symbol_native": "Kz",
            "decimal_digits": 0,
            "rounding": 0
        },
        {
            "code": "XOF",
            "name": "CFA Franc BCEAO",
            "name_plural": "CFA francs BCEAO",
            "symbol": "CFA",
            "symbol_native": "CFA",
            "decimal_digits": 0,
            "rounding": 0
        },
        {
            "code": "EUR",
            "name": "Euro",
            "name_plural": "euros",
            "symbol": "€",
            "symbol_native": "€",
            "decimal_digits": 2,
            "rounding": 0
        },
        {
            "code": "CDF",
            "name": "Congolese Franc",
            "name_plural": "Congolese francs",
            "symbol": "CDF",
            "symbol_native": "FrCD",
            "decimal_digits": 2,
            "rounding": 0
        },
        {
            "code": "RWF",
            "name": "Rwandan Franc",
            "name_plural": "Rwandan francs",
            "symbol": "RWF",
            "symbol_native": "FR",
            "decimal_digits": 0,
            "rounding": 0
        },

        {
            "code": "ZAR",
            "name": "South African Rand",
            "name_plural": "South African Rand",
            "symbol": "R",
            "symbol_native": "R",
            "decimal_digits": 2,
            "rounding": 0
        },

    ]);


    const handleInputMP = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setMode_paiement(value)
            setMessage_mode_paiement("");
        } else {
            setMode_paiement("")
        }
    }
    const handleInputCategorie = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setCategorie_fichier(value)
            setMessage_categorie_fichier("");
        } else {
            setCategorie_fichier("")
        }
    }

    const handleInputDevise = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setDevise(value)
            setMessage_devise("");
        } else {
            setDevise("")
        }
    }

    const handleInputSite = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSite_id(value)
            setMessage_site_id("");
        } else {
            setSite_id("")
        }
    }

    const handleInputGains = (event) => {
        const value = event.target.value;
        const regex = /^-?\d*$/;
        if (value !== "") {
            setGain(value)
            setMessage_gain("");
        } else {
            setGain("")
        }
        if (regex.test(value)) {
            setGain(value);
        } else {
            setGain("");
        }
    };

    const handleNombre = (e) => {
        const value = e.target.value;
        if (value != null) {
            setNbrExamen(value);
        }
    }

    const updateGains = async (id) => {
        try {
            if (categorie_fichier !== null || categorie_fichier !== "") {
                const data_gains = {
                    devise: devise,
                    gain: gain,
                    pourcentage: 0,
                    categorie_fichier: categorie_fichier,
                    mode_paiement: mode_paiement,
                    site_id: site_id,
                    user_id: user_id,
                    nbrExamen: nbrExamen,
                    typeExamen: typeExamen,
                    categorieExamen: categorieExamen

                }
                validationFields();
                if (devise != "" || gain != "" || categorie_fichier != "" || mode_paiement != "" || nbrExamen != "" || typeExamen != "" || categorieExamen != "") {
                    const response = await axios.post(URL_AXIOS + `update_gains/${id}`, data_gains);
                    if (response.data.status == 200) {
                        liste_dossiers_all_Not_Gains_Config()
                        setVisibleConfigGain(false);

                    } else {


                    }
                } else {
                    alert("not saved")
                }
            }
            // else {
            //     const data_gains = {
            //         devise: devise,
            //         gain: gain,
            //         pourcentage: 0,
            //         categorie_fichier: categorie_fichier.toUpperCase(),
            //         mode_paiement: mode_paiement,
            //         site_id: site_id,
            //         user_id: user_id,
            //         nbrExamen: nbrExamen,
            //         typeExamen: typeExamen,
            //         categorieExamen: categorieExamen,
            //         pu: pu
            //     }
            //     validationFields();
            //     if (devise != "" || gain != "" || categorie_fichier != "" || mode_paiement != "" || typeExamen != "" || categorieExamen != "") {
            //         const response = await axios.post(URL_AXIOS + `update_gains/${id}`, data_gains);
            //         if (response.data.status == 200) {
            //             setBoolStatus(false)
            //             setVisible(false);

            //         } else {


            //         }
            //     } else {
            //         alert("not saved")
            //     }
            // }
            // liste_gains();
        } catch (error) {
            alert('erreur ' + error)
        }

    }
    const validationFields = () => {
        if (categorie_fichier == "") {

            setMessage_categorie_fichier("Veuillez sélectionner l'examen");
        }

        if (gain == "") {

            setMessage_gain("Veuillez saisir le gain");
        }
        if (devise == "") {

            setMessage_devise("Veuillez sélectionner la dévise");
        }

        if (mode_paiement == "") {

            setMessage_mode_paiement("Veuillez sélectionner le mode de paiement");
        }
        if (pourcentage == "") {

            setMessage_pourcentage("Veuillez saisir le pourcentage");
        }

        if (site_id == "") {

            setMessage_site_id("Veuillez sélectionner le site");
        }
    }
    const items = [
        { label: 'Affectation', icon: 'pi pi-fw pi-users', command: () => confirm1() }
    ];
    const accept = () => {
        //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });

        setVisible(false);
        setMedecinradio('');


    }

    const reject = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        //setMedecinradio('')
    }
    const confirm1 = () => {
        if (medecinradio !== 0) {

            Swal.fire({
                title: "Questions ?",
                text: "Voulez-vous attribuer ces dossiers à ce médecin ?",
                icon: "warning",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Oui",
                denyButtonText: `Non`
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    accept();
                    Swal.fire("Le dossier a été attribué avec success", "", "success");


                } else if (result.isDenied) {
                    setVisible(false);
                }
            });
            setVisible(false);
        } else {


        }

    };




    const liste_site = async () => {
        try {

            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status === 200) {
                setSites(response.data.sites);
            }
        } catch (Error) {

        }


    }


    const liste_dossiers_all_Not_Gains_Config = async () => {
        try {
            const response = await axios.get(URL_AXIOS + "liste_dossiersConfigGains");
            if (response.data.status == 200) {
                setDossiersConfigGains(response.data.dossier_send);
                setLoading(false);
            }
        } catch (error) {
            // alert("Erreur " + error.message);
        }
    }


    const lister_contrat = async (id) => {
        try {

            const response = await axios.get(URL_AXIOS + `ListeMembreBySite/${id}`);
            if (response.data.status === 200) {

                setMembres(response.data.data_membres_sites);

            }
        } catch (Error) {

        }
    };
    const getNameOrIDSociete = async (id) => {
        let name = '';
        try {

            const response = await axios.get(URL_AXIOS + `liste_dossiersByIdToGetName/${id}`);
            if (response.data.status === 200) {
                name = response.data.dossier_send.societe;

            }
        } catch (Error) {

        }
        return name;
    };


    const handleAffectation = async () => {
        if (isChecked.length > 0) {
            setVisible(true);
            const response = await axios.get(URL_AXIOS + `liste_dossiersByIdToGetName/${isChecked[0]}`);
            if (response.data.status === 200) {
                //   alert(response.data.dossier_send.societe);
                const site_id = response.data.dossier_send.site_id;
                setSite_id(site_id);

            }
        } else {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Veuillez cocher un ou plusieurs dossiers",
                showConfirmButton: false,
                timer: 2500
            });

        }
    }
    const handleRadio = (e) => {
        const value = e.target.value;
        setMedecinradio(value);
        setMedecinradioSetValue(value);
        //confirm1();
    }




    const liste_gains_by_id = async (id) => {
        try {
            await axios.get(URL_AXIOS + `get_gains_by_id/${id}`)
                .then(response => {
                    // setDevise(response.data.gains.devise);
                    // setGain(response.data.gains.gain);
                    // setCategorie_fichier(response.data.gains.categorie_fichier);
                    // setMode_paiement(response.data.gains.mode_paiement);
                    // setSite_id(response.data.gains.site_id);
                    // setPourcentage(response.data.gains.pourcentage);
                    // setGainId(response.data.gains.id)
                    // setNbrExamen(response.data.gains.nbrExamen);
                    // setTypeExamen(response.data.gains.typeExamen);
                    // setCategorieExamen(response.data.gains.categorieExamen);
                    // setBoolStatus(true);
                    // setVisible(true);

                });
        } catch (Error) {

        }

    }

    const handleCheckedInput = (e) => {
        const value = e.value; // Get the value of the radio button
        setIsChecked(value);
        setBoolBtn(value !== null); 
        
    };

    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: 10 }}>


                <RadioButton
                    inputId={`check_${data}`}
                    value={data.id}
                    name={`check_group`}
                    onChange={(e) => handleCheckedInput(e)}
                    checked={isChecked === data.id}
                />
                {/* <i className='fa fa-hand-o-up'
                    onClick={() => navigate(`/member_detail_folder/${data.id}`)}
                    style={{
                        color: 'red',
                        cursor: 'pointer',
                        marginLeft: 10,
                        fontSize: 14
                    }}
                    title='Détails'
                >
                </i> */}
                {/* <i className='fa fa-download'
                    onClick={(e) => handleDownload(e, data.chemin_fichier, data.chemin_fichier_2)}
                    style={{
                        color: 'green',
                        cursor: 'pointer',
                        marginLeft: 10,
                        fontSize: 14
                    }}
                    title='Télécharger examen'
                >
                </i> */}
            </div>
        )
    }
    const handleDownload = async (e, value, value2) => {
        e.preventDefault();

        const downloadFile = (fileName, url) => {
            return new Promise((resolve) => {
                const link = document.createElement('a');
                link.download = fileName;
                link.href = url;
                document.body.appendChild(link); // Append link to the body
                link.click();
                document.body.removeChild(link); // Remove link from the body
                resolve();
            });
        };

        try {
            if (value !== null) {
                await downloadFile('Example-PDF-File', URL_FOLDER_API + value);
            }
            if (value2 !== null) {
                // Increase delay to ensure the browser has time to handle the first download
                await new Promise((resolve) => setTimeout(resolve, 2000));
                await downloadFile('Example-PDF-File2', URL_FOLDER_API + value2);
            }
        } catch (error) {
            console.error('Error during download:', error);
        }
    };
    const ActionBodyF = (data) => {
        return (

            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10 }}>

                <span style={{ backgroundColor: data.nom_fichier !== null ? 'green' : 'red', height: 10, width: 10, borderRadius: '50%', position: 'absolute' }}>
                </span>
                <span style={{ backgroundColor: data.nom_fichier_2 !== null ? 'green' : 'red', height: 10, width: 10, borderRadius: '50%', position: 'absolute', marginLeft: '15px' }}>

                </span>
            </div>
        )
    }

    const ActionBodyR = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                <span style={{ backgroundColor: data.etat_medecin === 1 ? 'green' : 'red', height: 10, width: 10, borderRadius: '50%', position: 'absolute' }}>

                </span>
            </div>
        )
    }
    const AddGains = () => {
        setCategorie_fichier("");
        setDevise("");
        setGain("");
        setSite_id("");
        setMode_paiement("");
        setNbrExamen('');
        setTypeExamen("");
        setCategorieExamen('');

    }
    
    const AddPrixGains = async () => {
         
        AddGains();
        if (isChecked!==null) {
            setVisibleConfigGain(true);
            // const site_id = isChecked.site_id;
            // setSite_id(site_id);
            // setCategorie_fichier(isChecked.categorie_fichier);
            // setGainId(isChecked.id);

            await axios.get(URL_AXIOS + `get_gains_by_id/${isChecked}`)
            .then(response => {
                setCategorie_fichier(response.data.gains.categorie_fichier);
                setSite_id(response.data.gains.site_id);
                setGainId(response.data.gains.id);
                setDevise(response.data.gains.devise);
                setGain(response.data.gains.gain);
                setCategorie_fichier(response.data.gains.categorie_fichier);
                setMode_paiement(response.data.gains.mode_paiement);
                setPourcentage(response.data.gains.pourcentage);
                setNbrExamen(response.data.gains.nbrExamen);
                setTypeExamen(response.data.gains.typeExamen);
                setCategorieExamen(response.data.gains.categorieExamen);
            });
        } else {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Veuillez cocher un ou plusieurs dossiers",
                showConfirmButton: false,
                timer: 2500
            });

        }
    }
    useEffect(() => {
        lister_contrat(parseInt(site_id));
    }, [site_id]);

    useEffect(() => {

        liste_site();
        liste_dossiers_all_Not_Gains_Config();

        // lister_contrat(parseInt(site_id));
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [site_id]);
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <Toast ref={toast} />
            <ConfirmDialog />

            <Dialog header="Gains" visible={visibleConfigGain} style={{ width: '60vw' }} onHide={() => setVisibleConfigGain(false)}>
                <form role="form" class="form-horizontal style-form">
                    <div className='form-group container-fluid'>

                        <div className='row'>
                            <div className='col col-lg-12'>
                                <input type='text' disabled value={categorie_fichier} className='form-control' />
                            </div>
                        </div>


                        <div className='row'>

                            <div className='col col-md-3'>
                                <select
                                    className='form-control'
                                    placeholder='Type examen'
                                    value={typeExamen}

                                    onChange={(e) => setTypeExamen(e.target.value)}
                                    style={{ fontSize: "12px" }}
                                >
                                    <option>TYPE EXAMEN</option>
                                    <option value={'SIMPLE'}>SIMPLE</option>
                                    <option value={'SPECIAL'}>SPECIAL</option>
                                    <option value={'DEUX ORGANES'}>DEUX ORGANES</option>
                                    <option value={'TROIS ORGANES'}>TROIS ORGANES</option>
                                    <option value={'QUATRE ORGANES'}>QUATRE ORGANES</option>
                                </select>
                            </div>
                            <div className='col col-md-3'>
                                <input type='number'
                                    value={nbrExamen}
                                    onChange={(e) => handleNombre(e)}
                                    className='form-control'
                                    placeholder='NOMBRE EXAMEN'
                                    style={{ fontSize: "12px" }}
                                />
                            </div>
                            <div className='col col-md-3'>
                                <input type='text' className='form-control' placeholder='GAIN *'
                                    value={gain} onChange={handleInputGains} style={{ textTransform: 'uppercase', fontSize: "12px" }}
                                />
                                {message_gain !== "" ? <label style={{ color: 'red' }}>{message_gain}</label> : ""}
                            </div>
                            <div className='col col-md-3'>
                                <select className='form-control' value={devise} onChange={handleInputDevise}
                                    style={{ textTransform: 'uppercase', fontSize: "12px" }}>
                                    <option value="">Sélectionner une devise *</option>

                                    {currency.map((current) => (
                                        <option key={current.code} value={current.code}>
                                            {current.code}
                                        </option>
                                    ))}
                                </select>
                                {message_devise !== "" ? <label style={{ color: 'red' }}>{message_devise}</label> : ""}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col col-lg-3'>
                                <select className='form-control' value={mode_paiement} onChange={handleInputMP}
                                    style={{ textTransform: 'uppercase', fontSize: "12px" }}
                                >
                                    <option>Mode paiement *</option>
                                    <option value="COMPTANT">COMPTANT</option>
                                    <option value={"VIREMENT"}>VIREMENT</option>
                                </select>
                                {message_mode_paiement !== "" ? <label style={{ color: 'red' }}>{message_mode_paiement}</label> : ""}
                            </div>

                            <div className='col col-md-9'>

                                <select className='form-control' value={site_id} onChange={handleInputSite}
                                    style={{ textTransform: 'uppercase', fontSize: "12px" }}
                                >
                                    <option value="">Sélectionner un site *</option>

                                    {sites.map((city) => (
                                        <option key={city.id} value={city.id}>
                                            {city.societe}
                                        </option>
                                    ))}
                                </select>
                                {message_site_id !== "" ? <label style={{ color: 'red' }}>{message_site_id}</label> : ""}
                            </div>
                        </div>


                    </div>
                    <div class="form-group">
                        <div class="col-lg-12 col-md-12">

                            <button class={'btn btn-warning form-control'} type="button"
                                onClick={() => updateGains(gainId)}
                            >{boolStatus === false ? 'Enregistrer' : 'Mettre à jour'}</button>

                        </div>
                    </div>
                </form>
            </Dialog>
            <ContextMenu model={items} ref={cm} breakpoint="767px" />



            {loading ? <Loading /> : <section id="main-content">
                <section class="wrapper ">
                    <div className='container-fluid mt card'>
                        <div className='row'>
                            {/* <div className='col col-md-9'>
                                <h4 style={{ marginLeft: 10, marginTop: 20 }}>Dossiers transférés</h4>
                            </div> */}
                            <div className='col col-md-12'>

                                <div style={{ float: 'right', display: 'block' }}>
                                    {/* <button type='button'  onClick={() => navigate('/liste_archive_dossier_transfere')} className="btn btn-default" title='Liste des archives dossiers transférés '>
                                        <i class="fa fa-eye"></i>
                    </button>*/}
                                    <button type='button' title='Configurer gain'
                                        onClick={() => AddPrixGains()}
                                        disabled={boolBtn === false ? true : false}
                                        className='btn btn-info' style={{ marginLeft: 5 }}>
                                        <i class=" fa fa-money"></i>
                                    </button>

                                </div>

                            </div>


                        </div>

                        <div className='row'>
                            {/* <DataTable value={dossiersConfigGains}  emptyMessage="Aucun dossier disponible!"  paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}   className="custom-datatable">
                                <Column header={() => { return <div style={{ marginLeft: 20, padding: '0 10px' }}>#</div> }} bodyStyle={{ textAlign: 'center', padding: '0 10px' }} headerStyle={{ marginLeft: "100px" }} field='id' style={{ width: '4%', height: '40px' }}></Column>
                                <Column field='societe' header="CENTRE" style={{ width: 'auto', height: '40px', padding: '0 10px' }} headerStyle={{ textAlign: 'center', padding: '0 10px' }}></Column>
                                <Column field='nom_pat' header="PATIENTS" style={{ width: 'auto', height: '40px' }} headerStyle={{ textAlign: 'center' }}></Column>
                                <Column field={'categorie_fichier'} header="EXAMENS" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                <Column field={(data) => { return data.nom_medecin + ' ' + data.prenom_medecin }} headerStyle={{ textAlign: 'center !important', padding: '0 10px' }} header="RADIOLOGUE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                <Column field='created_at' header="ENVOYER LE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                <Column header={"F"} body={ActionBodyF} style={{ width: '4%', height: '40px', textAlign: 'center', padding: '0 10px' }} headerStyle={{ textAlign: 'center !important', padding: '0 10px' }}></Column>
                                <Column header="R" body={ActionBodyR} style={{ width: '2%', height: '40px', padding: '0 10px' }} headerStyle={{ textAlign: 'center', padding: '0 10px' }}></Column>
                                <Column header="" body={ActionBody} style={{ width: 'auto', height: '40px', padding: '0 10px' }} headerStyle={{ textAlign: 'center', padding: '0 10px' }}></Column>
                            </DataTable> */}

                            <DataTable value={dossiersConfigGains} paginator rows={10}
                                tableStyle={{ minWidth: '50rem' }}
                                emptyMessage="Aucun gain disponible!"
                                className="custom-datatable"

                            >
                                <Column field='id' header="#" headerStyle={{ textAlign: 'center', padding: '0 10px' }} style={{ width: "3%", textAlign: 'center', padding: '0 10px' }}></Column>
                                <Column field='societe' header="CENTRE" style={{ width: "auto", height: "40px", padding: '0 10px' }}></Column>
                                <Column field='categorie_fichier' header="EXAMEN" style={{ width: "auto", height: "40px", padding: '0 10px' }}></Column>
                                <Column field='gain' header="GAIN" style={{ width: "auto", height: "40px", padding: '0 10px' }}></Column>
                                <Column field='mode_paiement' header="MP" style={{ width: "auto", height: "40px", padding: '0 10px' }}></Column>
                                <Column header="" body={ActionBody} style={{ width: 'auto', height: '40px', padding: '0 10px' }} headerStyle={{ textAlign: 'center', padding: '0 10px' }}></Column>
                            </DataTable>


                        </div>
                    </div>
                </section>
            </section>}
            <br /><br /><br />
            <Footer />
        </div>
    );
}

export default ConfigGains;