import React from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { Calendar } from 'antd';
import { useContext } from 'react';
import Footer from '../Footer';
import { useState } from 'react';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import { useEffect } from 'react';

function AddManipulateurs() {
    const [site_id, setSite_id] = useState('');
    const [sites, setSites] = useState([]);
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [mail, setMail] = useState('');
    const [telephone, setTelephone] = useState('');
    const usernameconnected=sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const handleInputSite = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSite_id(value)

        } else {
            setSite_id("")
        }
    }

    const handleInputTelephone = (event) => {
        const value = event.target.value;
        const regex = /^-?\d*$/;

        if (regex.test(value)) {
            setTelephone(value);
        } else {

        }
    };

    const handleNom = (e) => {
        const value = e.target.value;
        setNom(value);
    }
    const handlePrenom = (e) => {
        const value = e.target.value;
        setPrenom(value);
    }

    const handleMail = (e) => {
        const value = e.target.value;
        setMail(value);
    }
    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status == 200) {
                setSites(response.data.sites);

            }
        } catch (Error) {

        }

    }

    const addManipulation = async () => {
        try {
            const data = {
                nom: nom,
                prenom: prenom,
                mail: mail,
                telephone: telephone,
                site_id: site_id
            }
            const response = await axios.post(URL_AXIOS + 'store_manipulation', data);
            if (response.data.status === 200) {
                alert(response.data.message);
                setNom("");
                setPrenom("");
                setTelephone("")

            }
        } catch (error) {
            alert("erreur "+error.message)
        }
    }
    useEffect(() => {
        liste_site();
        if(usernameconnected===null || usernameconnected===""){
            navigator('/')
        }
    }, []);
    return (
        <section id="container">

            <Header />
            <AsideBar  username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div className="row mt">
                        <div class="form-panel">
                            <div className='container-fluid'>

                                <div className='row'>
                                    <div className='col col-md-4'>
                                        <input type='text' className='form-control' placeholder='Veuillez saisir le nom'
                                            value={nom}
                                            onChange={handleNom}
                                        />
                                    </div>
                                    <div className='col col-md-4'>
                                        <input type='text' className='form-control' placeholder='Veuillez saisir le prenom'
                                            value={prenom}
                                            onChange={handlePrenom}
                                        />
                                    </div>
                                    <div className='col col-md-4'>
                                        <input type='text' className='form-control' placeholder='Veuillez saisir le mail'
                                            value={mail}
                                            onChange={handleMail}
                                        />
                                    </div>

                                </div><br />
                                <div className='row'>
                                    <div className='col col-md-4'>
                                        <input type='text' value={telephone} onChange={handleInputTelephone} className='form-control' placeholder='Veuillez saisir le téléphone' />
                                    </div>
                                    <div className='col col-md-4'>
                                        <select className='form-control' value={site_id} onChange={handleInputSite}
                                            style={{ textTransform: 'uppercase' }}
                                        >
                                            <option value="">Sélectionner un site *</option>

                                            {sites.map((city) => (
                                                <option key={city.id} value={city.id}>
                                                    {city.societe}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col col-md-4'>

                                    </div>

                                </div><br />
                                <div className='row'>
                                    <div className='col col-md-4'>
                                        <button type='button' className='btn btn-info'
                                            onClick={() => addManipulation()}
                                        >Enregistrer</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </section>
    );
}

export default AddManipulateurs;