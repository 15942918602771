import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { URL_AXIOS } from '../../URL/URL';
import axios, { Axios } from 'axios';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { Country, State, City } from 'country-state-city';
import { Toast } from 'primereact/toast';
import { RadioButton } from "primereact/radiobutton";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useContext } from 'react';
import Footer from '../Footer';
function EditionUsers() {

    const usernameconnected=sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const navigate = useNavigate();
    const { id } = useParams();
    const [sites, setSites] = useState([]);
    const [societe, setSociete] = useState("");
    const [email, setEmail] = useState("");
    const [nom, setNom] = useState("");
    const [confirme, setConfirme] = useState("");
    const [adresselocal, setAdresselocal] = useState("");
    const [statut, setStatut] = useState(1);
    const [role, setRole] = useState("");
    const [password, setPassword] = useState("");
    const toast = useRef(null);
    const [ingredient, setIngredient] = useState('');
    const [message, setMessage] = useState('');
    const [getvalue, setGetvalue] = useState('');
    const [pays, setPays] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const [message_mail, setMessage_mail] = useState("");
    const [message_nom, setMessage_nom] = useState("");
    const [message_societe, setMessage_societe] = useState("");
    const [message_role, setMessage_role] = useState("");
    const [message_password, setMessage_password] = useState("");
    const [message_confirme, setMessage_confirme] = useState("");

    const handleInputSociete = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSociete(value)
            setMessage_societe("");
        } else {
            setSociete("")
        }
    }
    const handleInputPassword = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setPassword(value)
            setMessage_password("");
        } else {
            setPassword("")
        }
    }
    const handleInputNom = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setNom(value)
            setMessage_nom("");
        } else {
            setNom("")
        }
    }

    const handleInputRole = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setRole(value)
            setMessage_role("");
        } else {
            setRole("")
        }
    }

    const handleInputEmail = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setEmail(value)
            setMessage_mail("");
        } else {
            setEmail("")
        }
    }

    const validationFields = () => {

        if (societe == "") {

            setMessage_societe("Veuillez sélectionner la société");

        }

        if (nom == "") {

            setMessage_nom("Veuillez saisir le nom");

        }

        if (role == "") {

            setMessage_role("Veuillez sélectionner le rôle");

        }

        if (email == "") {

            setMessage_mail("Veuillez saisir le mail");

        }

        if (password == "") {

            setMessage_password("Veuillez saisir le password");

        }

        if (confirme == "") {

            setMessage_confirme("Veuillez confimer le mot de passe");

        }

    }
    const EmailValidation = (email) => {
        const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
        if (regex.test(email)) {

            return true;
        } else if (!regex.test(email) && email !== "") {
            setMessage_mail("le mail n'est pas valide");
            return false;
        } else {
            setMessage_mail("");
            return false;
        }
    };
    const removeIdenticalCharacters = (str) => {
        return str.replace(/(.)\1+/g, '$1');
    }

    const ClearFields = () => {
        setEmail("")
        setNom("")
        setSociete("")
        setPassword("")
        setConfirme("")
        setRole("")
    }
    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status == 200) {
                setSites(response.data.sites);
                //setFilteredData(response.data.sites);
            }
        } catch (Error) {

        }

    }
    const handleRoleChange = (e) => {
        const name_get = e.target.value;
        setRole(name_get);
    };
    const handleSocieteChange = (e) => {
        const name_get = e.target.value;

        if (name_get !== "") {
            setSociete(name_get)
            setMessage_societe("");
        } else {
            setSociete("")
        }

        // Assuming 'sites' is an array of objects with a property called 'id'
        const filtered = sites.filter((item) => item.id == name_get);


        // Make sure 'filtered' is not empty before accessing its elements
        if (filtered.length > 0) {
            // Assuming 'societe' is a property of the first item in the 'filtered' array
            const societeValue = filtered[0].societe;

            setSociete(societeValue);
            setGetvalue(name_get);
            //const filtered_two = sites.filter((item) => item.societe !== societeValue);
            //console.log("ARRAY "+JSON.stringify(filtered_two))
            //setSites(filtered_two)

        } else {
            // Handle the case when no matching item is found in 'sites'
            setSociete(""); // Set to an appropriate default value
            console.log("scf societe: No matching item found.");
        }
    };

    const liste_users_by_id = async () => {
        try {
            await axios.get(URL_AXIOS + `get_users_by_id/${id}`)
                .then(response => {
                  
                    setSociete(response.data.societe);
                    setNom(response.data.name);
                    setEmail(response.data.email_user);
                    setGetvalue(response.data.site_id);
                    setRole(response.data.role);
                    setPassword(response.data.password)
                    setConfirme(response.data.password)
                    response.data.statut_user == 1 ? setIngredient("oui") : setIngredient("non")
                });
        } catch (Error) {

        }

    }
    const update_user = async () => {
        try {
            const data_user = {
                name: nom.toUpperCase(),
                email: email,
                password: password,
                role: role.toUpperCase(),
                site_id: getvalue,
                statut: ingredient == "oui" ? 1 : 0
            }
            validationFields();
            if (EmailValidation(email)) {
                if (societe != "" || email != "" || nom != "" || role != "") {
                    if (ingredient != "") {

                        const response = await axios.post(URL_AXIOS + `update_user_api/${id}`, data_user);
                        if (response.data.status == 200) {
                            toast.current.show({ severity: 'info', summary: 'Info', detail: 'Le site a été modifié !' });
                            navigate('/users')

                        } else {
                            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Error !' });

                        }
                    } else {
                        setMessage("Les deux mot de passe doivent être pareil")
                    }



                } else {

                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Veuillez cocher le type de validation', life: 3000 });
                }

            }

        } catch (error) {
            //show();
        }



    }
    const handlePasswordConfirme = (event) => {
        const value = event.target.value;
        setConfirme(value);
        if (value == password) {

            setMessage_confirme("");

        } else {
            setMessage_confirme("Les deux mot de passe doivent être pareil")
        }
    }

    useEffect(() => {
        liste_site();
        liste_users_by_id();
        if(usernameconnected===null || usernameconnected===""){
            navigate('/')
        }
        // fetchData();
    }, []);


    return (
        <section id="container">
            <Toast ref={toast} />
            <Header />
            <AsideBar  username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="form-panel">
                                <form role="form" class="form-horizontal style-form">
                                    <div class="form-group ">

                                        <div class="col-lg-6">
                                            <input type="text" value={nom} onChange={handleInputNom} placeholder="Nom(s) complets *" class="form-control"
                                                style={{fontWeight: 'bolder' }} />
                                            {message_nom !== "" ? <label style={{ color: 'red' }}>{message_nom}</label> : ""}
                                        </div>
                                        <div class="col-lg-6">

                                            <select className='form-control' value={societe} onChange={handleSocieteChange}
                                                style={{fontWeight: 'bolder' }}>
                                                <option value={id !== "" ? societe : ""}>{id !== "" ? societe : "Sélectionner une société *"}</option>

                                                {sites.map((city) => (
                                                    <option key={city.id} value={city.id}>
                                                        {city.societe}

                                                    </option>
                                                ))}
                                            </select>
                                            {message_societe !== "" ? <label style={{ color: 'red' }}>{message_societe}</label> : ""}

                                        </div>
                                    </div>
                                    <div class="form-group ">

                                        <div class="col-lg-6">
                                            <select className='form-control'
                                                style={{fontWeight: 'bolder' }}
                                                value={role} onChange={handleInputRole}>
                                                <option>{id !== "" ? role == "M" ? "Médecin" : role == "A" ? "Administrateur" : role == "C" ? "Agent du centre" : "" : "Rôle *"}</option>
                                                <option value={'A'}>Administrateur</option>
                                                <option value={'M'}>Médecin</option>
                                                <option value={'C'}>Agent du centre</option>
                                            </select>
                                            {message_role !== "" ? <label style={{ color: 'red' }}>{message_role}</label> : ""}
                                        </div>

                                        <div class="col-lg-6">
                                            <input type="email" value={email} onChange={handleInputEmail} placeholder="Email *" class="form-control" />
                                            {message_mail !== "" ? <label style={{ color: 'red' }}>{message_mail}</label> : ""}
                                        </div>
                                    </div>

                                    <div class="form-group">


                                        <div class="col-lg-3">
                                            <input type="hidden" value={password} onChange={handleInputPassword} placeholder="Mot de passe *" class="form-control" />
                                            {message_password !== "" ? <label style={{ color: 'red' }}>{message_password}</label> : ""}

                                        </div>
                                        <div class="col-lg-3">
                                            <input type="hidden" value={confirme} onChange={handlePasswordConfirme} placeholder="Confirmer votre mot de passe *" class="form-control" />
                                            {message_confirme !== "" ? <label style={{ color: 'red' }}>{message_confirme}</label> : ""}

                                        </div>
                                    </div>
                                    <div className="form-group ">

                                        <div className="flex align-items-center" style={{ marginLeft: '40%' }}>
                                            <label className="ml-2" style={{ marginLeft: 5 }}> Status de validation :</label>
                                            <RadioButton inputId="ingredient1" name="pizza" style={{ marginLeft: 10 }} value="oui" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'oui'} />
                                            <label htmlFor="ingredient1" className="ml-2" style={{ marginLeft: 10 }}>Oui</label>
                                            <RadioButton inputId="ingredient2" name="pizza" value="non"
                                                onChange={(e) => setIngredient(e.value)} checked={ingredient === 'non'}
                                                style={{ marginLeft: 10 }} />
                                            <label htmlFor="ingredient2" style={{ marginLeft: 10 }} className="ml-2">Non</label>
                                        </div>



                                    </div>
                                    <div class="form-group">
                                        <div class="col-lg-10">
                                            <button class="btn btn-primary" type="button"
                                                onClick={() => update_user()}
                                            >Mettre à jour</button>
                                            <button class="btn btn-warning" type="button" style={{ marginLeft: 10 }}
                                                onClick={() => navigate('/users')}>Retour</button>
                                        </div>
                                    </div>
                                </form>



                            </div>
                        </div>
                    </div>


                </section>
            </section>
            <Footer/>
        </section>
    );
}

export default EditionUsers;