import { useEffect, useRef } from 'react';
import {useNavigate } from 'react-router-dom';

const useIdleTimer = (timeout) => {
  const navigate = useNavigate();
  const timerRef = useRef(null);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      sessionStorage.clear();
      navigate('/'); // Rediriger vers la page de connexion
    }, timeout);
  };

  useEffect(() => {
    const handleActivity = () => resetTimer();

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);

    resetTimer();

    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
    };
  }, [timeout, navigate]);

  return null;
};

export default useIdleTimer;
