import React, { useEffect, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { useReactToPrint } from 'react-to-print';
import JoditEditor from 'jodit-react';
import '../../styles/Print.css';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { useNavigate, useParams } from 'react-router-dom';
import { URL_AXIOS, URL_ENTETE_SITE, URL_FOLDER_API, URL_MAIN } from '../../URL/URL';
import axios from 'axios';
import { format } from 'date-fns';

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "black",
  },
  section: {
    margin: 10,
    padding: 10,
    boxShadow: '0 0 0.5cm rgba(0, 0, 0, 0.5)',
    minHeight: '297mm',
    width: '210mm',
    backgroundColor: "#fff",
    paddingBottom: 30

  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
    backgroundColor: '#c2c3c1',
    overflowX: 'hidden',
    overflowY: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    padding: 50,

  },
  viewerBar: {
    width: '100%', //the pdf viewer will take up all of the width and height
    height: 60,
    backgroundColor: '#3b3b3b',
    borderBottom: '0.5px solid grey',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2000

  },

  text: {
    fontSize: 10,
    textAlign: "center"
  },
  image: {
    width: 200,
    height: 200,
  },
});
function Apercu() {
  const editor = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [base64Image, setBase64Image] = useState('');
  const [siteName, setSiteName] = useState("");
  const [statutUrg, setStatutUrg] = useState(1);
  const [categorie_fichier, setCategorie_fichier] = useState("");
  const [Examen_1, setExamen_1] = useState('');
  const [Examen_2, setExamen_2] = useState('');
  const [chemin_fichier_1, setChemin_fichier_1] = useState('');
  const [chemin_fichier_2, setChemin_fichier_2] = useState('');
  const [bulletin_examen, setBulletin_examen] = useState('');
  const [chemin_bulletin, setChemin_bulletin] = useState('');
  const [nom_pat, setNom_pat] = useState('');
  const [age, setAge] = useState("");
  const [LibelleAge, setLibelleAge] = useState("");
  const [sexe, setSexe] = useState("");
  const [prescripteur, setPrescripteur] = useState("");
  const [observation, setObservation] = useState("");
  const [statut, setStatut] = useState("");
  const [etat, setEtat] = useState("");
  const [inter_id, setInter_id] = useState('');
  const [entete_societe, setEntete_societe] = useState("");
  const [ville_societe, setVille_societe] = useState(null);
  const [created_at, setCreated_at] = useState(new Date());
  const [img_obs_1, setImg_obs_1] = useState("");
  const [img_obs_2, setImg_obs_2] = useState("");
  const [img_obs_3, setImg_obs_3] = useState("");
  const [signature, setSignature] = useState("");
  const [nom_medecin, setNom_medecin] = useState("");
  const [prenom_medecin, setPrenom_medecin] = useState("");
  const [qualification, setQualification] = useState('');
  const [membre_id, setMembre_id] = useState(0);
  const [cheminfichiers, setCheminfichiers] = useState("");
  const componentRef = useRef();
  const liste_membres_by_id = async (id) => {
    try {
      await axios.get(URL_AXIOS + `get_membre_by_id/${id}`)
        .then(res => {
          setCheminfichiers(res.data.membres.cheminfichiers);
        });
    } catch (Error) {

    }

  }
  const liste_dossiers_by_siteId = async (dossier_id) => {
    try {
      const response = await axios.get(URL_AXIOS + `liste_dossiers_by_dossiersId/${dossier_id}`);
      if (response.status == 200) {
        // setDossiers(response.data.dossier_send);
        setSiteName(response.data.dossier_send.societe);
        setCategorie_fichier(response.data.dossier_send.categorie_fichier);
        setExamen_1(response.data.dossier_send.nom_fichier)
        setExamen_2(response.data.dossier_send.nom_fichier_2)
        setChemin_fichier_1(response.data.dossier_send.chemin_fichier)
        setChemin_fichier_2(response.data.dossier_send.chemin_fichier_2)
        setBulletin_examen(response.data.dossier_send.bulletin_examen)
        setChemin_bulletin(response.data.dossier_send.chemin_bulletin)
        setNom_pat(response.data.dossier_send.nom_pat)
        setAge(response.data.dossier_send.age_pat)
        setSexe(response.data.dossier_send.sexe_pat)
        setLibelleAge(response.data.dossier_send.libelle_age_pat)
        setPrescripteur(response.data.dossier_send.prescripteur)
        setStatut(response.data.dossier_send.statut)
        setEtat(response.data.dossier_send.etat);
        setVille_societe(response.data.dossier_send.ville_medecin);
        setNom_medecin(response.data.dossier_send.nom_medecin);
        setPrenom_medecin(response.data.dossier_send.prenom_medecin);
        setSignature(response.data.dossier_send.signature_medecin);
        setQualification(response.data.dossier_send.qualification);
        setMembre_id(response.data.dossier_send.membre_id);

        const imageUrl = `${URL_FOLDER_API}${siteName}/img/${response.data.dossier_send.entete_societe}`;

        const enteteSociete = response.data?.dossier_send?.entete_societe || '';

        if (enteteSociete) {
          setEntete_societe(imageUrl);
        } else {
          setEntete_societe('');
        }




        //setEntete_societe(imageUrl);


      }
    } catch (error) {
      // alert("Erreur " + error.message);
    }
  }
  const config = {
    style: {
      overflow: 'hidden',
      height: 'auto',
      zIndex: 10,
      paddingBottom: 10,


    },
    height: 'auto',
    scroll: false,
    allowResizeX: false,
    allowResizeY: false,
    autofocus: true,
    toolbarAdaptive: false,
    editorClassName: 'editorComponent_apercu',
    zIndex: 10,
    readonly: true,
    toolbar: true,
    //activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
    toolbarButtonSize: 'middle',
    theme: 'default',
    // saveModeInCookie: true,
    // spellcheck: true,
    // editorCssClass: true,
    // triggerChangeEvent: true,
    statusbar: false,
    width: 'auto',
    direction: '',
    language: 'auto',
    debugLanguage: false,
    i18n: 'fr',
    tabIndex: -1,
    enter: "P",
    useSplitMode: false,
    colorPickerDefaultTab: 'background',
    removeButtons: [],
    disablePlugins: [],
    extraButtons: [],
    sizeLG: 900,
    sizeMD: 700,
    buttons: [

    ],
    events: {},
    textIcons: false,
  }
  const liste_dossiers_interprete = async (dossier_id) => {
    try {
      const response = await axios.get(URL_AXIOS + `liste_interpretation/${parseInt(dossier_id)}`);
      if (response.status === 200) {

        const obs = response.data.dossierInter.observation;
        setObservation(obs);
        if (response.data.dossierInter.img_obs_1) {
          const URL1 = URL_MAIN + response.data.dossierInter.img_obs_1;
          setImg_obs_1(URL1);
        } else {
          setImg_obs_1("");
        }
        if (response.data.dossierInter.img_obs_2) {
          const URL2 = URL_MAIN + response.data.dossierInter.img_obs_2;
          setImg_obs_2(URL2);
        } else {
          setImg_obs_2("");
        }
        if (response.data.dossierInter.img_obs_3) {
          const URL3 = URL_MAIN + response.data.dossierInter.img_obs_3;
          setImg_obs_3(URL3);
        } else {
          setImg_obs_3("");
        }
        setInter_id(response.data.dossierInter.inter_id);
        setCreated_at(response.data.dossierInter.created_at);
      }

    } catch (error) {
      console.log("Erreur " + error.message);
    }
  }

  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,


  });
  useEffect(() => {
    liste_membres_by_id(membre_id)
  }, [membre_id])
  useEffect(() => {
    try {
      const FetchData = async (dossier_id) => {
        liste_dossiers_by_siteId(dossier_id);
        liste_dossiers_interprete(dossier_id);
      }
      FetchData(id);
    } catch (error) {

    }

  }, [id, entete_societe, signature])

  return (
    <div id='body-pint'>
      <div className='bar-header'>
        <center><button type='button' className='btn btn-primary' onClick={() => HandlePrint()}><i className='fa fa-print' style={{ color: 'white', fontSize: 20, marginTop: 10 }}></i></button></center>
      </div>
      <div className='content-print' ref={componentRef}>
        {/* <header>
        </header> */}
        <center><h6 className='text-center titre' style={{ color: 'black' }}>© E-METECH, plateforme de téléradiologie, www.e-metech.com</h6></center>
        <table style={{ width: "100%" }} className='bloc1'>
          <tr>
            {entete_societe && <img src={entete_societe} style={{ width: '100%', height: '150px' }} />}
          </tr>
          <tr>
            <p style={{ fontSize: 12, marginTop: 5, float: 'right', color: 'black', fontWeight: 'bold' }}>Fait à {ville_societe} le, {format(new Date(created_at), 'dd/MM/yyyy')}</p>
          </tr>
          <tr>
            <h3 className='text-center' style={{ marginTop: "1%", color: 'black', textDecoration: "underline" }}>{categorie_fichier}</h3>
          </tr>
        </table>
        <hr className='devider' />
        <table className='table table-striped' style={{ marginLeft: 30 }}>
          <tr style={{ color: 'black', fontSize: 12 }}>
            <th>ID : {id}</th>
            <th>PATIENT(E) : {nom_pat}</th>
            <th>SEXE : {sexe}</th>
          </tr>
          <tr>
            <br />
          </tr>
          <tr style={{ color: 'black', fontSize: 12 }}>
            <th>AGE :  {`${(age !== null && age !== "") ? age : ''} ${(LibelleAge !== null && LibelleAge !== "") ? LibelleAge : ''}`}</th>
            <th>PRESCRIPTEUR : {(prescripteur !== null && prescripteur !== "") ? prescripteur : ''}</th>
            <th>RADIOLOGUE :{qualification === "Médecin"
              ? ((nom_medecin ? " Dr " + nom_medecin : '')+" "+(prenom_medecin ? prenom_medecin :""))
              : qualification === "Professeur agrégé"
                ? ((nom_medecin ? " Pr. Ag " + nom_medecin : '')+" "+(prenom_medecin ? prenom_medecin :""))
                : ''}</th>
          </tr>

        </table>
        <hr className='devider' />
        {/* <div className='joditDiv jod' style={{ width: "100%", height: "auto", paddingBottom: 20 }}> */}
        <JoditEditor
          config={config}
          ref={editor}
          value={observation}
          tabIndex={1}
        />
        <div className="page-break"></div>
        {/* </div> */}

        <table style={{ marginTop: 10, width: "100%" }}>
          <tr>
            <th>
              {img_obs_1 && <img src={img_obs_1} style={{ width: '150px', height: '150px', float: 'left' }} />}
              {img_obs_1 && <h6 style={{ marginTop: "75px", marginLeft: 13 }}>Fig.1</h6>}

            </th>
            <th>
              {img_obs_2 && <img src={img_obs_2} style={{ width: '150px', height: '150px', float: 'left' }} />}
              {img_obs_2 && <h6 style={{ marginTop: "75px", marginLeft: 13 }}>Fig.2</h6>}
            </th>
            <th>
              {img_obs_3 && <img src={img_obs_3} style={{ width: '150px', height: '150px', float: 'left' }} />}
              {img_obs_3 && <h6 style={{ marginTop: "75px", marginLeft: 13 }}>Fig.3</h6>}
            </th>
          </tr>
          <tr>

          </tr>
        </table>
        <table style={{ width: '100%', marginTop: 30 }}>
          <tr>
            <th>
              <p style={{ marginTop: '20px', fontWeight: 'bold', color: "black" }}>Bien confraternellement !<br />
                Examen interprété dans le cadre de la téléradiologie
              </p>
            </th>
            <th>
              {signature && <img src={URL_MAIN + cheminfichiers + "/" + signature} style={{ float: 'right', width: '150px', height: '100px' }} />}
            </th>
          </tr>
        </table>
        {/* <footer>

        </footer> */}
      </div>
    </div>

  );
}

export default Apercu;